import React, { useState } from "react";
import { includes } from "lodash";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  Menu,
  MenuItem,
  IconButton,
  Fab as MuiFab,
  Box as MuiBox,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  Typography as MuiTypography,
  CardContent as MuiCardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  ExpandMore as ExpandMoreIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from "@mui/icons-material";

import { FILE_STATUS } from "../constants";
import RenameModal from "./RenameModal";
import CompareImage from "./CompareImage";
import WhiteCheckbox from "./WhiteCheckbox";
import Intro from "./Intro";

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ImageName = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardContent = styled(MuiCardContent)`
  padding: 4px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};

  &:last-child {
    padding-bottom: 4px;
  }
`;

const CardMedia = styled(MuiCardMedia)`
  height: 210px;
  overflow: hidden;
  position: relative;

  & > div:first-child {
    min-height: 210px;
    max-height: 100%;
  }
`;
const Checkbox = styled(WhiteCheckbox)`
  position: absolute;
  right: 0;
  top: 0;
`;
const Fab = styled(MuiFab)`
  transform: scale(0.6);
  position: absolute;
  left: 0;
  top: 0;
`;

const ImageCard = ({
  id,
  status,
  index,
  tabValue,
  leftImage,
  imageName,
  rightImage,
  checkedFiles,
  downloadFile,
  updateFileName,
  setDeleteModal,
  setCompareModal,
  handleCheckboxChange,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [renameModal, setRenameModal] = useState({
    open: false,
    name: imageName,
    id,
  });

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Intro />
      <Card>
        <CardMedia>
          <CompareImage
            right={`${rightImage}?w=500&h=500`}
            left={`${leftImage}?w=500&h=500`}
            status={status}
            inComponent={true}
          />

          {((status === FILE_STATUS.WAITING_FOR_APPROVAL &&
            tabValue !== "enhancement") ||
            status === FILE_STATUS.COMPLETED) && (
            <Checkbox
              checked={includes(checkedFiles, id)}
              onChange={() => handleCheckboxChange(id)}
            />
          )}
          <Fab
            className={`button-${index}`}
            size="small"
            color="secondary"
            aria-label="zoom-out"
            onClick={() =>
              setCompareModal({ open: true, id, activeTab: tabValue })
            }
          >
            <ZoomOutMapIcon />
          </Fab>
        </CardMedia>
        <CardContent>
          <Box>
            <ImageName variant="h6" ml={2}>
              {imageName}
            </ImageName>
            <IconButton
              aria-owns={anchorEl ? "menu" : undefined}
              aria-haspopup="true"
              onClick={handleMenuClick}
              variant="contained"
              color="secondary"
              size={"small"}
            >
              <ExpandMoreIcon />
            </IconButton>

            <Menu
              id="menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{ style: { width: 155 } }}
            >
              <MenuItem
                onClick={() =>
                  setCompareModal({ open: true, id, activeTab: tabValue })
                }
              >
                {t("project.preview")}
              </MenuItem>
              <MenuItem
                onClick={() => downloadFile(id)}
                disabled={
                  !(
                    status === FILE_STATUS.COMPLETED ||
                    status === FILE_STATUS.WAITING_FOR_APPROVAL
                  )
                }
              >
                {t("project.download")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setRenameModal({ open: true, name: imageName, id });
                  handleMenuClose();
                }}
              >
                {t("project.rename")}
              </MenuItem>
              {setDeleteModal && (
                <MenuItem
                  disabled={!(status === FILE_STATUS.COMPLETED)}
                  onClick={() => {
                    setDeleteModal({ open: true, id });
                  }}
                >
                  {t("project.delete")}
                </MenuItem>
              )}
            </Menu>
          </Box>
        </CardContent>
      </Card>
      <RenameModal
        renameModal={renameModal}
        setRenameModal={setRenameModal}
        updateName={updateFileName}
      />
    </React.Fragment>
  );
};

export default ImageCard;
