/* eslint-disable */
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { useTranslation } from "react-i18next";
import { get, trim, map, uniq, remove, compact, includes } from "lodash";
import {
  Grid,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
  FormControl,
  Checkbox as MuiCheckbox,
  Typography as MuiTypography,
  FormControlLabel as MuiFormControlLabel,
  Box,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { STAGING_STYLES } from "../constants";
import Keyword from "./Keyword";
import Popup from "./Popup";

const Label = styled(FormLabel)`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #8492a6;
`;

const Image = styled.img`
  width: 100%;
  min-width: 100px;
  max-height: 165px;
  object-fit: cover;
`;

const Container = styled(Grid)`
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 2px;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  align-items: center;
  justify-content: center;
  margin: 0;
  height: inherit;
`;

const Checkbox = styled(MuiCheckbox)`
  padding-left: 0;
`;

const Typography = styled(MuiTypography)`
  word-break: break-all;
`;

const ServiceSelectBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ListView = (props) => {
  const {
    file,
    formik,
    stagingStyle,
    handleStagingCheckbox,
    handleEnhancementCheckbox,
  } = props;
  const { t } = useTranslation();
  const { values, setFieldValue, isSubmitting } = formik;
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([
    ...(file?.auto_detections || file?.metadata?.keywords || []),
  ]);
  const [includeInfo, setIncludeInfo] = useState(null);
  const [alreadyEnhanced, setAlreadyEnhanced] = useState(null);
  const [premiumEnhancementInfo, setPremiumEnhancementInfo] = useState(null);
  const [premiumStagingInfo, setPremiumStagingInfo] = useState(null);

  const onRoomTypeChange = (e, file) => {
    const { value } = e.target;
    e.stopPropagation();

    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) f.room_type = value;
        return f;
      })
    );
  };

  const onSelectPremiumService = (e, file) => {
    e.stopPropagation();
    const { value, name } = e.target;
    let hasPremium = false;
    if (value !== 1) hasPremium = true;
    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) {
          name === "staging"
            ? (f.has_premium_staging = hasPremium)
            : (f.has_premium_enhancement = hasPremium);
        }
        return f;
      })
    );
  };

  const saveKeywords = (value) => {
    setValue(value);
    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) {
          f.keywords = value;
        }
        return f;
      })
    );
  };

  const onKeywordsChange = (e, value) => {
    saveKeywords(compact(uniq([...value, trim(get(e, "target.value"))])));
  };

  const handleKeyDown = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      e.stopPropagation();

      if (
        trim(get(e, "target.value")).length &&
        !value.includes(trim(inputValue))
      ) {
        saveKeywords([...value, trim(get(e, "target.value"))]);

        setInputValue("");
      }
    }
  };

  const handleClickOutside = () => {
    if (inputValue && !value.includes(trim(inputValue))) {
      saveKeywords([...value, trim(inputValue)]);
      setInputValue("");
    }
  };

  useEffect(() => {
    const styles = map(STAGING_STYLES, (style) => style);
    const filtered = remove(
      value,
      (item) => !includes(styles, item) && item !== ""
    );

    file.checkedStaging && stagingStyle
      ? setValue([...filtered, stagingStyle])
      : setValue(filtered);
  }, [stagingStyle, inputValue, file.checkedStaging]);

  useEffect(() => {
    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) {
          f.keywords = value;
        }
        return f;
      })
    );
  }, [value]);

  return (
    <Container container>
      <Grid item xs={3} p={3}>
        <Image src={file.smallSizePreview || file.public_url} />
        <Typography
          sx={{
            fontSize: {
              lg: 13,
              xs: 10,
            },
          }}
        >
          {file?.name || file.file_name}
        </Typography>
      </Grid>
      <Grid item xs={3} p={3} display={"flex"} flexDirection={"column"}>
        <Label component="legend">{t("project.services")}</Label>
        <ServiceSelectBox>
          <div>
            <FormControlLabel
              disabled={isSubmitting}
              control={
                <Checkbox
                  value="staging"
                  checked={!!file.checkedStaging}
                  onChange={(e) => handleStagingCheckbox(e, file)}
                />
              }
              label="Virtual Staging"
            />
            <IconButton
              aria-label="info"
              size="small"
              onClick={(e) => setPremiumStagingInfo(e.currentTarget)}
            >
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          </div>

          <FormControl sx={{ width: "96px" }}>
            <Select
              name={"staging"}
              disabled={isSubmitting || !file.checkedStaging}
              value={file?.has_premium_staging === true ? 2 : 1}
              onChange={(e) => onSelectPremiumService(e, file)}
            >
              <MenuItem value={1}>{t(`project.basic`)}</MenuItem>
              <MenuItem value={2}>{t(`project.premium`)}</MenuItem>
            </Select>
          </FormControl>
        </ServiceSelectBox>
        <ServiceSelectBox>
          <div>
            <FormControlLabel
              disabled={isSubmitting || !!file?.has_enhanced_file}
              onClick={(e) => {
                if (
                  file.checkedStaging &&
                  file.checkedEnhancement &&
                  !file?.has_enhanced_file
                ) {
                  setIncludeInfo(e.currentTarget);
                }
                if (file?.has_enhanced_file) {
                  setAlreadyEnhanced(e.currentTarget);
                }
              }}
              control={
                <Checkbox
                  value="enhancement"
                  checked={
                    !!file.checkedEnhancement || !!file?.has_enhanced_file
                  }
                  onChange={(e) => {
                    if (!file.checkedStaging) {
                      handleEnhancementCheckbox(e, file);
                    }
                  }}
                />
              }
              label="Enhancement"
            />
            <IconButton
              aria-label="info"
              size="small"
              onClick={(e) => setPremiumEnhancementInfo(e.currentTarget)}
            >
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          </div>

          <FormControl sx={{ width: "96px" }}>
            <Select
              name={"enhancement"}
              disabled={
                isSubmitting ||
                !file.checkedEnhancement ||
                !!file?.has_enhanced_file
              }
              value={file?.has_premium_enhancement === true ? 2 : 1}
              onChange={(e) => onSelectPremiumService(e, file)}
            >
              <MenuItem value={1}>{t(`project.basic`)}</MenuItem>
              <MenuItem value={2}>{t(`project.premium`)}</MenuItem>
            </Select>
          </FormControl>
        </ServiceSelectBox>
      </Grid>
      <Grid item xs={2.5} p={3}>
        <FormControl mt={3} fullWidth>
          <Label component="legend">{t("project.room_type")}</Label>
          <Select
            disabled={isSubmitting}
            value={file.room_type || file.metadata.room_type}
            onChange={(e) => onRoomTypeChange(e, file)}
          >
            <MenuItem value={"living_room"}>
              {t(`room_type.living_room`)}
            </MenuItem>
            <MenuItem value={"dining_room"}>
              {t(`room_type.dining_room`)}
            </MenuItem>
            <MenuItem value={"dining_room_and_living_room"}>
              {t(`room_type.dining_room_and_living_room`)}
            </MenuItem>
            <MenuItem value={"bedroom"}>{t(`room_type.bedroom`)}</MenuItem>
            <MenuItem value={"kitchen"}>{t(`room_type.kitchen`)}</MenuItem>
            <MenuItem value={"kitchen_and_dining_room"}>
              {t(`room_type.kitchen_and_dining_room`)}
            </MenuItem>
            <MenuItem value={"kitchen_and_living_room"}>
              {t(`room_type.kitchen_and_living_room`)}
            </MenuItem>
            <MenuItem value={"bathroom"}>{t(`room_type.bathroom`)}</MenuItem>
            <MenuItem value={"office_space"}>
              {t(`room_type.office_space`)}
            </MenuItem>
            <MenuItem value={"other"}>{t(`room_type.other`)}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3.5} p={3}>
        <Label component="legend">{t("project.keywords")}</Label>
        <Keyword
          disabled={isSubmitting}
          value={value}
          inputValue={inputValue}
          setInputValue={setInputValue}
          onChange={onKeywordsChange}
          handleKeyDown={handleKeyDown}
          handleClickOutside={handleClickOutside}
        />
        <InputLabel style={{ paddingTop: "5px", whiteSpace: "normal" }}>
          {t("project.press_enter_return_comma_key_to_add_multiple_keywords")}
        </InputLabel>
      </Grid>

      <Popup
        open={includeInfo}
        anchorEl={includeInfo}
        onClose={() => setIncludeInfo(null)}
        text={
          "Cannot unselect Enhancement when Virtual Staging is selected. Virtual Staging service includes Enhancement."
        }
      />
      <Popup
        open={alreadyEnhanced}
        anchorEl={alreadyEnhanced}
        onClose={() => setAlreadyEnhanced(null)}
        text={"Previously processed."}
      />

      <Popup
        open={premiumEnhancementInfo}
        anchorEl={premiumEnhancementInfo}
        onClose={() => setPremiumEnhancementInfo(null)}
        text={
          "Premium enhancement includes sky replacement and getting rid of clutter (both for exterior and interior photos)."
        }
      />

      <Popup
        open={premiumStagingInfo}
        anchorEl={premiumStagingInfo}
        onClose={() => setPremiumStagingInfo(null)}
        text={"Premium virtual staging includes furniture replacement."}
      />
    </Container>
  );
};

export default ListView;
