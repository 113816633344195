import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { every, includes, map } from "lodash";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  Grid,
  Checkbox,
  CardContent,
  FormControlLabel,
  Alert as MuiAlert,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import ListView from "../ListView";
import StagingStyle from "../StagingStyle";
import { FILE_METADATA_ACTIONS } from "../../constants";

const Alert = styled(MuiAlert)(spacing);
const Typography = styled(MuiTypography)(spacing);

function SelectServiceStep(props) {
  const {
    formik,
    folderId,
    projectTitle,
    stagingStyle,
    isNextBtnClicked,
    virtualStagingContentRef,
    setFieldValue,
    setStagingStyle,
    handleStagingStyle,
  } = props;

  const { values, errors, isSubmitting } = formik;
  const { t } = useTranslation();

  const handleFileActions = (params) => {
    const { file, actions, checked } = params;

    return !checked
      ? file?.actions?.filter((action) => !includes(actions, action))
      : actions;
  };

  const scrollPageByChecked = (param) => {
    if (param) {
      document.getElementById("root").scrollTo({
        left: 0,
        behavior: "smooth",
        top: document.body.scrollHeight,
      });
    }
  };

  const handleSelectAllStaging = (e) => {
    const { checked } = e.target;
    setFieldValue(
      "files",
      map(values.files, (f) => {
        f.checkedStaging = f.checkedEnhancement = !!checked;
        f.actions = handleFileActions({
          file: f,
          checked,
          actions: [
            FILE_METADATA_ACTIONS.ENHANCEMENT,
            FILE_METADATA_ACTIONS.STAGING,
          ],
        });
        return f;
      })
    );

    scrollPageByChecked(checked);
  };

  const handleStagingCheckbox = (e, file) => {
    const { checked } = e.target;
    e.stopPropagation();

    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) {
          f.checkedEnhancement = f.checkedStaging = checked;
          f.actions = handleFileActions({
            file: f,
            checked,
            actions: [
              FILE_METADATA_ACTIONS.ENHANCEMENT,
              FILE_METADATA_ACTIONS.STAGING,
            ],
          });
        }
        return f;
      })
    );
  };

  const handleSelectAllEnhancement = (e) => {
    const { checked } = e.target;
    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (!f.has_file_enhanced) {
          f.checkedEnhancement = !!checked;
          f.actions = handleFileActions({
            file: f,
            checked,
            actions: [FILE_METADATA_ACTIONS.ENHANCEMENT],
          });
          return f;
        }
      })
    );
  };

  const handleEnhancementCheckbox = (e, file) => {
    const { checked } = e.target;
    e.stopPropagation();
    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) {
          f.checkedEnhancement = checked;
          f.actions = handleFileActions({
            file: f,
            checked,
            actions: [FILE_METADATA_ACTIONS.ENHANCEMENT],
          });
        }
        return f;
      })
    );
  };

  useEffect(() => {
    if (isNextBtnClicked) {
      setFieldValue(
        "files",
        map(values.files, (f) => {
          f.checkedEnhancement = true;
          f.actions = handleFileActions({
            file: f,
            checked: true,
            actions: [FILE_METADATA_ACTIONS.ENHANCEMENT],
          });
          return f;
        })
      );
    }
  }, [isNextBtnClicked]);

  return (
    <>
      <Card mb={6}>
        <CardContent>
          {errors.submit && (
            <Alert my={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {isNextBtnClicked && (
            <>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  {isNextBtnClicked ? (
                    <Typography variant="h5" display="inline">
                      {t("project.select_for_virtual_staging")}
                    </Typography>
                  ) : (
                    folderId && (
                      <Typography variant="h3" display="inline">
                        {projectTitle}
                      </Typography>
                    )
                  )}
                </Grid>
                <Grid item xs={3} display="flex" justifyContent="flex-end">
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="max-content"
                    alignItems="flex-start"
                  >
                    <FormControlLabel
                      disabled={isSubmitting}
                      control={
                        <Checkbox
                          checked={every(values.files, {
                            checkedStaging: true,
                          })}
                          onChange={(e) => handleSelectAllStaging(e)}
                          name="selectAllStaging"
                        />
                      }
                      label="Select All - Virtual Staging"
                    />
                    <FormControlLabel
                      disabled={
                        every(values.files, {
                          checkedEnhancement: true,
                          checkedStaging: true,
                        }) || isSubmitting
                      }
                      control={
                        <Checkbox
                          checked={every(values.files, {
                            checkedEnhancement: true,
                          })}
                          onChange={(e) => handleSelectAllEnhancement(e)}
                          name="selectAllEnhancement"
                        />
                      }
                      label="Select All - Enhancement"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1, maxHeight: 750, overflow: "auto" }}>
                <Grid container spacing={1}>
                  <Grid container item>
                    {values?.files.map((file, key) => (
                      <ListView
                        key={key}
                        file={file}
                        formik={formik}
                        stagingStyle={stagingStyle}
                        handleStagingCheckbox={handleStagingCheckbox}
                        handleEnhancementCheckbox={handleEnhancementCheckbox}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          <StagingStyle
            formik={formik}
            stagingStyle={stagingStyle}
            isNextBtnClicked={isNextBtnClicked}
            handleStagingStyle={handleStagingStyle}
            virtualStagingContentRef={virtualStagingContentRef}
            setStagingStyle={setStagingStyle}
          />
        </CardContent>
      </Card>
    </>
  );
}

export default SelectServiceStep;
