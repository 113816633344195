import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
  CircularProgress as MuiCircularProgress,
  Button as MuiButton,
} from "@mui/material";

const CircularProgress = styled(MuiCircularProgress)`
  margin-left: 10px;
`;

const Button = styled(MuiButton)`
  ${spacing};
  min-width: 140px;
`;

const LoadingButton = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <CircularProgress size={20} color={"inherit"} />}
    </Button>
  );
};

export default LoadingButton;
