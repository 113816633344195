import React, { useEffect, useRef, useState } from "react";
import lozad from "lozad";
import { get } from "lodash";

const defaultProps = {
  inComponent: false,
  aspectRatio: "taller",
  handle: null,
  handleSize: 40,
  hover: false,
  leftImageAlt: "",
  leftImageStyle: {},
  leftImageLabel: null,
  onSliderPositionChange: () => {},
  rightImageAlt: "",
  rightImageStyle: {},
  rightImageLabel: null,
  skeleton: null,
  sliderLineColor: "#ffffff",
  sliderLineWidth: 2,
  sliderPositionPercentage: 0.5,
  vertical: false,
  disabledLazyLoading: true,
};

const ReactCompareImage = (props) => {
  const {
    hover,
    handle,
    vertical,
    skeleton,
    hideLabel,
    handleSize,
    aspectRatio,
    inComponent,
    disabledLazyLoading,
    leftImage,
    leftImageAlt,
    leftImageStyle,
    leftImageLabel,
    leftImageLabelStyle,
    rightImage,
    rightImageAlt,
    rightImageStyle,
    rightImageLabel,
    sliderLineColor,
    sliderLineWidth,
    sliderPositionPercentage,
    onSliderPositionChange,
  } = props;

  const horizontal = !vertical;

  // 0 to 1
  const [sliderPosition, setSliderPosition] = useState(
    sliderPositionPercentage
  );
  const [containerWidth, setContainerWidth] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [isSliding, setIsSliding] = useState(false);
  const [leftImageDimensions, setLeftImageDimensions] = useState(null);
  const [rightImageDimensions, setRightImageDimensions] = useState(null);

  const containerRef = useRef(null);
  const rightImageRef = useRef(null);
  const leftImageRef = useRef(null);

  const isMobile =
    !!navigator.maxTouchPoints || "ontouchstart" in document.documentElement;

  const getImageSrcProps = (side) => {
    const k = disabledLazyLoading ? "src" : "data-src";
    const v = side === "left" ? leftImage : rightImage;

    return { [k]: v };
  };

  const leftImageOnLoad = () => {
    const width = get(leftImageRef, "current.naturalWidth");
    const height = get(leftImageRef, "current.naturalHeight");

    width && height && setLeftImageDimensions({ width, height });
  };

  const rightImageOnLoad = () => {
    const width = get(rightImageRef, "current.naturalWidth");
    const height = get(rightImageRef, "current.naturalHeight");

    width && height && setRightImageDimensions({ width, height });
  };

  const recursiveCalculate = ({
    base,
    imageHeight,
    imageWidth,
    maxHeight,
    maxWidth,
  }) => {
    if (base !== "width" && imageHeight > maxHeight) {
      imageWidth = imageWidth * (maxHeight / imageHeight);
      imageHeight = maxHeight;
    }

    if (imageWidth > maxWidth) {
      imageHeight = imageHeight * (maxWidth / imageWidth);
      imageWidth = maxWidth;
    }

    if (
      (base === "width" || imageHeight <= maxHeight) &&
      imageWidth <= maxWidth
    ) {
      if (base === "width") {
        imageHeight = maxHeight;
      }

      return { imageHeight, imageWidth, maxHeight, maxWidth };
    }

    return recursiveCalculate({ imageHeight, imageWidth, maxHeight, maxWidth });
  };

  const styles = {
    fade: {
      animationName: "fade",
      animationDuration: "1s",
      animationTimingFunction: "ease",
      "@keyframes fade": {
        from: { opacity: 0 },
        to: { opacity: 1 },
      },
    },
    container: {
      overflow: "hidden",
      position: "relative",
      boxSizing: "border-box",
      margin: "0 auto",
      width: containerWidth,
      height: containerHeight,
    },
    imageItem: {
      top: 0,
      left: 0,
      objectFit: "cover",
      position: "absolute",
      width: "100%",
      height: "100%",
      objectPosition: "center",
    },
    rightImage: {
      clip: horizontal
        ? `rect(auto, auto, auto, ${containerWidth * sliderPosition}px)`
        : `rect(${containerHeight * sliderPosition}px, auto, auto, auto)`,
    },
    leftImage: {
      clip: horizontal
        ? `rect(auto, ${containerWidth * sliderPosition}px, auto, auto)`
        : `rect(auto, auto, ${containerHeight * sliderPosition}px, auto)`,
    },
    slider: {
      alignItems: "center",
      cursor:
        (!hover && horizontal && "ew-resize") ||
        (!hover && !horizontal && "ns-resize"),
      display: "flex",
      flexDirection: horizontal ? "column" : "row",
      height: horizontal ? "100%" : `${handleSize}px`,
      justifyContent: "center",
      left: horizontal
        ? `${containerWidth * sliderPosition - handleSize / 2}px`
        : 0,
      position: "absolute",
      top: horizontal
        ? 0
        : `${containerHeight * sliderPosition - handleSize / 2}px`,
      width: horizontal ? `${handleSize}px` : "100%",
    },
    line: {
      background: sliderLineColor,
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
      flex: "0 1 auto",
      height: horizontal ? "100%" : `${sliderLineWidth}px`,
      width: horizontal ? `${sliderLineWidth}px` : "100%",
    },
    handleCustom: {
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      flex: "1 0 auto",
      height: "auto",
      justifyContent: "center",
      width: "auto",
    },
    handleDefault: {
      alignItems: "center",
      border: `${sliderLineWidth}px solid ${sliderLineColor}`,
      borderRadius: "100%",
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
      boxSizing: "border-box",
      display: "flex",
      flex: "1 0 auto",
      height: `${handleSize}px`,
      justifyContent: "center",
      width: `${handleSize}px`,
      transform: horizontal ? "none" : "rotate(90deg)",
    },
    leftArrow: {
      border: `inset ${handleSize * 0.15}px rgba(0,0,0,0)`,
      borderRight: `${handleSize * 0.15}px solid ${sliderLineColor}`,
      height: "0px",
      marginLeft: `-${handleSize * 0.25}px`, // for IE11
      marginRight: `${handleSize * 0.25}px`,
      width: "0px",
    },
    rightArrow: {
      border: `inset ${handleSize * 0.15}px rgba(0,0,0,0)`,
      borderLeft: `${handleSize * 0.15}px solid ${sliderLineColor}`,
      height: "0px",
      marginRight: `-${handleSize * 0.25}px`, // for IE11
      width: "0px",
    },
    leftLabel: {
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      left: horizontal ? "5%" : "50%",
      opacity: isSliding ? 0 : 1,
      padding: "6px 12px",
      position: "absolute",
      top: horizontal ? "50%" : "3%",
      transform: horizontal ? "translate(0,-50%)" : "translate(-50%, 0)",
      transition: "opacity 0.1s ease-out",
      ...leftImageLabelStyle,
    },
    rightLabel: {
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      opacity: isSliding ? 0 : 1,
      padding: "6px 12px",
      position: "absolute",
      left: horizontal ? null : "50%",
      right: horizontal ? "5%" : null,
      top: horizontal ? "50%" : null,
      bottom: horizontal ? null : "3%",
      transform: horizontal ? "translate(0,-50%)" : "translate(-50%, 0)",
      transition: "opacity 0.1s ease-out",
    },
    leftLabelContainer: {
      clip: horizontal
        ? `rect(auto, ${containerWidth * sliderPosition}px, auto, auto)`
        : `rect(auto, auto, ${containerHeight * sliderPosition}px, auto)`,
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    rightLabelContainer: {
      clip: horizontal
        ? `rect(auto, auto, auto, ${containerWidth * sliderPosition}px)`
        : `rect(${containerHeight * sliderPosition}px, auto, auto, auto)`,
      height: "100%",
      position: "absolute",
      width: "100%",
    },
  };

  useEffect(() => {
    setAllImagesLoaded(leftImageDimensions && rightImageDimensions);
  }, [leftImageDimensions, rightImageDimensions]);

  useEffect(() => {
    if (!rightImageDimensions) return;

    const ySpacing = inComponent ? 0 : 120;
    const xSpacing = inComponent ? 0 : isMobile ? 80 : 180;

    const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
    const parentWidth = containerRef.current.parentElement.clientWidth;
    const parentHeight = containerRef.current.parentElement.clientHeight;

    const result = recursiveCalculate({
      base: inComponent ? "width" : "height",
      imageWidth: rightImageDimensions.width,
      imageHeight: rightImageDimensions.height,
      maxHeight: (inComponent ? parentHeight : windowHeight) - ySpacing,
      maxWidth:
        (inComponent ? parentWidth : windowWidth - (isMobile ? 30 : 384)) -
        xSpacing,
    });

    setContainerWidth(result.imageWidth);
    setContainerHeight(result.imageHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, inComponent, rightImageDimensions]);

  useEffect(() => {
    rightImageOnLoad();

    window.addEventListener("resize", rightImageOnLoad);

    const resizeObserver = new ResizeObserver(rightImageOnLoad);
    resizeObserver.observe(containerRef.current);

    return () => {
      window.removeEventListener("resize", rightImageOnLoad);

      resizeObserver.disconnect();
    };
  }, [containerRef]);

  useEffect(() => {
    const handleSliding = (e) => {
      if (!e) return;

      // Calc cursor position from the:
      // - left edge of the viewport (for horizontal)
      // - top edge of the viewport (for vertical)
      const cursorXfromViewport = e.touches ? e.touches[0].pageX : e.pageX;
      const cursorYfromViewport = e.touches ? e.touches[0].pageY : e.pageY;

      // Calc Cursor Position from the:
      // - left edge of the window (for horizontal)
      // - top edge of the window (for vertical)
      // to consider any page scrolling
      const cursorXfromWindow = cursorXfromViewport - window.scrollX;
      const cursorYfromWindow = cursorYfromViewport - window.scrollY;

      // Calc Cursor Position from the:
      // - left edge of the image(for horizontal)
      // - top edge of the image(for vertical)
      const imagePosition = rightImageRef.current.getBoundingClientRect();
      let pos = horizontal
        ? cursorXfromWindow - imagePosition.left
        : cursorYfromWindow - imagePosition.top;

      // Set minimum and maximum values to prevent the slider from overflowing
      const minPos = sliderLineWidth / 2;
      const maxPos = horizontal
        ? containerWidth - sliderLineWidth / 2
        : containerHeight - sliderLineWidth / 2;

      if (pos < minPos) pos = minPos;
      if (pos > maxPos) pos = maxPos;

      horizontal
        ? setSliderPosition(pos / containerWidth)
        : setSliderPosition(pos / containerHeight);

      // If there's a callback function, invoke it everytime the slider changes
      if (onSliderPositionChange) {
        horizontal
          ? onSliderPositionChange(pos / containerWidth)
          : onSliderPositionChange(pos / containerHeight);
      }
    };

    const startSliding = (e) => {
      setIsSliding(true);

      // Prevent default behavior other than mobile scrolling
      if (!("touches" in e)) {
        e.preventDefault();
      }

      // Slide the image even if you just click or tap (not drag)
      handleSliding(e);

      window.addEventListener("mousemove", handleSliding); // 07
      window.addEventListener("touchmove", handleSliding); // 08
    };

    const finishSliding = () => {
      setIsSliding(false);
      window.removeEventListener("mousemove", handleSliding);
      window.removeEventListener("touchmove", handleSliding);
    };

    const containerElement = containerRef.current;

    if (allImagesLoaded) {
      containerElement.addEventListener("touchstart", startSliding);
      window.addEventListener("touchend", finishSliding);

      if (hover) {
        containerElement.addEventListener("mousemove", handleSliding);
        containerElement.addEventListener("mouseleave", finishSliding);
      } else {
        containerElement.addEventListener("mousedown", startSliding);
        window.addEventListener("mouseup", finishSliding);
      }
    }

    return () => {
      containerElement.removeEventListener("touchstart", startSliding);
      window.removeEventListener("touchend", finishSliding);
      containerElement.removeEventListener("mousemove", handleSliding);
      containerElement.removeEventListener("mouseleave", finishSliding);
      containerElement.removeEventListener("mousedown", startSliding);
      window.removeEventListener("mouseup", finishSliding);
      window.removeEventListener("mousemove", handleSliding);
      window.removeEventListener("touchmove", handleSliding);
    };
  }, [
    allImagesLoaded,
    aspectRatio,
    containerHeight,
    containerWidth,
    horizontal,
    hover,
    onSliderPositionChange,
    sliderLineWidth,
    vertical,
  ]);

  useEffect(() => {
    if (disabledLazyLoading) return;

    // noinspection JSUnusedGlobalSymbols
    const { observe } = lozad(".lozad", {
      loaded: (el) => el.classList.add("fade"),
    });

    observe();
  });

  return (
    <>
      {
        <div
          ref={containerRef}
          style={styles.container}
          className="rci-container"
        >
          {skeleton && !allImagesLoaded && (
            <div style={{ ...styles.container }}>{skeleton}</div>
          )}

          <>
            <img
              alt={leftImageAlt}
              ref={leftImageRef}
              data-alt={leftImageAlt}
              onLoad={leftImageOnLoad}
              key={`left-${
                getImageSrcProps("left")["src"] ||
                getImageSrcProps("left")["data-src"]
              }`}
              {...getImageSrcProps("left")}
              className="lozad rci-left-image"
              style={{
                ...styles.imageItem,
                ...styles.leftImage,
                ...leftImageStyle,
              }}
            />

            <img
              alt={rightImageAlt}
              ref={rightImageRef}
              data-alt={rightImageAlt}
              onLoad={rightImageOnLoad}
              key={`right-${
                getImageSrcProps("right")["src"] ||
                getImageSrcProps("right")["data-src"]
              }`}
              {...getImageSrcProps("right")}
              className="lozad rci-right-image"
              style={{
                ...styles.imageItem,
                ...styles.rightImage,
                ...rightImageStyle,
              }}
            />
          </>

          <div style={styles.slider}>
            <div style={styles.line} />
            {handle ? (
              <div style={styles.handleCustom}>{handle}</div>
            ) : (
              <div style={styles.handleDefault}>
                <div style={styles.leftArrow} />
                <div style={styles.rightArrow} />
              </div>
            )}
            <div style={styles.line} />
          </div>

          {!hideLabel && leftImageLabel && (
            <div style={styles.leftLabelContainer}>
              <div style={styles.leftLabel}>{leftImageLabel}</div>
            </div>
          )}

          {!hideLabel && rightImageLabel && (
            <div style={styles.rightLabelContainer}>
              <div style={styles.rightLabel}>{rightImageLabel}</div>
            </div>
          )}
        </div>
      }
    </>
  );
};

ReactCompareImage.defaultProps = defaultProps;

export default ReactCompareImage;
