import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import AddPaymentMethodForm from "../components/forms/AddPaymentMethod";
import { NavLink } from "react-router-dom";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function AddPaymentMethod() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("payment.add_payment_method")} />

      <Typography variant="h3" gutterBottom display="inline">
        {t("payment.add_payment_method")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>{t("dashboard")}</Typography>
        <Link component={NavLink} to="/dashboard/payment">
          {t("payment.payment_title")}
        </Link>
        <Typography>{t("payment.add_payment_method")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <Grid item xs={12} sm={6} margin={"auto"}>
              <CardContent>
                <AddPaymentMethodForm />
              </CardContent>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddPaymentMethod;
