/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  Divider as MuiDivider,
  TextField,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Close, NavigateBefore, NavigateNext } from "@mui/icons-material";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";

import axios from "../../utils/axios";
import { getFileInformation } from "../../utils/fileFilteringHelper";
import {} from "../../utils/fileFilteringHelper";
import dateFormatter from "../../utils/dateFormatter";

import { FILE_STATUS, FILE_REVIEW_STATUS } from "../../constants";
import {
  Modal,
  CloseButton,
  CircleButton,
  ModalWrapper,
  CompareImage,
  ModalViewport,
  ReviewBarGrid,
  ViewportContent,
  ViewportNavItem,
  ViewportReviewBar,
  NoCommentBox,
  CommentBox,
} from "./CompareModalComponents";
import LoadingButton from "../LoadingButton";

const Divider = styled(MuiDivider)(spacing);

const BlueText = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const CancelIcon = styled(CancelRoundedIcon)`
  color: ${(props) => props.theme.palette.error.main};
  transform: scale(0.55);
`;

const ApproveIcon = styled(CheckCircleSharpIcon)`
  color: ${(props) => props.theme.palette.success.main};
  transform: scale(0.55);
`;

const NoCommentIcon = styled(ChatRoundedIcon)`
  font-size: 100px;
  color: #c4c4c4;
  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 50px;
  }
`;

export default function CompareModal({
  files,
  hideLabel,
  leftLabel,
  rightLabel,
  hideFooter,
  compareModal,
  defaultStatus,
  setCompareModal,
  compareModalFile,
  hasComments = false,
  getFiles = () => {},
}) {
  const { t } = useTranslation();
  let { id } = useParams();
  const [current, setCurrent] = useState();
  const [allNotes, setAllNotes] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      note: "",
      liked: "1",
    },
    validationSchema: Yup.object().shape({
      note: Yup.string(),
    }),
  });

  const { handleChange, values, handleBlur, errors, setFieldError, resetForm } =
    formik;

  const next = () => {
    const { id, activeTab } = compareModal;
    const index = files.findIndex((f) => f.hid === id);
    if (index < files.length - 1) {
      setCompareModal({ open: true, id: files[index + 1].hid, activeTab });
    }
  };
  const prev = () => {
    const { id, activeTab } = compareModal;
    const index = files.findIndex((f) => f.hid === id);
    if (index > 0) {
      setCompareModal({ open: true, id: files[index - 1].hid, activeTab });
    }
  };

  useEffect(() => {
    setCurrent(files.findIndex((f) => f.hid === compareModal.id));
  }, [compareModal]);

  useEffect(() => {
    getFileReviews();
  }, [compareModalFile]);

  const getFileReviews = () => {
    // don't do anything: defenc
    if (!hasComments || !compareModal.open || !compareModalFile) {
      return;
    }

    // get id of the file info
    const { modified_file_hid: hid } = getFileInformation(
      compareModal.activeTab,
      compareModalFile
    );

    axios
      .get(`/file-reviews?file_id=${hid}&include=submitted_by`)
      .then(({ data }) => {
        setAllNotes(data);
      });
  };

  const handleFileFeedback = () => {
    setLoading(true);

    const { modified_file_hid: hid } = getFileInformation(
      compareModal.activeTab,
      compareModalFile
    );

    const payload = {
      file_id: hid,
      status: parseInt(values.liked),
    };
    if (values.note) {
      payload.notes = values.note;
    }
    axios.post(`/file-reviews`, payload).then(() => {
      resetForm();
      getFileReviews();
      setLoading(false);
      toast.success("Thank you! Your response has been recorded.");
    });
  };

  const handleFileReview = (status) => {
    setLoading(status);

    const { modified_file_hid: hid } = getFileInformation(
      compareModal.activeTab,
      compareModalFile
    );

    const payload = {
      file_id: hid,
      status: status,
    };
    if (values.note) {
      payload.notes = values.note;
    }
    axios.post(`/file-reviews`, payload).then(() => {
      resetForm();
      getFiles(id, false);
      setLoading(false);
    });
  };

  const handleReject = () => {
    if (!values.note) {
      setFieldError("note", t("validation.enter_comment"));
      return;
    }
    handleFileReview(FILE_REVIEW_STATUS.REJECTED);
  };

  // This component is rendered, even when there's no compareModalFile
  // We return empty component preemptively,
  // to enforce displaying nothing when there's no compareModalFile
  if (!compareModalFile) {
    return <></>;
  }

  const { activeTab } = compareModal;

  const { file_name, public_url, hid, modified_file_status, modified_url } =
    getFileInformation(activeTab, compareModalFile);

  return (
    <>
      <Modal
        open={compareModal.open}
        onClose={() => {
          setCompareModal({ open: false, id: null, activeTab: null });
        }}
      >
        <ModalWrapper>
          <CloseButton
            variant="contained"
            onClick={() => {
              setCompareModal({ open: false, id: null, activeTab: null });
            }}
          >
            <Close />
          </CloseButton>

          <ModalViewport className={"ModalViewport"}>
            <ViewportNavItem className={"NavigationPrev"}>
              <CircleButton
                onClick={prev}
                variant="contained"
                disabled={current === 0}
                children={<NavigateBefore />}
              />
            </ViewportNavItem>
            <ViewportContent className={"ImageViewportContent"}>
              <div>
                <CompareImage
                  hideLabel={hideLabel}
                  left={modified_url}
                  right={public_url}
                  leftLabel={leftLabel || ""}
                  rightLabel={rightLabel || ""}
                  disabledLazyLoading={true}
                  status={!defaultStatus ? modified_file_status : defaultStatus}
                />
                {!hideFooter && (
                  <ReviewBarGrid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={"ViewportTitle"}>
                        {compareModalFile?.file_name}
                      </Typography>
                    </Grid>
                  </ReviewBarGrid>
                )}
              </div>

              {hasComments &&
                allNotes !== null &&
                allNotes.length === 0 &&
                activeTab === "enhancement" &&
                modified_file_status === FILE_STATUS.COMPLETED && (
                  <ViewportReviewBar>
                    <Typography mb={1}>Feedback</Typography>
                    <Divider mb={3} />
                    <CommentBox>
                      <FormControl>
                        <FormLabel id="radio-buttons-group-label">
                          Do you like this photo?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="radio-buttons-group-label"
                          value={values.liked}
                          defaultValue={"1"}
                          onChange={handleChange}
                          name="liked"
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="I love it!"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="It can be improved."
                          />
                        </RadioGroup>
                      </FormControl>
                    </CommentBox>
                    <FormControl>
                      <FormLabel>
                        {values.liked === "1"
                          ? "Feel free to add any comments!"
                          : "Let us know what we can improve."}
                      </FormLabel>
                      <TextField
                        name="note"
                        placeholder={"Write your comments"}
                        autoFocus
                        margin="dense"
                        id="note"
                        type="text"
                        fullWidth
                        multiline
                        rows={5}
                        value={values.note}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Grid item mt={3} marginLeft={"auto"}>
                      <LoadingButton
                        loading={loading}
                        variant="contained"
                        color="success"
                        onClick={() => handleFileFeedback()}
                      >
                        {t("send")}
                      </LoadingButton>
                    </Grid>
                  </ViewportReviewBar>
                )}

              {hasComments && activeTab === "staging" && (
                <ViewportReviewBar>
                  <Typography mb={1}>Comments</Typography>
                  <Divider mb={3} />
                  {allNotes !== null && allNotes.length == 0 && (
                    <NoCommentBox>
                      <NoCommentIcon />
                      <Typography
                        variant="h6"
                        color={"gray"}
                        textAlign={"center"}
                      >
                        No comments
                      </Typography>
                    </NoCommentBox>
                  )}
                  <CommentBox>
                    {allNotes !== null &&
                      allNotes.length !== 0 &&
                      allNotes.map((note) => (
                        <div key={note.id}>
                          <Typography>
                            <BlueText>
                              {get(note, "submitted_by.full_name")}
                            </BlueText>{" "}
                            • {dateFormatter(get(note, "created_at"))}
                          </Typography>
                          <Grid container alignItems={"center"}>
                            {get(note, "status") === 2 ? (
                              <>
                                <Typography>Rejected</Typography>
                                <CancelIcon />
                              </>
                            ) : (
                              <>
                                <Typography>Approved</Typography>
                                <ApproveIcon />
                              </>
                            )}
                          </Grid>
                          <Typography>{get(note, "notes")}</Typography>
                          <Divider my={1} />
                        </div>
                      ))}
                  </CommentBox>

                  <form noValidate>
                    <TextField
                      name="note"
                      placeholder={"Write your comments"}
                      autoFocus
                      margin="dense"
                      id="note"
                      type="text"
                      fullWidth
                      multiline
                      rows={5}
                      error={errors.note}
                      helperText={errors.note}
                      value={values.note}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={
                        modified_file_status ===
                        FILE_STATUS.WAITING_FOR_REVISION
                      }
                    />
                  </form>

                  <Grid item mt={3} textAlign={"right"} display={"flex"}>
                    <LoadingButton
                      loading={loading === 2}
                      disabled={
                        modified_file_status ===
                        FILE_STATUS.WAITING_FOR_REVISION
                      }
                      style={{ marginRight: 8 }}
                      variant="contained"
                      color="error"
                      onClick={() => handleReject()}
                    >
                      {t("reject")}
                    </LoadingButton>
                    <LoadingButton
                      loading={loading === 1}
                      disabled={
                        modified_file_status == FILE_STATUS.COMPLETED ||
                        modified_file_status ===
                          FILE_STATUS.WAITING_FOR_REVISION
                      }
                      variant="contained"
                      color="success"
                      onClick={() =>
                        handleFileReview(FILE_REVIEW_STATUS.APPROVED)
                      }
                    >
                      {t("approve")}
                    </LoadingButton>
                  </Grid>
                </ViewportReviewBar>
              )}
            </ViewportContent>
            <ViewportNavItem className={"NavigationNext"}>
              <CircleButton
                onClick={next}
                variant="contained"
                disabled={current === files.length - 1}
                children={<NavigateNext />}
              />
            </ViewportNavItem>
          </ModalViewport>
        </ModalWrapper>
      </Modal>
    </>
  );
}
