import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

function ResetPassword({ token }) {
  const { t } = useTranslation();
  const { forgotPassword, resetPassword } = useAuth();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    submit: false,
  };

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("validation.invalid_email"))
      .max(255)
      .required(t("validation.enter_your_email")),
  });
  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(7, t("validation.password_must_least_7_characters"))
      .required(t("validation.enter_your_password")),
    confirmPassword: Yup.string()
      .min(7, t("validation.password_must_least_7_characters"))
      .required(t("validation.enter_your_password"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("validation.password_need_same")
        ),
      }),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        token ? passwordValidationSchema : emailValidationSchema
      }
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, setValues }
      ) => {
        try {
          token
            ? await resetPassword(values.password, token).then(() => {
                setStatus({ sent: true });
                navigate("/sign-in", { state: { message: t("auth.your_password_changed") } })
              })
            : await forgotPassword(values.email).then(() => {
                setStatus({
                  sent: true,
                  messages: t("auth.we_sent_you_email_to_reset_password"),
                });
              });
          setValues({ values: initialValues }, false);
        } catch (error) {
          const message = error.response.data.message || "Something went wrong";
          setStatus({ sent: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        status,
        setStatus,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {status?.messages && (
            <Alert
              severity="success"
              my={3}
              onClose={() => setStatus({ sent: false })}
            >
              {status.messages}
            </Alert>
          )}

          {errors.submit && (
            <Alert my={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {token ? (
            <>
              <TextField
                type="password"
                name="password"
                label={t("auth.password")}
                value={values.password || ""}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
              <TextField
                type="password"
                name="confirmPassword"
                label={t("auth.confirm_password")}
                value={values.confirmPassword || ""}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
            </>
          ) : (
            <TextField
              type="email"
              name="email"
              label={t("auth.email")}
              value={values.email || ""}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
          )}

          <Button
            mt={3}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t("auth.reset_password")}
          </Button>
          <Button component={Link} to="/sign-in" fullWidth color="primary">
            {t("auth.signin")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
