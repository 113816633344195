import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";
import ResetPasswordForm from "../../components/auth/ResetPassword";
import { useParams } from "react-router-dom";

const Logo = styled.img`
  width: 105px;
  padding-bottom: 30px;
`;

const Wrapper = styled(Paper)`
  width: 100%;
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  const { t } = useTranslation();
  const { token } = useParams();

  return (
    <React.Fragment>
      <Helmet title={t("auth.reset_password")} />

      <Box sx={{ maxWidth: "500px" }}>
        <a href="https://studio.odaportal.com/" target="_blank" rel="noreferrer">
          <Logo src={"/oda-logo.png"} alt={"oda studio"} />
        </a>
        <Wrapper>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            {t("auth.reset_password")}
          </Typography>
          <Typography component="h2" variant="body1" align="center" mb={3}>
            {token
              ? t("auth.enter_your_new_password")
              : t("auth.enter_your_email_to_reset_password")}
          </Typography>
          <ResetPasswordForm token={token} />
        </Wrapper>
      </Box>
    </React.Fragment>
  );
}

export default ResetPassword;
