import amplitude from "amplitude-js";
import { get, map, flatten } from "lodash";
import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { apolloClient } from "../utils/react-apollo";
import { isValidToken, setSession } from "../utils/jwt";
import { sendAmplitudeData, setAmplitudeUserId } from "../utils/amplitude";

import { GET_AUTHENTICATED_USER_PERMISSIONS } from "../queries/authorization";
import Loader from "../components/Loader";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const GET_STATISTICS = "GET_STATISTICS";

const disableCardRequirement = true;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userHasCard: false,
  organization: null,
  statistics: null,
};

const JWTReducer = (state, action) => {
  const userHasCard =
    disableCardRequirement ||
    !!get(action.payload.user, "attributes.stripe_card_token");

  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        userHasCard,
        organization: action.payload.organization,
        authorization: action.payload.authorization,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userHasCard,
        organization: action.payload.organization,
        authorization: action.payload.authorization,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userHasCard: false,
        organization: null,
        statistics: null,
        authorization: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userHasCard,
        organization: action.payload.organization,
        authorization: action.payload.authorization,
      };

    case GET_STATISTICS:
      return {
        ...state,
        statistics: action.payload.statistics,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState, () => ({}));

  useEffect(() => {
    const initialize = async () => {
      let user = null;
      let authorization = null;
      let isAuthenticated = false;

      const pUrl = new URL(window.location);
      const adminAccessToken = pUrl.searchParams.get("access_token");
      const accessToken = window.localStorage.getItem("accessToken");

      // if user has admin access token, they won't have hasura access token
      // hence we signout & signin the user
      try {
        if (adminAccessToken) {
          await signOut();
          setSession(adminAccessToken, null);
          user = await getMe();
          setSession(adminAccessToken, user.access_token_hasura);
          isAuthenticated = true;
        } else if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, null);
          user = await getMe();

          setSession(accessToken, user.access_token_hasura);
          isAuthenticated = true;
        }
      } catch (e) {
        isAuthenticated = false;
      }

      const organization = user
        ? get(user, "relationships.organization.data")
        : null;

      if (isAuthenticated) {
        authorization = await getAuthenticatedUserPermissions();
      }

      // noinspection JSCheckFunctionSignatures
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated,
          user,
          organization,
          authorization,
        },
      });
    };

    initialize().then(() => {});
  }, []);

  const signIn = async (email, password) => {
    await getToken(email, password);

    const user = await getMe();
    const organization = get(user, "relationships.organization.data");

    const authorization = await getAuthenticatedUserPermissions();

    // noinspection JSCheckFunctionSignatures
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        organization,
        authorization,
      },
    });
    sendAmplitudeData("logged");
  };

  const signOut = async () => {
    setSession(null, null);

    // noinspection JSCheckFunctionSignatures
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (
    email,
    password,
    first_name,
    last_name,
    organization,
    invitationToken
  ) => {
    const data = {
      email,
      password,
      first_name,
      last_name,
      organization: { name: organization },
    };

    invitationToken && (data.invitation_token = invitationToken);
    const response = await axios.post("/oauth/register", data);
    const user = response.data.data;
    const organizationInfo = get(user, "relationships.organization.data");
    await getToken(email, password);

    const authorization = await getAuthenticatedUserPermissions();

    // noinspection JSCheckFunctionSignatures
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
        organization: organizationInfo,
        authorization,
      },
    });
  };

  const forgotPassword = async (email) => {
    await axios.post("/account/forgot-password", {
      email,
    });
  };

  const resetPassword = async (password, token) => {
    await axios.post("/account/reset-password", {
      password,
      token,
    });
  };

  const getToken = async (username, password) => {
    const response = await axios.post("/oauth/token", {
      username,
      password,
    });
    const { access_token, access_token_hasura } = response.data;
    await setSession(access_token, access_token_hasura);
  };

  const getMe = async () => {
    const { data: response } = await axios.get("/oauth/me");
    setAmplitudeUserId(response.data.id);
    const { first_name, last_name, email, stripe_card_token } =
      response.data.attributes;
    const { name } = response.included[0].attributes;
    const { id } = response.included[0];
    const userProperties = {
      first_name,
      last_name,
      email,
      organization_id: id,
      organization_name: name,
      is_paying: !!stripe_card_token,
    };
    amplitude.getInstance().setUserProperties(userProperties);
    localStorage.setItem("fileStackSignature", response.filestack.signature);
    localStorage.setItem("fileStackPolicy", response.filestack.policy);

    return {
      access_token_hasura: response.access_token_hasura,
      id: response.data.id,
      ...response.data,
      included: response.included,
    };
  };

  const getStatistics = async () => {
    const response = await axios.get("/statistics/dashboard");
    const statistics = response.data.data;

    // noinspection JSCheckFunctionSignatures
    dispatch({
      type: GET_STATISTICS,
      payload: {
        statistics,
      },
    });
  };

  const refreshAuthState = async () => {
    const user = await getMe();
    const organization = get(user, "relationships.organization.data");

    // noinspection JSCheckFunctionSignatures
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        organization,
      },
    });
  };

  if (!state.isInitialized) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        forgotPassword,
        resetPassword,
        getStatistics,
        refreshAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

async function getAuthenticatedUserPermissions() {
  const { data } = await apolloClient.query({
    query: GET_AUTHENTICATED_USER_PERMISSIONS,
  });

  const roles = map(get(data, "roles", []), ({ id, name }) => ({ id, name }));
  const role_names = map(roles, "name");

  const permissions = map(
    flatten(map(get(data, "roles", []), "roles_permissions")),
    ({ permission: { id, name } }) => ({ id, name })
  );
  const permission_names = map(permissions, "name");

  return { roles, role_names, permissions, permission_names };
}

export { AuthContext, AuthProvider };
