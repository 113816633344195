/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { get, map, every } from "lodash";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Link,
  Radio,
  Button,
  Checkbox,
  Typography,
  Breadcrumbs,
  CardContent,
  FormControlLabel,
  Card as MuiCard,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { CheckCircleOutline } from "@mui/icons-material";

import axios from "../utils/axios";

import { STAGING_STYLES } from "../constants";
import ListViewCard from "../components/ListViewCard";
import LoadingButton from "../components/LoadingButton";

const boxStyle = {
  margin: "20px 0",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const BackButton = styled(Button)`
  width: 155px;
  color: #5bbac7;
  border-color: #5bbac7;

  &:hover {
    color: white;
    border-color: #5bbac7;
    background-color: #5bbac7;
  }
`;

const SubmitButton = styled(LoadingButton)`
  width: 155px;
  background-color: #5bbac7;

  &:hover {
    background-color: #287e89;
  }
`;

const StagingCheckbox = (props) => {
  const { url, name, value, isDisabled, stagingStyle, handleStagingStyle } =
    props;

  const imgStyle = {
    width: "100%",
    height: "auto",
    opacity: stagingStyle === value ? "0.2" : "1",
  };

  const divStyle = {
    backgroundColor: stagingStyle === value ? "#0080005e" : "",
  };

  const radioStyle = {
    display: "none",
  };

  const iconStyle = {
    top: "50%",
    left: "50%",
    fill: "white",
    position: "absolute",
    transform: "translate(-50%, -50%)",
  };

  return (
    <Grid item xs={3} sx={{ opacity: isDisabled ? 0.2 : 1 }}>
      <FormControlLabel
        style={{
          margin: "unset",
          position: "relative",
          width: "100%",
          maxWidth: "400px",
        }}
        value={value}
        control={
          <div style={divStyle}>
            <Radio
              value={value}
              style={radioStyle}
              name="radio-buttons"
              disabled={isDisabled}
              checked={stagingStyle === value}
              onChange={(e) => handleStagingStyle(e)}
            />
            <img src={url} style={imgStyle} alt="" />
            {stagingStyle === value && (
              <CheckCircleOutline
                style={iconStyle}
                sx={{
                  fontSize: {
                    lg: 70,
                    md: 50,
                    sm: 15,
                    xs: 10,
                  },
                }}
              />
            )}
          </div>
        }
        label=""
      />
      <Typography variant="h6" align="center">
        {name}
      </Typography>
    </Grid>
  );
};

function EnhancementToStaging() {
  const { id } = useParams();
  const projectId = id;
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const files = get(location, "state.enhancementFiles");
  const [isDisabled, setIsDisabled] = useState(true);
  const [stagingStyle, setStagingStyle] = useState("");
  const stagingOptions = [
    {
      url: "/static/img/stagingStyles/modern.jpeg",
      value: STAGING_STYLES.MODERN,
    },
    {
      url: "/static/img/stagingStyles/scandinavian.jpeg",
      value: STAGING_STYLES.SCANDINAVIAN,
    },
    {
      url: "/static/img/stagingStyles/traditional.jpeg",
      value: STAGING_STYLES.TRADITIONAL,
    },
    {
      url: "/static/img/stagingStyles/coastal.jpeg",
      value: STAGING_STYLES.COASTAL,
    },
  ];

  const handleSelectAllFiles = (e) => {
    const { checked } = e.target;

    setFieldValue(
      "files",
      map(files, (f) => {
        f.checked = !!checked;
        return f;
      })
    );
  };

  const handleStagingStyle = (e) => {
    const { value } = e.target;
    setStagingStyle(value);
  };

  const resetState = () => {
    navigate(location.pathname, { replace: true });
  };

  const handleStagingCheckbox = (e, file) => {
    const { checked } = e.target;
    e.stopPropagation();

    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) f.checked = checked;
        return f;
      })
    );
  };

  const onKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  const formik = useFormik({
    initialValues: {
      files: [],
    },
    validationSchema: Yup.object().shape({
      files: Yup.mixed().required(),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const payload = map(get(values, "files"), (f) => {
          const { room_type, keywords } = f?.metadata;
          return {
            id: get(f, "hid"),
            keywords,
            room_type,
            staging_style: get(f, "checked") ? stagingStyle : "",
          };
        });
        await axios.post("/files/send-to-staging", { files: payload });

        resetState();
        resetForm({});
      } catch (error) {
        map(values.files, (f) => (f.state = "failed"));
        setErrors({
          submit: get(error, "response.data.message") || "Something went wrong",
        });
      } finally {
        setSubmitting(false);
        if (projectId) {
          navigate(`/dashboard/projects/${projectId}`, {
            state: {
              tabValue: "staging",
              message: t(
                "project.success_your_photos_are_sent_to_virtual_staging"
              ),
            },
          });
        }
      }
    },
  });

  const { values, isSubmitting, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    if (get(values, "files").filter((f) => f.checked).length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setStagingStyle(null);
    }
  }, [values.files, values]);

  useEffect(() => {
    if (files) {
      setFieldValue(
        "files",
        map(files, (f) => {
          f.checked = true;
          return f;
        })
      );
    }
  }, [files, setFieldValue]);

  return (
    <React.Fragment>
      <Helmet title={t("project_detail.title")} />
      <Typography variant="h3" gutterBottom display="inline">
        {get(location, "state.projectTitle")}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography> {t("dashboard")}</Typography>
        <Typography>{t("projects")}</Typography>
        <Link component={NavLink} to={`/dashboard/projects/${id}`}>
          {get(location, "state.projectTitle")}
        </Link>
      </Breadcrumbs>
      <Divider my={6} />
      <Box
        display="flex"
        my={5}
        columnGap="10px"
        justifyContent={{ lg: "end", sm: "end", xs: "center" }}
      >
        <BackButton
          variant="outlined"
          onClick={() => navigate(`/dashboard/projects/${id}`)}
        >
          {t("back")}
        </BackButton>
        <SubmitButton
          disabled={
            values.files.filter((f) => f.checked).length > 0 && !stagingStyle
          }
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          {t("submit")}
        </SubmitButton>
      </Box>
      <form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>
        <Card mb={6}>
          <CardContent>
            <Box style={boxStyle}>
              <Typography variant="h5" display="inline">
                {t("project.select_for_virtual_staging")}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={every(files, { checked: true })}
                    onChange={(e) => handleSelectAllFiles(e)}
                    name="selectAll"
                  />
                }
                label="Select All"
              />
            </Box>
            <Box sx={{ flexGrow: 1, maxHeight: 750, overflow: "auto" }}>
              <Grid container spacing={1}>
                <Grid container item>
                  {files?.map((file, key) => {
                    return (
                      <ListViewCard
                        key={key}
                        file={file}
                        formik={formik}
                        stagingStyle={stagingStyle}
                        handleStagingCheckbox={handleStagingCheckbox}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Typography
              variant="h3"
              mb={4}
              sx={{
                fontSize: {
                  lg: 20,
                  md: 20,
                  sm: 15,
                  xs: 10,
                },
              }}
            >
              {t("project.virtual_staging_style")}
            </Typography>
            <Grid container item spacing={3}>
              {stagingOptions.map((item, key) => (
                <StagingCheckbox
                  key={key}
                  url={item.url}
                  name={item.value}
                  value={item.value}
                  isDisabled={isDisabled}
                  stagingStyle={stagingStyle}
                  handleStagingStyle={handleStagingStyle}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} display="flex" justifyContent="center">
          <SubmitButton
            disabled={
              values.files.filter((f) => f.checked).length > 0 && !stagingStyle
            }
            style={{ width: "300px" }}
            my={4}
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            {t("submit")}
          </SubmitButton>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default EnhancementToStaging;
