export default function dateFormatter(date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let fullDate = new Date(date);

  let twoDigitMonth = fullDate.getMonth();
  if (twoDigitMonth.length === 1) {
    twoDigitMonth = "0" + twoDigitMonth;
  }

  let twoDigitDate = fullDate.getDate() + "";
  if (twoDigitDate.length === 1) {
    twoDigitDate = "0" + twoDigitDate;
  }

  const monthName = monthNames[twoDigitMonth];

  return `${monthName} ${twoDigitDate}, ${fullDate.getFullYear()}`;
}
