import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, Alert, CardContent } from "@mui/material";
import UploadInput from "../UploadInput";

function UploadStep(props) {
  const { formik, location, setActiveStep } = props;
  const { t } = useTranslation();

  return (
    <Card mb={6}>
      {location?.state?.isCardAdded && (
        <Box px={4} my={3}>
          <Alert my={3} severity="success">
            {t("project.credit_card_successfully_added")}
          </Alert>
        </Box>
      )}
      <CardContent>
        <UploadInput formik={formik} setActiveStep={setActiveStep} />
      </CardContent>
    </Card>
  );
}

export default UploadStep;
