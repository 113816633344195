import React from "react";
import {
  Alert,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "../components/LoadingButton";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CREATE_PROPERTY_MUTATION,
  DELETE_ALL_USERS_FOR_PROPERTY_MUTATION,
  GET_PROPERTIES_QUERY,
  GET_USER_LIST_FOR_PROPERTY_QUERY,
  SET_ALLOWED_USERS_TO_PROPERTY_MUTATION,
  UPDATE_PROPERTY_MUTATION,
} from "../queries/properties";
import { useMutation, useQuery } from "@apollo/client";
import { find, map } from "lodash";
import styled from "styled-components/macro";

const Title = styled(Typography)`
  padding-top: 16px;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.primary.main};
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddPropertyModal({
  open,
  setOpen,
  values: editableValues = {}, // it means this is edit form
}) {
  const { t } = useTranslation();

  const [createProperty, { loading: createLoading, error: createError }] =
    useMutation(CREATE_PROPERTY_MUTATION);
  const [updateProperty, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_PROPERTY_MUTATION);
  const { data: users, loading: usersLoading } = useQuery(
    GET_USER_LIST_FOR_PROPERTY_QUERY
  );
  const [
    deleteAllUsersForProperty,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_ALL_USERS_FOR_PROPERTY_MUTATION);

  const [
    addSelectedUserToProperty,
    { loading: addUserLoading, error: addUserError },
  ] = useMutation(SET_ALLOWED_USERS_TO_PROPERTY_MUTATION, {
    refetchQueries: [
      { query: GET_PROPERTIES_QUERY }, // DocumentNode object parsed with gql, refetch after mutation
    ],
  });

  const handleAllowedUsers = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue("allowedUsers", value);
  };

  const formik = useFormik({
    initialValues: {
      name: editableValues?.name || "",
      address: editableValues?.address || "",
      city: editableValues?.city || "",
      state_code: editableValues?.state_code || "",
      zip_code: editableValues?.zip_code || "",
      allowedUsers:
        (editableValues?.real_estate_properties_users &&
          editableValues.real_estate_properties_users.map((i) => i.user.id)) ||
        [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("validation.enter_property_name")),
      address: Yup.string().required(t("validation.enter_your_address")),
      city: Yup.string().required(t("validation.enter_your_city")),
      zip_code: Yup.string().required(t("validation.enter_your_zip_code")),
      state_code: Yup.string().required(t("validation.enter_your_state_code")),
    }),
    onSubmit: (values, { setErrors }) => {
      const { name, address, city, state_code, zip_code, allowedUsers } =
        values;
      if (editableValues.id) {
        const objects = map(allowedUsers, (id) => ({
          user_id: id,
          real_estate_property_id: editableValues.id,
        }));

        deleteAllUsersForProperty({
          variables: { real_estate_property_id: editableValues.id },
          onCompleted: () =>
            addSelectedUserToProperty({
              variables: { objects },
            }),
        });

        updateProperty({
          variables: {
            id: editableValues.id,
            address,
            city,
            name,
            state_code,
            zip_code,
          },
          onCompleted: () => handleCloseAndResetForm(),
        });
      } else {
        createProperty({
          variables: {
            address,
            city,
            name,
            state_code,
            zip_code,
          },
          update: (cache, { data: { insert_real_estate_properties_one } }) => {
            const data = cache.readQuery({
              query: GET_PROPERTIES_QUERY,
            });
            cache.writeQuery({
              query: GET_PROPERTIES_QUERY,
              data: {
                real_estate_properties: [
                  ...data.real_estate_properties,
                  insert_real_estate_properties_one,
                ],
              },
            });
          },
          onCompleted: () => handleCloseAndResetForm(),
        });
      }
      if (createError || updateError) {
        setErrors({ submit: "Something went wrong" });
      }
    },
  });

  const {
    errors,
    isValid,
    handleChange,
    values,
    resetForm,
    handleSubmit,
    setErrors,
    touched,
    setFieldValue,
  } = formik;

  const handleCloseAndResetForm = () => {
    setOpen(false);
    resetForm();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={() => handleCloseAndResetForm(false)}
    >
      <DialogTitle>
        <Title>{editableValues.id ? "Edit" : "Add New"} Property</Title>
      </DialogTitle>
      <DialogContent>
        {createError ||
          deleteError ||
          addUserError ||
          (updateError && (
            <Alert my={3} severity="warning" onClose={() => setErrors({})}>
              {errors.submit}
            </Alert>
          ))}

        <TextField
          name={"name"}
          value={values.name}
          onChange={handleChange}
          margin="dense"
          label="Property name"
          fullWidth
          variant="standard"
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
        <TextField
          name={"address"}
          value={values.address}
          onChange={handleChange}
          margin="dense"
          label="Address"
          fullWidth
          variant="standard"
          error={touched.address && Boolean(errors.address)}
          helperText={touched.address && errors.address}
        />
        <TextField
          name={"city"}
          value={values.city}
          onChange={handleChange}
          margin="dense"
          label="City"
          fullWidth
          variant="standard"
          error={touched.city && Boolean(errors.city)}
          helperText={touched.city && errors.city}
        />
        <TextField
          name={"state_code"}
          value={values.state_code}
          onChange={handleChange}
          margin="dense"
          label="State"
          fullWidth
          variant="standard"
          error={touched.state_code && Boolean(errors.state_code)}
          helperText={touched.state_code && errors.state_code}
        />
        <TextField
          name={"zip_code"}
          value={values.zip_code}
          onChange={handleChange}
          margin="dense"
          label="ZIP code"
          fullWidth
          variant="standard"
          error={touched.zip_code && Boolean(errors.zip_code)}
          helperText={touched.zip_code && errors.zip_code}
        />

        {editableValues.id && (
          <>
            <Title>Set Allowed Users</Title>
            <FormControl fullWidth sx={{ mt: 4, mb: 3 }}>
              <InputLabel id="allowed_user">Allowed Users</InputLabel>
              <Select
                labelId="allowed_user"
                multiple
                value={values.allowedUsers}
                onChange={(e) => handleAllowedUsers(e)}
                input={<OutlinedInput label="Allowed Users" />}
                renderValue={(selected) => {
                  return map(selected, (i) => {
                    const item = find(users?.users, { id: i });
                    return `${item.first_name} ${item.last_name}, `;
                  });
                }}
                MenuProps={MenuProps}
              >
                {!usersLoading &&
                  users?.users.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox
                        checked={values.allowedUsers.indexOf(item.id) > -1}
                      />
                      <ListItemText
                        primary={`${item.first_name} ${item.last_name}`}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseAndResetForm(false)}>
          {t("close")}
        </Button>
        <LoadingButton
          loading={
            createLoading || updateLoading || deleteLoading || addUserLoading
          }
          disabled={
            !isValid ||
            createLoading ||
            updateLoading ||
            deleteLoading ||
            addUserLoading
          }
          variant={"contained"}
          onClick={handleSubmit}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
