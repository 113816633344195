import React, { useState } from "react";
import styled from "styled-components/macro";
import { includes } from "lodash";
import {
  Typography as MuiTypography,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Menu,
  MenuItem,
  IconButton,
  Box as MuiBox,
  Fab as MuiFab,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  ExpandMore as ExpandMoreIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import RenameModal from "./RenameModal";
import WhiteCheckbox from "./WhiteCheckbox";

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
  & video {
    background-color: #000;
  }
`;

const ImageName = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px 0;
`;

const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardContent = styled(MuiCardContent)`
  padding: 4px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  &:last-child {
    padding-bottom: 4px;
  }
`;

const CardMedia = styled(MuiCardMedia)`
  height: 210px;
  overflow: hidden;
  position: relative;
  & > div:first-child {
    min-height: 210px;
    max-height: 100%;
  }
`;
const Checkbox = styled(WhiteCheckbox)`
  position: absolute;
  right: 0;
  top: 0;
`;
const Fab = styled(MuiFab)`
  transform: scale(0.6);
  position: absolute;
  left: 0;
  top: 0;
`;

const OriginalImageCard = ({
  id,
  image,
  video,
  imageName,
  handleCheckboxChange,
  checkedFiles,
  setImagePreviewModal,
  updateFileName,
  downloadFile,
  handleOriginalFileDeleteModal,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [renameModal, setRenameModal] = useState({
    open: false,
    name: imageName,
    id,
  });

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Card>
        {video ? (
          <CardMedia
            controls
            component="video"
            src={video}
            allowFullScreen="allowfullscreen"
          />
        ) : (
          <CardMedia image={`${image}?w=500&h=500`}>
            <Checkbox
              checked={includes(checkedFiles, id)}
              onChange={() => handleCheckboxChange(id)}
            />

            <Fab
              size="small"
              color="secondary"
              aria-label="zoom-out"
              onClick={() => setImagePreviewModal({ open: true, id })}
            >
              <ZoomOutMapIcon />
            </Fab>
          </CardMedia>
        )}
        <CardContent>
          <Box>
            <ImageName variant="h6" ml={2}>
              {imageName}
            </ImageName>
            {!video && (
              <>
                <IconButton
                  aria-owns={anchorEl ? "menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  variant="contained"
                  color="secondary"
                  size={"small"}
                >
                  <ExpandMoreIcon />
                </IconButton>
                <Menu
                  id="menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{ style: { width: 155 } }}
                >
                  <MenuItem
                    onClick={() => setImagePreviewModal({ open: true, id })}
                  >
                    {t("project.preview")}
                  </MenuItem>
                  <MenuItem onClick={() => downloadFile(id)}>
                    {t("project.download")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRenameModal({ open: true, name: imageName, id });
                      handleMenuClose();
                    }}
                  >
                    {t("project.rename")}
                  </MenuItem>
                  {handleOriginalFileDeleteModal && (
                    <MenuItem onClick={() => handleOriginalFileDeleteModal(id)}>
                      {t("project.delete")}
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
      <RenameModal
        renameModal={renameModal}
        setRenameModal={setRenameModal}
        updateName={updateFileName}
      />
    </React.Fragment>
  );
};

export default OriginalImageCard;
