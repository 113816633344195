import React from "react";
import { Routes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import * as Sentry from "@sentry/react";
import { initAmplitude } from "./utils/amplitude";
import "./i18n";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import { AuthProvider } from "./contexts/AuthContext";
import AppRoutes from "./routes";
import { Toaster } from "react-hot-toast";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const { theme } = useTheme();
  initAmplitude();
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <Sentry.ErrorBoundary>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Oda Studio" defaultTitle="Oda Studio" />
        <FlagsmithProvider
          options={{ environmentID: process.env.REACT_APP_FLAGSMITH_KEY }}
          flagsmith={flagsmith}
        >
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <ThemeProvider theme={createTheme(theme)}>
                    <AuthProvider>
                      <SentryRoutes>{AppRoutes}</SentryRoutes>
                      <Toaster position="top-right" />
                    </AuthProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </StylesProvider>
        </FlagsmithProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
