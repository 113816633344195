import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Card, Typography, CardContent, Link, Box } from "@mui/material";
import { NavLink } from "react-router-dom";

const MessageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
`;

const IllustrationImage = styled.img`
  width: 300px;
  height: 100%;
  top: ${(props) => props.theme.spacing(1)};
  bottom: ${(props) => props.theme.spacing(1)};
`;

const CreatedNewProjectMessage = ({ createdProjectId, setActiveStep }) => {
  const { t } = useTranslation();

  return (
    <Card mb={6} display="flex">
      <CardContent>
        <MessageContainer>
          <IllustrationImage src={"/static/img/coolness.svg"} />
          <Typography variant={"h4"} mt={6} mb={4}>
            {t("project.images_uploaded_successfully")}
          </Typography>
          <Typography variant={"h5"}>
            Go to{" "}
            <Link
              component={NavLink}
              to={`/dashboard/projects/${createdProjectId}`}
            >
              {t("project.my_project")}
            </Link>
          </Typography>
          <Typography variant={"h5"} mt={2}>
            Or{" "}
            <Link component={"button"} onClick={setActiveStep}>
              <Typography variant={"h5"}>
                {t("project.create_new_project")}
              </Typography>
            </Link>
          </Typography>
        </MessageContainer>
      </CardContent>
    </Card>
  );
};

export default CreatedNewProjectMessage;
