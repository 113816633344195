import React, { forwardRef } from "react";
import { IMaskInput } from "react-imask";

const ExpiryMaskInput = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00/00"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default ExpiryMaskInput;
