/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import {
  Box as MuiBox,
  Fab as MuiFab,
  Modal as MuiModal,
  Typography,
} from "@mui/material";
import { Close, NavigateNext, NavigateBefore } from "@mui/icons-material";

const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
`;

const Fab = styled(MuiFab)`
  transform: scale(0.7);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
`;

const NextButton = styled(MuiFab)`
  position: absolute;
  right: 14%;
  top: 46%;
  z-index: 2;

  ${(props) => props.theme.breakpoints.down("sm")} {
    right: 5px;
  }
`;
const PrevButton = styled(MuiFab)`
  position: absolute;
  left: 14%;
  top: 46%;
  z-index: 2;

  ${(props) => props.theme.breakpoints.down("sm")} {
    left: 5px;
  }
`;

const Box = styled(MuiBox)`
  max-width: 60%;
  margin: 0 auto;
  outline: none;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 85vh;
  }
`;

const ImageNameBox = styled(MuiBox)`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.paper};
  width: 100%;
  padding: 12px 16px;
  margin-top: -5px;
`;

export default function ImagePreviewModal({
  imagePreviewModal,
  imagePreviewModalFile,
  setImagePreviewModal,
  files,
}) {
  const [current, setCurrent] = useState();

  /*useEffect(() => {
    const handleKeyCode = (event) => {
      console.log(event.keyCode);
      switch (event.keyCode) {
        case 37:
          prev();
          break;
        case 39:
          next();
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyCode);
    return () => {
      window.removeEventListener("keydown", handleKeyCode);
    };
  }, []);*/

  useEffect(() => {
    setCurrent(files.findIndex((f) => f.hid === imagePreviewModal.id));
  }, [imagePreviewModal]);

  const next = () => {
    const { id } = imagePreviewModal;
    const index = files.findIndex((f) => f.hid === id);
    if (index < files.length - 1) {
      setImagePreviewModal({ open: true, id: files[index + 1].hid });
    }
  };

  const prev = () => {
    const { id } = imagePreviewModal;
    const index = files.findIndex((f) => f.hid === id);
    if (index > 0) {
      setImagePreviewModal({ open: true, id: files[index - 1].hid });
    }
  };

  return (
    <Modal
      open={imagePreviewModal.open}
      onClose={() => setImagePreviewModal({ open: false, id: null })}
    >
      <Box>
        <Fab
          size="small"
          onClick={() => setImagePreviewModal({ open: false, id: null })}
          color="primary"
          aria-label="handle close"
        >
          <Close />
        </Fab>
        <img
          alt={imagePreviewModalFile?.file_name}
          src={imagePreviewModalFile?.public_url}
        />

        <ImageNameBox>
          <Typography variant="h6">
            {imagePreviewModalFile?.file_name}
          </Typography>
        </ImageNameBox>

        <NextButton
          disabled={current === files.length - 1}
          size="small"
          onClick={next}
          color="primary"
          aria-label="handle close"
        >
          <NavigateNext />
        </NextButton>
        <PrevButton
          disabled={current === 0}
          size="small"
          onClick={prev}
          color="primary"
          aria-label="handle close"
        >
          <NavigateBefore />
        </PrevButton>
      </Box>
    </Modal>
  );
}
