import React from "react";
import styled from "styled-components/macro";
import { Popover as MuiPopover, Typography } from "@mui/material";

const Popover = styled(MuiPopover)`
  margin-left: 10px;
  & .MuiPopover-paper {
    background: transparent;
  }
`;

const Text = styled(Typography)`
  padding: 10px;
  color: gray;
  border-radius: 6px;
  border: #d7d7d7 1px solid;
  width: 223px;
  background-color: white;
`;

const Popup = ({ open, anchorEl, onClose, text }) => {
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={anchorEl}
      onClose={() => onClose(null)}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Text>{text}</Text>
    </Popover>
  );
};

export default Popup;
