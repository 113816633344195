import { get, size, includes, intersection } from "lodash";

import useAuth from "../hooks/useAuth";

function useAuthorization() {
  const { authorization } = useAuth();
  const userRoles = get(authorization, "role_names", []);
  const userPermissions = get(authorization, "permission_names", []);

  const hasRole = (role) => includes(userRoles, role);
  const hasAnyRole = (roles) => size(intersection(userRoles, roles)) > 0;
  const hasAllRole = (roles) =>
    size(intersection(userRoles, roles)) === size(roles);
  const hasPermission = (permission) => includes(userPermissions, permission);
  const hasAnyPermission = (permissions) =>
    size(intersection(userPermissions, permissions)) > 0;
  const hasAllPermission = (permissions) =>
    size(intersection(userPermissions, permissions)) === size(permissions);

  return {
    hasRole,
    hasAnyRole,
    hasAllRole,
    hasPermission,
    hasAnyPermission,
    hasAllPermission,
  };
}

export default useAuthorization;
