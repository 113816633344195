import React from 'react';

import {THEMES} from "../constants";

const initialState = THEMES.LIGHT;

const ThemeContext = React.createContext(initialState);

function ThemeProvider({children}) {
    const [theme, _setTheme] = React.useState(() => {
        let storedTheme = localStorage.getItem('theme');
        if (storedTheme) return storedTheme;

        localStorage.setItem('theme', initialState);

        return initialState;
    });

    const setTheme = (name) => {
        _setTheme(name);

        localStorage.setItem('theme', name);
    };

    return (
        <ThemeContext.Provider value={{theme, setTheme}}>
            {children}
        </ThemeContext.Provider>
    );
}

export {ThemeProvider, ThemeContext};
