import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { parallelLimit } from "async";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { map, sum, get, includes, uniq } from "lodash";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Link,
  Grid,
  Step,
  Stack,
  Button,
  Stepper,
  StepLabel,
  CardContent,
  Box as MuiBox,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
} from "@mui/material";
import { spacing } from "@mui/system";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import axios from "../utils/axios";
import { isNumber } from "chart.js/helpers";
import { sendAmplitudeData } from "../utils/amplitude";
import { CATEGORY_UNIT_TYPE, FILE_METADATA_ACTIONS, STEPS } from "../constants";

import useAuth from "../hooks/useAuth";
import UploadStep from "../components/UploadFlow/UploadStep";
import InfoStep from "../components/UploadFlow/InfoStep";
import SelectServiceStep from "../components/UploadFlow/SelectServiceStep";
import LoadingButton from "../components/LoadingButton";
import CreatedNewProjectMessage from "../components/CreatedNewProjectMessage";
import AddCreditCardMessage from "../components/AddCreditCardMessage";
import PricingSummary from "../components/PricingSummary";
import UploadingBarModal from "../components/UploadingBarModal";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)`
  ${spacing}
`;

const Box = styled(MuiBox)`
  ${spacing};
  margin-top: 0;
`;

const ColorlibConnector = styled(StepConnector)(({}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#E06763",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#E06763",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    borderRadius: 1,
    backgroundColor: "#eaeaf0",
  },
}));

const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
  zIndex: 1,
  color: "#fff",
  width: 45,
  height: 45,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#ccc",
  ...(ownerState.active && {
    backgroundColor: "#E06763",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#E06763",
  }),
}));

const HeaderButtonGroup = (props) => {
  const {
    formik,
    stagingStyle,
    isNextBtnClicked,
    setActiveStep,
    setIsNextBtnClicked,
  } = props;
  const { t } = useTranslation();
  const { values, isSubmitting, handleSubmit } = formik;

  const isButtonDisabled = () => {
    return (
      values.files.filter((f) => f.checkedStaging).length > 0 &&
      !stagingStyle &&
      !isSubmitting
    );
  };

  return (
    <>
      {isNextBtnClicked && (
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            disabled={isSubmitting}
            onClick={() => {
              setActiveStep(STEPS.INFO);
              !isSubmitting && setIsNextBtnClicked(false);
            }}
          >
            {t("back")}
          </Button>
          <LoadingButton
            id={"submit-btn"}
            disabled={isButtonDisabled()}
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={() => !isSubmitting && handleSubmit()}
          >
            {t("submit")}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsOutlinedIcon />,
    2: <DescriptionOutlinedIcon />,
    3: <CloudUploadOutlinedIcon />,
    4: <FlagOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

function NewProject() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const virtualStagingContentRef = useRef(null);
  const { UNIT, OTHER } = CATEGORY_UNIT_TYPE;
  const { getStatistics, statistics, userHasCard } = useAuth();
  const [createdProjectId, setCreatedProjectId] = useState(null);
  const [stagingStyle, setStagingStyle] = useState("");
  const [projectDetail, setProjectDetail] = useState({});
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [hideUnitInput, setHideUnitInput] = useState(true);
  const [activeStep, setActiveStep] = useState(STEPS.UPLOAD);
  const [uploadedFileSize, setUploadedFileSize] = useState(0);
  const [isNextBtnClicked, setIsNextBtnClicked] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [uploadingBarPercent, setUploadingBarPercent] = useState(0);
  const [showUploadingBar, setShowUploadingBar] = useState(false);

  //for price summary
  const [stagingCount, setStagingCount] = useState(0);
  const [premiumStagingCount, setPremiumStagingCount] = useState(0);
  const [enhancedCount, setEnhancedCount] = useState(0);
  const [premiumEnhancedCount, setPremiumEnhancedCount] = useState(0);

  const folderCount = get(statistics, "attributes.folder_count");
  const steps = [
    t("upload"),
    t("name_project"),
    t("select_services"),
    t("finish"),
  ];
  const showCreateForm = folderCount === 0 || (folderCount > 0 && userHasCard);
  const showAddCreditCardMessage =
    isNumber(folderCount) && folderCount > 0 && !userHasCard;

  const getProjectDetail = (id) => {
    if (!id) return;
    axios.get(`/folders/${id}`).then(({ data }) => {
      setProjectDetail(data.data);
    });
  };

  const formik = useFormik({
    initialValues: {
      projectName: projectDetail.attributes?.title || "",
      category: "",
      unitName: "",
      files: [],
      propertyId: null,
      propertyName: "",
    },
    validationSchema: Yup.object().shape({
      files: Yup.mixed().required(),
      category:
        !id && Yup.string().required(t("validation.select_category_style")),
      unitName:
        !id &&
        Yup.string().when("category", {
          is: (category) => includes([UNIT, OTHER], category),
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
      propertyName: !id && Yup.string().required(),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      setShowUploadingBar(true);
      try {
        setTotalFileSize(sum(map(values.files, (f) => f.size)));

        const fileNamesAndActions = map(values.files, (f) => {
          if (f.checkedStaging) {
            f.actions = [
              FILE_METADATA_ACTIONS.STAGING,
              FILE_METADATA_ACTIONS.ENHANCEMENT,
            ];
          }
          if (f.checkedEnhancement) {
            f.actions = uniq([
              ...(f.actions || []),
              FILE_METADATA_ACTIONS.ENHANCEMENT,
            ]);
          }
          let data = {
            category: values?.category,
            keywords: f?.keywords || [],
            file_name: f?.name,
            actions: f?.actions || [],
            size: f?.size,
            room_type: f?.room_type || "",
            category_value: values?.unitName,
            detected_room_type: f?.detected_room_type,
            image_width: f?.image_width,
            image_height: f?.image_height,
            real_estate_property_id: values?.propertyId,
          };
          if (includes(data.actions, "staging")) {
            data.has_premium_staging = f?.has_premium_staging || false;
            data.has_premium_enhancement = f?.has_premium_enhancement || false;
            data.staging_style = stagingStyle || "";
          }
          if (includes(data.actions, "enhancement")) {
            data.has_premium_enhancement = f?.has_premium_enhancement || false;
          }
          return data;
        });
        let uploadUrls = null;
        if (!id) {
          const createdProject = await axios.post(`/folders`, {
            title: `${values.propertyName}`,
          });
          const { id: createdProjectId } = createdProject.data.data;
          setCreatedProjectId(createdProjectId);
          uploadUrls = await axios.put(
            `/folders/${createdProjectId}/bulk-upload`,
            {
              files: fileNamesAndActions,
            }
          );
        } else {
          uploadUrls = await axios.put(`/folders/${id}/bulk-upload`, {
            files: fileNamesAndActions,
          });
        }

        const uploadFileIds = map(uploadUrls?.data, "data.id");

        const parallelStack = uploadUrls?.data.map((file, i) => (cb) => {
          const sourceFile = values.files[i];
          const headers = { "Content-Type": sourceFile.type };
          Axios.put(`${get(file, "upload_url")}`, sourceFile, { headers })
            .then((response) => {
              setUploadedFileSize((prevState) => prevState + sourceFile.size);
              return cb(null, response);
            })
            .catch((err) => {
              return cb(err);
            });
        });

        await new Promise((resolve, reject) => {
          parallelLimit(parallelStack, 10, async (err, results) => {
            if (err) {
              reject(err);
            } else {
              resolve(results);
            }
          });
        });
        await axios.post(`/webhooks/approve-file-upload`, {
          source: "oda-web",
          ids: uploadFileIds,
        });

        sendAmplitudeData("project_creation_requested", {
          project_name: `${values.propertyName}`,
          project_id: id || createdProjectId,
          image_count: values.files.length,
        });

        fileNamesAndActions.forEach((f) => {
          sendAmplitudeData("image_operation_requested", {
            image: f,
            project_name: `${values.propertyName}`,
            project_id: id || createdProjectId,
          });
        });
        resetState();
        getStatistics();
        resetForm({});
      } catch (error) {
        const message =
          error?.response?.data?.message || "Something went wrong";
        setErrors({ submit: message });
      } finally {
        setShowUploadingBar(false);
        setActiveStep(STEPS.FINISH);
        setSubmitting(false);
        if (id) {
          navigate(`/dashboard/projects/${id}`, {
            state: { message: t("project.successfully_added") },
          });
        }
      }
    },
  });

  const { values, handleSubmit, setFieldValue } = formik;

  const checkRoomTypes = React.useCallback(() => {
    const loadedRoomTypes = values.files.filter(
      (file) => file.room_type !== undefined
    );
    if (
      (values.files.length > 0 && values.files.length) ===
      loadedRoomTypes.length
    ) {
      setDisableNextButton(false);
    }
  }, [values]);

  useEffect(() => {
    getProjectDetail(id);
  }, [id]);

  useEffect(() => {
    if (uploadedFileSize > 0) {
      setUploadingBarPercent(
        Math.ceil(
          ((uploadedFileSize / (1024 * 1024)) * 100) /
            (totalFileSize / (1024 * 1024))
        )
      );
    }
  }, [uploadedFileSize, totalFileSize]);

  useEffect(() => {
    setDisableNextButton(true);
    checkRoomTypes();
  }, [values.files.length, checkRoomTypes]);

  useEffect(() => {
    let staging_count = 0;
    let premium_staging_count = 0;
    let premium_enhancement_count = 0;
    let enhancement_count = 0;

    // enhancement premium is a separate $4 charge, but staging includes enhancement
    values.files.map((f) => {
      if (f.checkedStaging && f.checkedEnhancement) {
        if (f.has_premium_staging && !f.has_premium_enhancement)
          premium_staging_count++;
        else if (f.has_premium_staging && f.has_premium_enhancement) {
          premium_staging_count++;
          premium_enhancement_count++;
        } else if (!f.has_premium_staging && f.has_premium_enhancement) {
          premium_enhancement_count++;
          staging_count++;
        } else staging_count++;
      } else if (!f.checkedStaging && f.checkedEnhancement) {
        if (f.has_premium_enhancement) premium_enhancement_count++;
        else enhancement_count++;
      }
    });

    setEnhancedCount(enhancement_count);
    setPremiumEnhancedCount(premium_enhancement_count);
    setStagingCount(staging_count);
    setPremiumStagingCount(premium_staging_count);
  }, [values.files]);

  useEffect(() => {
    if (values.category) {
      if (includes(["Unit", "Other"], values.category)) {
        setHideUnitInput(false);
      } else {
        setHideUnitInput(true);
        setFieldValue("unitName", "");
      }
    }
  }, [values.category]);

  function resetState() {
    setUploadingBarPercent(0);
    setUploadedFileSize(0);
    setTotalFileSize(0);
    navigate(location.pathname, { replace: true });
  }

  const handleStagingStyle = (e) => {
    const { value } = e.target;
    setStagingStyle(value);
  };

  const onKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <Helmet
        title={id ? t("project.add_new_photos") : t("new_project.title")}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h3" gutterBottom display="inline">
              {id ? t("project.add_new_photos") : t("new_project.title")}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Typography> {t("dashboard")}</Typography>
              {!id ? (
                <Typography>{t("new_project.title")}</Typography>
              ) : (
                <Breadcrumbs>
                  <Link component={NavLink} to="/dashboard/projects">
                    {t("projects")}
                  </Link>
                  <Typography>{projectDetail.attributes?.title}</Typography>
                </Breadcrumbs>
              )}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={8}>
            <Stack
              sx={{ width: "100%", alignItems: "flex-end", marginLeft: "60px" }}
            >
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{ width: "200px" }}
                      StepIconComponent={ColorlibStepIcon}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider my={3} />
      {activeStep === STEPS.SELECT_SERVICES && (
        <Grid
          container
          gap={2}
          mb={3}
          alignItems={"center"}
          flexWrap={"nowrap"}
        >
          <Grid item>
            <PricingSummary
              enhancedCount={enhancedCount}
              stagingCount={stagingCount}
              premiumStagingCount={premiumStagingCount}
              premiumEnhancedCount={premiumEnhancedCount}
            />
          </Grid>
          <Grid item marginLeft={"auto"}>
            <HeaderButtonGroup
              formik={formik}
              setActiveStep={setActiveStep}
              stagingStyle={stagingStyle}
              isNextBtnClicked={isNextBtnClicked}
              setIsNextBtnClicked={setIsNextBtnClicked}
            />
          </Grid>
        </Grid>
      )}

      {showCreateForm && (
        <form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>
          <>
            {activeStep === STEPS.UPLOAD && (
              <UploadStep
                formik={formik}
                location={location}
                setActiveStep={setActiveStep}
              />
            )}

            {activeStep === STEPS.INFO && (
              <InfoStep
                id={id}
                projectDetail={projectDetail}
                formik={formik}
                hideUnitInput={hideUnitInput}
                disableNextButton={disableNextButton}
                setActiveStep={setActiveStep}
                setIsNextBtnClicked={setIsNextBtnClicked}
              />
            )}

            {activeStep === STEPS.SELECT_SERVICES && (
              <SelectServiceStep
                formik={formik}
                folderId={createdProjectId}
                projectTitle={projectDetail.attributes?.title}
                stagingStyle={stagingStyle}
                totalFileSize={totalFileSize}
                isNextBtnClicked={isNextBtnClicked}
                uploadedFileSize={uploadedFileSize}
                uploadingBarPercent={uploadingBarPercent}
                virtualStagingContentRef={virtualStagingContentRef}
                setFieldValue={setFieldValue}
                setStagingStyle={setStagingStyle}
                handleStagingStyle={handleStagingStyle}
              />
            )}
            {activeStep === STEPS.FINISH && (
              <Card mb={6}>
                <CardContent>
                  <CreatedNewProjectMessage
                    createdProjectId={createdProjectId}
                    setActiveStep={() => setActiveStep(STEPS.UPLOAD)}
                  />
                </CardContent>
              </Card>
            )}
          </>
        </form>
      )}
      {showAddCreditCardMessage && <AddCreditCardMessage />}
      <UploadingBarModal
        hasUploadingBar={true}
        uploadingBarPercent={uploadingBarPercent}
        totalFileSize={totalFileSize}
        uploadedFileSize={uploadedFileSize}
        open={showUploadingBar}
      />
    </React.Fragment>
  );
}

export default NewProject;
