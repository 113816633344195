import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Alert as MuiAlert,
} from "@mui/material";
import styled from "styled-components/macro";
import { PRICING } from "../constants";

const Title = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  color: #e06763;
`;
const Price = styled(Typography)`
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
`;

const Alert = styled(MuiAlert)`
  font-size: 10px;
  font-weight: 500;
  padding: 0 6px;
  text-align: left;
`;

const PricingSummary = ({
  enhancedCount,
  stagingCount,
  premiumStagingCount,
  premiumEnhancedCount,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    setTotalPrice(
      enhancedCount * PRICING.ENHANCEMENT +
        premiumEnhancedCount * PRICING.PREMIUM_ENHANCEMENT +
        stagingCount * PRICING.STAGING +
        premiumStagingCount * PRICING.PREMIUM_STAGING
    );
  }, [enhancedCount, stagingCount, premiumStagingCount, premiumEnhancedCount]);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <Title>PAYMENT SUMMARY</Title>
            <Grid container mt={6} mb={4}>
              <Grid item xs={6}>
                <Typography>
                  {stagingCount} of Virtual Staging: $
                  {stagingCount * PRICING.STAGING}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {premiumStagingCount} of Premium Staging: $
                  {premiumStagingCount * PRICING.PREMIUM_STAGING}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Typography>
                  {enhancedCount} of Enhancement: $
                  {enhancedCount * PRICING.ENHANCEMENT}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {premiumEnhancedCount} of Premium Enhancement: $
                  {premiumEnhancedCount * PRICING.PREMIUM_ENHANCEMENT}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} textAlign={"right"}>
            <Title>TOTAL</Title>
            <Price>${totalPrice}</Price>
            <Alert icon={false} severity="success">
              Your first project is FREE (up to 2 photos for staging and 20
              photos for enhancement)
            </Alert>
          </Grid>
        </Grid>
        <Grid item mt={2}>
          <Alert icon={false} severity="warning">
            You won’t be charged now. Payments are processed at the end of the
            month.
          </Alert>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PricingSummary;
