import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { compact } from "lodash";
import styled from "styled-components/macro";

import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Alert as MuiAlert,
} from "@mui/material";
import { spacing } from "@mui/system";

import axios from "../utils/axios";

const Alert = styled(MuiAlert)(spacing);

export default function ShareLinkModal({
  open,
  checkedFiles,
  projectTitle,
  setShareLinkModal,
}) {
  const { t } = useTranslation();
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [copied, setCopied] = useState(false);

  const handleOnChange = (event) => setTitle(event.target.value);

  const generateLink = () => {
    axios
      .post(`/sharing-links/generate-from-files`, {
        title,
        ids: compact(checkedFiles),
        access_type: "public",
      })
      .then(({ data }) => {
        const url = new URL(window.location);
        url.pathname = `/sl/${data.hid}`;
        setLink(url.toString());
      });
  };

  const closeModal = () => {
    setLink("");
    setShareLinkModal(false);
    setCopied(false);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(link);

    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  useEffect(() => setTitle(projectTitle), [projectTitle, open]);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={"xs"}
      onClose={() => setShareLinkModal(false)}
    >
      <DialogTitle>Share your photos</DialogTitle>
      {link ? (
        <>
          <DialogContent>
            {!copied && (
              <Alert severity="success" my={2}>
                Your url is ready!
              </Alert>
            )}
            {copied && (
              <Alert severity="success" my={2}>
                Successfully copied to clipboard!
              </Alert>
            )}
            <TextField
              value={link}
              margin="dense"
              id="name"
              fullWidth
              disabled
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeModal()}>{t("close")}</Button>
            <Button onClick={() => copyToClipboard()}>Copy Link</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <TextField
              value={title}
              onChange={handleOnChange}
              autoFocus
              margin="dense"
              id="name"
              label="Project Name"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeModal()}>{t("close")}</Button>
            <Button onClick={() => generateLink()}>Generate Link</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
