import { gql } from "@apollo/client";

export const GET_AUTHENTICATED_USER_PERMISSIONS = gql`
  query getAuthenticatedUserPermissions {
    roles {
      id
      name
      roles_permissions {
        permission {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($user_id: bigint = "", $role_id: Int = 5) {
    update_roles_users(
      where: { user_id: { _eq: $user_id } }
      _set: { role_id: $role_id }
    ) {
      affected_rows
      returning {
        user_id
        role_id
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers {
    users {
      id
      email
      first_name
      created_at
      last_name
      status
      roles_users {
        role_id
      }
    }
  }
`;
