import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import { CssBaseline, Grid } from "@mui/material";
import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  background-image: linear-gradient(
      rgba(229, 106, 102, 0.7),
      rgba(229, 106, 102, 0.7)
    ),
    url(${"/static/img/auth-background.jpg"});
  background-color: #e56a66;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  display: flex;
  padding: 12px;
`;

const Auth = () => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Outlet />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img
            src={"/static/img/properties.svg"}
            alt={"properties"}
            style={{ padding: 12 }}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export default Auth;
