import React, { useState } from "react";
import { get, compact } from "lodash";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Link,
  Button,
  Typography,
  Breadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../utils/axios";
import styled from "styled-components/macro";
import LoadingButton from "../components/LoadingButton";
import SelectServiceStep from "../components/UploadFlow/SelectServiceStep";

const Divider = styled(MuiDivider)(spacing);

function OriginalToEnhancementAndStaging() {
  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [stagingStyle, setStagingStyle] = useState("");

  const handleStagingStyle = (e) => {
    const { value } = e.target;
    setStagingStyle(value);
  };

  const onKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  const formik = useFormik({
    initialValues: {
      files: get(location, "state.originalFiles"),
    },
    validationSchema: Yup.object().shape({
      files: Yup.mixed().required(),
    }),
    onSubmit: (values, { setErrors, setSubmitting }) => {
      try {
        const payload = values.files.map((f) => {
          let data = {};
          let flow = [];
          if (f.checkedEnhancement && !f.has_enhanced_file)
            flow.push("enhancement");
          if (f.checkedStaging) {
            flow.push("staging");
            data.staging_style = stagingStyle;
          }
          data.id = get(f, "hid");
          data.keywords = get(f, "keywords");
          data.room_type = get(f, "room_type");
          data.flow = flow;
          if (!!data.flow.length) return data;
        });
        if (compact(payload).length > 0) {
          //means didnt select services
          axios
            .post("/files/send-to-flow", { files: compact(payload) })
            .then(() => {
              if (id) {
                navigate(`/dashboard/projects/${id}`, {
                  state: {
                    tabValue: "original",
                    message: t("project.success_your_photos_are_sent_to_flow"),
                  },
                });
              }
            });
        } else {
          setErrors({
            submit:
              "Photos enhanced previously. Please select Virtual Staging as a service.",
          });
        }
      } catch (error) {
        setErrors({
          submit: get(error, "response.data.message") || "Something went wrong",
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, isSubmitting, setFieldValue, handleSubmit } = formik;

  return (
    <React.Fragment>
      <Helmet title={t("project_detail.title")} />
      <Typography variant="h3" gutterBottom display="inline">
        {get(location, "state.projectTitle")}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography> {t("dashboard")}</Typography>
        <Typography>{t("projects")}</Typography>
        <Link component={NavLink} to={`/dashboard/projects/${id}`}>
          {get(location, "state.projectTitle")}
        </Link>
      </Breadcrumbs>
      <Divider my={6} />
      <Box
        display="flex"
        my={5}
        columnGap="10px"
        justifyContent={{ lg: "end", sm: "end", xs: "center" }}
      >
        <Button
          variant="outlined"
          onClick={() => navigate(`/dashboard/projects/${id}`)}
        >
          {t("back")}
        </Button>
        <LoadingButton
          disabled={
            values?.files.filter((f) => f.checked).length > 0 && !stagingStyle
          }
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          {t("submit")}
        </LoadingButton>
      </Box>
      <form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>
        <SelectServiceStep
          formik={formik}
          folderId={id}
          projectTitle={get(location, "state.projectTitle")}
          stagingStyle={stagingStyle}
          isNextBtnClicked={true}
          hasUploadingBar={false}
          setFieldValue={setFieldValue}
          setStagingStyle={setStagingStyle}
          handleStagingStyle={handleStagingStyle}
        />
      </form>
    </React.Fragment>
  );
}

export default OriginalToEnhancementAndStaging;
