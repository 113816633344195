import { get, orderBy } from "lodash";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useFlags } from "flagsmith/react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { TabPanel, TabList, TabContext } from "@mui/lab";

import {
  Box,
  Tab,
  Grid,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
} from "@mui/material";

import {
  Hits,
  ScrollTo,
  Configure,
  SearchBox,
  Pagination,
  InstantSearch,
  RefinementList,
  HierarchicalMenu,
} from "react-instantsearch-dom";
import { searchClient } from "../utils/typesense-adapter";

import "instantsearch.css/themes/satellite.css";
import "../components/library/library-styles.css";

import useAuth from "../hooks/useAuth";
import LibraryUpload from "../components/library/LibraryUpload";
import OriginalImageCard from "../components/OriginalImageCard";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Library() {
  const navigate = useNavigate();
  const { organization } = useAuth();
  const flags = useFlags(["show_library_menu_item"]);

  const [selectedTab, setSelectedTab] = useState("1");

  useEffect(() => {
    if (!flags.show_library_menu_item.enabled) {
      return navigate("/dashboard/new-project");
    }
  }, [flags, navigate]);

  const refinementListTransformer = (items) => {
    return orderBy(items, ["label", "count"], ["asc", "desc"]);
  };

  return (
    <React.Fragment>
      <Helmet title={"Library"} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {"Library"}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{"Dashboard"}</Typography>
            <Typography>{"Library"}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <TabContext value={selectedTab}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TabList
            indicatorColor="primary"
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            aria-label="Accounts Tabs"
          >
            <Tab label={"Library"} value="1" />
            <Tab label={"Upload"} value="2" />
          </TabList>
        </Box>
        <TabPanel value={"1"}>
          <InstantSearch indexName="photos-staging" searchClient={searchClient}>
            <Configure hitsPerPage={15} />

            <ScrollTo>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <SearchBox />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <h4>Refine Search</h4>
                  <HierarchicalMenu
                    attributes={["refine.lvl0", "refine.lvl1", "refine.lvl2"]}
                    transformItems={refinementListTransformer}
                  />
                  {/*<h4>State</h4>*/}
                  {/*<RefinementList*/}
                  {/*  facetOrder*/}
                  {/*  attribute="property_state_code"*/}
                  {/*  transformItems={refinementListTransformer}*/}
                  {/*/>*/}
                  {/*<h4>City</h4>*/}
                  {/*<RefinementList*/}
                  {/*  attribute="property_city"*/}
                  {/*  transformItems={refinementListTransformer}*/}
                  {/*/>*/}
                  {/*<h4>Property</h4>*/}
                  {/*<RefinementList*/}
                  {/*  searchable={true}*/}
                  {/*  attribute="property_name"*/}
                  {/*  transformItems={refinementListTransformer}*/}
                  {/*/>*/}
                  <h4>Room Type</h4>
                  <RefinementList
                    searchable={true}
                    attribute="room_type"
                    transformItems={refinementListTransformer}
                  />
                  <h4>Category</h4>
                  <RefinementList
                    attribute="category"
                    transformItems={refinementListTransformer}
                  />
                  <h4>Keywords</h4>
                  <RefinementList
                    attribute="keywords"
                    transformItems={refinementListTransformer}
                  />
                </Grid>
                <Grid item xs={12} lg={10}>
                  <Hits hitComponent={Photo} className={"library-items"} />
                  <Pagination />
                </Grid>
              </Grid>
            </ScrollTo>
          </InstantSearch>
        </TabPanel>
        <TabPanel value={"2"}>
          <LibraryUpload organization={organization} />
        </TabPanel>
      </TabContext>
    </React.Fragment>
  );
}

function Photo({ hit }) {
  return (
    <Grid key={get(hit, "objectID")} item xs={12}>
      <OriginalImageCard
        id={get(hit, "id")}
        image={get(hit, "image_url")}
        imageName={get(hit, "title")}
        downloadFile={(...args) => console.log("Library.js:71", args)}
      />
    </Grid>
  );
}

export default Library;
