import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function RenameModal({
  renameModal,
  setRenameModal,
  updateName,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(renameModal.name);
  }, [renameModal.name]);

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={renameModal.open}
      onClose={() => setRenameModal({ open: false, name: "" })}
    >
      <DialogTitle>Rename</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter your new name</DialogContentText>
        <TextField
          value={value}
          onChange={handleOnChange}
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setRenameModal({ open: false, name: "" });
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            updateName(value, renameModal.id);
            setRenameModal({ open: false, name: "" });
          }}
        >
          {t("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
