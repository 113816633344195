class ImageResizer {
  static changeHeightWidth(height, maxHeight, width, maxWidth) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
    return { height, width };
  }

  static resizeImage(
    image,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    compressFormat = "jpeg",
    quality = 100
  ) {
    let qualityDecimal = quality / 100;
    let canvas = document.createElement("canvas");

    let width = image.width;
    let height = image.height;

    let newHeightWidth = this.changeHeightWidth(
      height,
      maxHeight,
      width,
      maxWidth
    );

    canvas.width = newHeightWidth.width;
    canvas.height = newHeightWidth.height;

    width = newHeightWidth.width;
    height = newHeightWidth.height;

    let ctx = canvas.getContext("2d");
    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.fillRect(0, 0, width, height);

    ctx.drawImage(image, 0, 0, width, height);
    return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal);
  }

  static b64toByteArrays(b64Data) {
    let sliceSize = 512;

    let byteCharacters = atob(
      b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, "")
    );
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return byteArrays;
  }

  static b64toFile(b64Data, fileName, firstWidth, firstHeight, contentType) {
    const byteArrays = this.b64toByteArrays(b64Data, contentType);

    // noinspection JSCheckFunctionSignatures
    let file = new File(byteArrays, fileName, {
      type: contentType,
      lastModified: new Date(),
    });
    file.firstWidth = firstWidth;
    file.firstHeight = firstHeight;
    return file;
  }

  static createResizedImage(
    file,
    maxWidth,
    maxHeight,
    compressFormat,
    quality
  ) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          let image = new Image();
          // noinspection JSValidateTypes
          image.src = reader.result;
          image.onload = function () {
            let resizedDataUrl = ImageResizer.resizeImage(
              image,
              maxWidth,
              maxHeight,
              compressFormat,
              quality
            );
            const contentType = `image/${compressFormat}`;
            let fileName = file.name;
            let firstWidth = image.width;
            let firstHeight = image.height;
            let fileNameWithoutFormat = fileName
              .toString()
              .replace(/(png|jpeg|jpg|webp)$/i, "");
            let newFileName = fileNameWithoutFormat.concat(
              compressFormat.toString()
            );
            const newFile = ImageResizer.b64toFile(
              resizedDataUrl,
              newFileName,
              firstWidth,
              firstHeight,
              contentType
            );

            return resolve(newFile);
          };
        };

        reader.onerror = (error) => reject(error);
      }
    });
  }
}

export default ImageResizer;
