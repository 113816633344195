/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Grid,
  Alert as MuiAlert,
  TextField as MuiTextField,
  Button as MuiButton,
  Box as MuiBox,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";

const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const Alert = styled(MuiAlert)(spacing);

const Box = styled(MuiBox)`
  ${spacing};
  text-align: right;
`;

function MyAccount() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      submit: false,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .max(255)
        .required(t("validation.enter_your_first_name")),
      last_name: Yup.string()
        .max(255)
        .required(t("validation.enter_your_last_name")),
      email: Yup.string()
        .email(t("validation.invalid_email"))
        .max(255)
        .required(t("validation.enter_your_email")),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const { first_name, last_name, email } = values;
        await axios
          .patch(`/users/${user.id}`, {
            first_name,
            last_name,
            email,
          })
          .then(() => {
            setStatus({ sent: true });
          });
      } catch (error) {
        const message = error.message || "Something went wrong";
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setValues,
    status,
    setStatus,
    setErrors,
  } = formik;

  useEffect(() => {
    axios.get(`/users/${user.id}`).then(({ data }) => {
      const { first_name, last_name, email } = data.data.attributes;
      setValues({
        ...values,
        first_name,
        last_name,
        email,
      });
    });
  }, [user]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      {status && status.sent && (
        <Alert
          severity="success"
          my={3}
          onClose={() => setStatus({ sent: false })}
        >
          Your data has been submitted successfully!
        </Alert>
      )}

      {errors.submit && (
        <Alert my={3} severity="warning" onClose={() => setErrors({})}>
          {errors.submit}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            type="text"
            id="first-name"
            name="first_name"
            value={values.first_name || ""}
            label={t("auth.first_name")}
            variant="outlined"
            error={Boolean(touched.first_name && errors.first_name)}
            helperText={touched.first_name && errors.first_name}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            my={2}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="last_name"
            id="last-name"
            label={t("auth.last_name")}
            variant="outlined"
            value={values.last_name || ""}
            error={Boolean(touched.last_name && errors.last_name)}
            helperText={touched.last_name && errors.last_name}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            my={2}
          />
        </Grid>
      </Grid>

      <TextField
        disabled
        type="email"
        name="email"
        id="email"
        label={t("auth.email")}
        value={values.email || ""}
        error={Boolean(touched.email && errors.email)}
        helperText={touched.email && errors.email}
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        my={2}
      />
      <Box>
        <Button
          type="submit"
          variant="contained"
          disabled={isSubmitting}
          color="primary"
          mt={3}
        >
          {t("save_changes")}
        </Button>
      </Box>
    </form>
  );
}

export default MyAccount;
