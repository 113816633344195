import { gql } from "@apollo/client";

export const GET_PROPERTIES_QUERY = gql`
  query getProperties {
    real_estate_properties {
      address
      city
      state_code
      zip_code
      id
      name
      organization_id
      created_by {
        first_name
        last_name
      }
      real_estate_properties_users {
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const CREATE_PROPERTY_MUTATION = gql`
  mutation createProperty(
    $address: String
    $city: String
    $name: String
    $state_code: String
    $zip_code: String
  ) {
    insert_real_estate_properties_one(
      object: {
        address: $address
        city: $city
        name: $name
        state_code: $state_code
        zip_code: $zip_code
      }
    ) {
      id
      address
      city
      state_code
      zip_code
      name
      organization_id
      created_by {
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_PROPERTY_MUTATION = gql`
  mutation updateProperty(
    $id: bigint = ""
    $address: String
    $city: String
    $name: String
    $state_code: String
    $zip_code: String
  ) {
    update_real_estate_properties_by_pk(
      pk_columns: { id: $id }
      _set: {
        address: $address
        city: $city
        name: $name
        state_code: $state_code
        zip_code: $zip_code
      }
    ) {
      id
      address
      city
      state_code
      zip_code
      name
      organization_id
      created_by {
        first_name
        last_name
      }
    }
  }
`;

export const GET_USER_LIST_FOR_PROPERTY_QUERY = gql`
  query getUserList {
    users {
      id
      first_name
      last_name
    }
  }
`;

export const DELETE_ALL_USERS_FOR_PROPERTY_MUTATION = gql`
  mutation deleteAllUsers($real_estate_property_id: bigint) {
    delete_core_real_estate_properties_users(
      where: { real_estate_property_id: { _eq: $real_estate_property_id } }
    ) {
      affected_rows
    }
  }
`;

export const SET_ALLOWED_USERS_TO_PROPERTY_MUTATION = gql`
  mutation addUsersToProperty(
    $objects: [core_real_estate_properties_users_insert_input!] = {}
  ) {
    insert_core_real_estate_properties_users(objects: $objects) {
      affected_rows
      returning {
        users {
          id
          first_name
          last_name
        }
      }
    }
  }
`;
