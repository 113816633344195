import React, { useState } from "react";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { PickerInline } from "filestack-react";
import { parallelLimit } from "async";

import { Alert as MuiAlert } from "@mui/material";
import ImageResizer from "../utils/ImageResizer";

import uploadFilesToImageApi from "../utils/uploadFilesToImageApi";
import { STEPS } from "../constants";

const Alert = styled(MuiAlert)(spacing);

/**
 * Retrieve a `File` object for a file that is available at a specific URL.
 *
 * This function is needed to retrieve files from third-party services (Google Drive,
 * Dropbox, etc.) so they can be then uploaded to our existing backend infrastructure.
 *
 * TODO: Replace with direct S3 upload: https://www.filestack.com/docs/uploads/storage/
 *
 * @param {string} url - the URL where the file can be downloaded
 * @param {string} name - the name of the file
 * @param {string} defaultType - the MIME type of the file
 * @returns {File} - the File object retrieved from the URL
 */
async function getFileFromUrl(url, name, defaultType = "image/jpeg") {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

const resizeFile = async (file) =>
  await ImageResizer.createResizedImage(file, 400, 400, "JPEG", 100, 0, "file");

/**
 * Render a Filestack file uploader when provided an instance of a formik form.
 *
 * @param {Object} props - the React props - must contain a `formik` value
 * @returns {div} - a `<div />` containing a Filestack file upload widget
 */
const UploadInput = React.memo(
  ({ formik, setActiveStep }) => {
    const [errors, setErrors] = useState(null);
    // get the existing files that are already uploaded in the formik form instance
    const { values, setFieldValue } = formik;
    const files = values.files;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          width: "100%",
          height: "fit-content",
          zIndex: "89",
          position: "relative",
        }}
      >
        <PickerInline
          apikey="AWVxLgl4tQ8Sw2gMpArFRz"
          key="filestack-picker"
          pickerOptions={{
            exposeOriginalFile: true,
            maxFiles: 1000,
            viewType: "grid",
            uploadInBackground: true,
            fromSources: [
              "local_file_system",
              "googledrive",
              "googlephotos",
              "dropbox",
              "box",
              "onedrive",
              "facebook",
            ],
            accept: [".png", ".JPG", ".jpeg"],
          }}
          onUploadDone={async (fileResponse) => {
            // for file stored in third-party cloud storage, retrieve a `File` object
            for (var f of fileResponse.filesUploaded) {
              f.originalFile = await getFileFromUrl(f.url, f.filename);
            }
            // map an array of `File` objects that were just uploaded
            const newUploads = fileResponse.filesUploaded.map(
              (f) => f.originalFile
            );

            // do not proceed unless there are new uploads to process
            if (!newUploads.length) {
              return;
            }

            const parallelStack = newUploads.map((image) => (cb) => {
              resizeFile(image)
                .then((resizedImage) => {
                  return cb(null, resizedImage);
                })
                .catch((err) => {
                  return cb(err);
                });
            });
            await new Promise((resolve, reject) => {
              parallelLimit(parallelStack, 5, async (err, resizedImage) => {
                if (err) {
                  reject(err);
                } else {
                  // update the form state
                  const newFiles = [
                    ...files,
                    ...newUploads.map((file, i) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        smallSizePreview: URL.createObjectURL(resizedImage[i]),
                        image_width: resizedImage[i].firstWidth,
                        image_height: resizedImage[i].firstHeight,
                      })
                    ),
                  ];

                  setFieldValue("files", newFiles);
                  // upload new files and provide old files for deduplication
                  const existingAndNewFiles = () => {
                    const newFiles = [...files, ...newUploads];
                    setFieldValue("files", newFiles);
                  };
                  await uploadFilesToImageApi(newUploads, existingAndNewFiles);
                }
                setActiveStep(STEPS.INFO);
                setErrors(null);
              });
            });
          }}
        />

        {errors && (
          <Alert my={2} severity="warning">
            {errors}
          </Alert>
        )}
      </div>
    );
  },
  () => true
);

export default UploadInput;
