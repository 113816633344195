/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Alert as MuiAlert,
  TextField as MuiTextField,
  Button as MuiButton,
  Box as MuiBox,
} from "@mui/material";

import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";

const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const Alert = styled(MuiAlert)(spacing);

const Box = styled(MuiBox)`
  ${spacing};
  text-align: right;
`;

function Organization() {
  const { t } = useTranslation();
  const { organization } = useAuth();

  const formik = useFormik({
    initialValues: {
      name: "",
      submit: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(255)
        .required(t("validation.enter_your_organization_name")),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const { name } = values;
        await axios
          .patch(`/organizations/${organization.id}`, { name })
          .then(() => {
            setStatus({ sent: true });
          });
      } catch (error) {
        const message = error.message || "Something went wrong";
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setValues,
    status,
    setStatus,
    setErrors,
  } = formik;

  useEffect(() => {
    axios.get(`/organizations/${organization.id}`).then(({ data }) => {
      const { name } = data.data.attributes;
      setValues({
        ...values,
        name,
      });
    });
  }, [organization]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      {status && status.sent && (
        <Alert
          severity="success"
          my={3}
          onClose={() => setStatus({ sent: false })}
        >
          Your data has been submitted successfully!
        </Alert>
      )}

      {errors.submit && (
        <Alert my={3} severity="warning" onClose={() => setErrors({})}>
          {errors.submit}
        </Alert>
      )}
      <TextField
        type="text"
        name="name"
        id="name"
        label={t("auth.organization_name")}
        value={values.name || ""}
        error={Boolean(touched.name && errors.name)}
        helperText={touched.name && errors.name}
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        my={2}
      />

      <Box>
        <Button
          type="submit"
          variant="contained"
          disabled={isSubmitting}
          color="primary"
          mt={3}
        >
          {t("save_changes")}
        </Button>
      </Box>
    </form>
  );
}

export default Organization;
