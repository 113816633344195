/* eslint-disable */
// noinspection JSValidateTypes,JSCheckFunctionSignatures

import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Grid,
  Button,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";

import useAuth from "../hooks/useAuth";
import PaymentMethodList from "../components/PaymentMethodList";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Payment() {
  const { user, userHasCard } = useAuth();
  const { t } = useTranslation();
  const [cards, setCards] = useState([]);

  const stripeCardToken = get(user, "attributes.stripe_card_token");
  useEffect(() => {
    if (userHasCard && stripeCardToken) {
      setCards([
        {
          type: "card",
          id: stripeCardToken,
          attributes: {
            provider: "stripe",
            holder_name: get(user, "attributes.card_holder_name"),
            exp_month: get(user, "attributes.card_exp_month"),
            exp_year: get(user, "attributes.card_exp_year"),
            last4: get(user, "attributes.card_last4"),
            brand: get(user, "attributes.card_brand"),
            country: get(user, "attributes.card_country"),
            currency: get(user, "attributes.card_currency"),
            created_at: get(user, "attributes.card_created_at"),
          },
        },
      ]);
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet title={t("payment.payment_title")} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("payment.payment_title")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("dashboard")}</Typography>
            <Typography>{t("payment.payment_title")}</Typography>
          </Breadcrumbs>
        </Grid>
        {(!userHasCard || !stripeCardToken) && (
          <Grid item>
            <div>
              <Button
                variant="contained"
                color="primary"
                component={NavLink}
                to={"/dashboard/payment/add-payment-method"}
              >
                <AddIcon fontSize={"small"} />
                &nbsp;{t("payment.add_payment_method")}
              </Button>
            </div>
          </Grid>
        )}
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PaymentMethodList rows={cards} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Payment;
