import React, { useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { includes } from "lodash";

import {
  Grid,
  Chip,
  Table,
  Paper,
  Select,
  Tooltip,
  TableRow,
  MenuItem,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  IconButton,
  TableSortLabel,
  TableContainer,
  Box as MuiBox,
  Fab as MuiFab,
  Checkbox as MuiCheckbox,
  Typography as MuiTypography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { ZoomOutMap as ZoomOutMapIcon } from "@mui/icons-material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import {
  fileIsWaitingStagingOrApprovalOrRevisionState,
  getFileInformation,
} from "../utils/fileFilteringHelper";
import { FILE_STATUS } from "../constants";
import dateFormatter from "../utils/dateFormatter";
import Loader from "./Loader";
import RenameModal from "./RenameModal";

const Fab = styled(MuiFab)`
  transform: scale(0.6);
  position: absolute;
  left: 0;
  top: 0;
`;
const DownloadButtonBox = styled(MuiBox)`
  width: 30px;
  background-color: #e06763;
  margin: 0 auto;

  & button {
    color: #fff;
  }
`;

const Checkbox = styled(MuiCheckbox)`
  height: fit-content;
  align-self: center;
`;

const KeywordChip = styled(Chip)`
  margin: 1px;
  background-color: #f5dddc;
  color: #e06763;
`;
const ImageName = styled(Typography)`
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  text-align: left;
  color: #000;
`;

const StatusTitle = styled(MuiTypography)`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  padding: 6px;
  width: 27px;
  height: max-content;
`;

function ProjectDetailListViewTable({
  files,
  order,
  loading,
  orderBy,
  projectDetail,
  isActiveTab,
  downloadFile,
  checkedFiles,
  updateFileName,
  setCompareModal,
  onRoomTypeChange,
  handleRequestSort,
  handleSelectAll,
  setImagePreviewModal,
  handleCheckboxChange,
}) {
  const { t } = useTranslation();
  const [renameModal, setRenameModal] = useState({
    open: false,
    name: null,
    id: null,
  });

  const headCells = [
    {
      id: "image",
      alignment: "left",
      label: "Image",
      isSorting: false,
    },
    {
      id: "file_name",
      alignment: "left",
      label: "File Name",
      isSorting: true,
    },
    {
      id: "keywords",
      alignment: "left",
      label: "Keywords",
      isSorting: false,
    },
    {
      id: "room_type",
      alignment: "left",
      label: "Room Type",
      isSorting: true,
    },
    {
      id: "updated_at",
      alignment: "left",
      label: "Last Updated",
      isSorting: true,
    },
    {
      id: "created_at",
      alignment: "left",
      label: "Upload Date",
      isSorting: true,
    },
  ];

  const ProjectDetailTableHead = () => {
    const createSortHandler = (property) => () => handleRequestSort(property);
    return (
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <Checkbox
              color="primary"
              checked={files.length > 0 && checkedFiles.length === files.length}
              onChange={(e) => handleSelectAll(e)}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignment}
              padding={"normal"}
            >
              <TableSortLabel
                disabled={!headCell.isSorting}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id, headCell.label)}
                active={orderBy === headCell.id}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell align={"right"} padding={"normal"} size={"small"}>
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const returnStatusColor = (status) => {
    switch (status) {
      case FILE_STATUS.COMPLETED:
        return { backgroundColor: "rgba(80,193,63,0.76)", color: "#fff" };
      case FILE_STATUS.PROCESSING:
        return { backgroundColor: "rgba(255,152,0,0.73)", color: "#fff" };
      case FILE_STATUS.WAITING_FOR_REVISION:
        return { backgroundColor: "#E06763", color: "#fff" };
      case FILE_STATUS.WAITING_FOR_STAGING:
        return { backgroundColor: "rgba(255,152,0,0.73)", color: "#fff" };
      case FILE_STATUS.WAITING_FOR_APPROVAL:
        return {
          backgroundColor:
            isActiveTab === "original" ? "#5BBAC7" : "rgba(255,152,0,0.73)",
          color: "#fff",
        };
      case FILE_STATUS.UPLOADED:
        return { backgroundColor: "#5BBAC7", color: "#fff" };
      default:
        return { backgroundColor: "#5BBAC7", color: "#fff", minWidth: "27px" };
    }
  };

  const handleDisabled = (status) => {
    return isActiveTab !== "original" && status !== FILE_STATUS.COMPLETED
      ? isActiveTab === "enhancement"
        ? true
        : status !== FILE_STATUS.WAITING_FOR_APPROVAL
      : false;
  };

  let result = null;

  if (loading) {
    result = (
      <>
        <Grid item xs={12} padding={5}>
          <Loader loading={loading} />
        </Grid>
      </>
    );
  } else if (files?.length === 0) {
    result = (
      <>
        <Grid item xs={12} padding={5}>
          <Typography
            variant="h6"
            color="textSecondary"
            textAlign={"center"}
            mt={6}
          >
            {projectDetail?.attributes?.has_staging_files &&
            isActiveTab === "staging"
              ? t("project_detail.photos_being_processed")
              : t("project_detail.no_files_found")}
          </Typography>
        </Grid>
      </>
    );
  } else {
    result = (
      <>
        <TableContainer>
          <Table size={"small"}>
            <ProjectDetailTableHead />
            <TableBody>
              {!loading &&
                files?.map((f, i) => {
                  const {
                    hid,
                    created_at,
                    modified_file_status,
                    statusTitle,
                    file_name,
                    image_url,
                    keywords,
                    room_type,
                    lastTouchedAt,
                    modified_file_hid,
                    isDownloadDisabled,
                  } = getFileInformation(isActiveTab, f);

                  return (
                    <TableRow
                      style={{ cursor: "pointer" }}
                      hover
                      tabIndex={-1}
                      key={`${hid}-${i}`}
                    >
                      <TableCell align={"center"}>
                        <Checkbox
                          disabled={handleDisabled(modified_file_status)}
                          checked={
                            !handleDisabled(modified_file_status) &&
                            includes(checkedFiles, hid)
                          }
                          inputProps={{ "aria-labelledby": hid }}
                          onClick={() => handleCheckboxChange(hid)}
                        />
                      </TableCell>
                      <TableCell>
                        <MuiBox sx={{ display: "flex" }}>
                          <Grid
                            item
                            sx={returnStatusColor(modified_file_status)}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <StatusTitle variant={"h6"}>
                              {statusTitle}
                            </StatusTitle>
                          </Grid>
                          <Grid
                            item
                            sx={{ position: "relative", alignSelf: "center" }}
                          >
                            <img
                              alt={file_name}
                              src={`${image_url}?w=500&h=500`}
                              style={{
                                display: "block",
                                width: "170px",
                                height: "120px",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            />
                            <Tooltip title={"Click to compare"}>
                              <Fab
                                size="small"
                                color="secondary"
                                aria-label="zoom-out"
                                onClick={() =>
                                  setCompareModal
                                    ? setCompareModal({
                                        open: true,
                                        id: hid,
                                        activeTab: isActiveTab,
                                      })
                                    : setImagePreviewModal({
                                        open: true,
                                        id: hid,
                                      })
                                }
                              >
                                <ZoomOutMapIcon />
                              </Fab>
                            </Tooltip>
                          </Grid>
                        </MuiBox>
                      </TableCell>
                      <TableCell>
                        <Grid sx={{ maxWidth: "110px" }}>
                          <MuiBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ImageName variant="h6">{file_name}</ImageName>
                            <Tooltip title={"Edit file name"}>
                              <IconButton
                                onClick={() =>
                                  setRenameModal({
                                    open: true,
                                    name: file_name,
                                    id: hid,
                                  })
                                }
                              >
                                <DriveFileRenameOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </MuiBox>
                        </Grid>
                      </TableCell>
                      <TableCell sx={{ maxWidth: "140px" }}>
                        <>
                          {keywords?.length ? (
                            keywords.map((keyword, i) => (
                              <KeywordChip key={i} label={keyword} />
                            ))
                          ) : (
                            <ImageName variant="h6">-</ImageName>
                          )}
                        </>
                      </TableCell>
                      <TableCell>
                        <Select
                          sx={{ width: "130px" }}
                          disabled={fileIsWaitingStagingOrApprovalOrRevisionState(
                            modified_file_status
                          )}
                          value={room_type}
                          onChange={(e) =>
                            onRoomTypeChange(e, modified_file_hid || hid)
                          }
                        >
                          <MenuItem value={"living_room"}>
                            {t(`room_type.living_room`)}
                          </MenuItem>
                          <MenuItem value={"dining_room"}>
                            {t(`room_type.dining_room`)}
                          </MenuItem>
                          <MenuItem value={"dining_room_and_living_room"}>
                            {t(`room_type.dining_room_and_living_room`)}
                          </MenuItem>
                          <MenuItem value={"bedroom"}>
                            {t(`room_type.bedroom`)}
                          </MenuItem>
                          <MenuItem value={"kitchen"}>
                            {t(`room_type.kitchen`)}
                          </MenuItem>
                          <MenuItem value={"kitchen_and_dining_room"}>
                            {t(`room_type.kitchen_and_dining_room`)}
                          </MenuItem>
                          <MenuItem value={"kitchen_and_living_room"}>
                            {t(`room_type.kitchen_and_living_room`)}
                          </MenuItem>
                          <MenuItem value={"bathroom"}>
                            {t(`room_type.bathroom`)}
                          </MenuItem>
                          <MenuItem value={"office_space"}>
                            {t(`room_type.office_space`)}
                          </MenuItem>
                          <MenuItem value={"other"}>
                            {t(`room_type.other`)}
                          </MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <ImageName variant="h6">
                          {dateFormatter(lastTouchedAt)}
                        </ImageName>
                      </TableCell>
                      <TableCell>
                        <ImageName variant="h6">
                          {dateFormatter(created_at)}
                        </ImageName>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "#e06763" }}>
                        <DownloadButtonBox>
                          <Tooltip title={"Download"}>
                            <span>
                              <IconButton
                                onClick={() => downloadFile(hid)}
                                disabled={isDownloadDisabled}
                                variant="contained"
                                size={"small"}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </DownloadButtonBox>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <Paper sx={{ minHeight: 400 }}>{result}</Paper>

      <RenameModal
        renameModal={renameModal}
        setRenameModal={setRenameModal}
        updateName={updateFileName}
      />
    </>
  );
}

export default ProjectDetailListViewTable;
