/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { find, get, includes } from "lodash";
import styled from "styled-components/macro";

import {
  Grid,
  Typography,
  Fab as MuiFab,
  Card as MuiCard,
  Divider as MuiDivider,
  CardMedia as MuiCardMedia,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ZoomOutMap as ZoomOutMapIcon } from "@mui/icons-material";

import axios from "../utils/axios";

import { FILE_STATUS } from "../constants";
import CompareImage from "../components/CompareImage";
import ImagePreviewModal from "../components/ImagePreviewModal";
import CompareModal from "../components/compare-modal/CompareModal";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const Fab = styled(MuiFab)`
  transform: scale(0.6);
  position: absolute;
  left: 0;
  top: 0;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 410px;
  overflow: hidden;
  position: relative;

  .rci-container {
    width: 100% !important;
    height: 410px !important;
  }
`;

const Logo = styled.img`
  max-width: 100%;
  height: 94px;
  object-fit: contain;
`;

function ShareableLink() {
  let { id } = useParams();
  const [files, setFiles] = useState([]);
  const [projectTitle, setProjectTitle] = useState("");
  const [organization, setOrganization] = useState(null);
  const [compareModalFile, setCompareModalFile] = useState(null);
  const [imagePreviewModalFile, setImagePreviewModalFile] = useState(null);
  const [compareModal, setCompareModal] = useState({ open: false, id: null });
  const [imagePreviewModal, setImagePreviewModal] = useState({
    open: false,
    id: null,
  });

  const getDetail = (id) => {
    axios
      .get(
        `/sharing-links/${id}?include=related_folder,related_organization,files`
      )
      .then(({ data }) => {
        setFiles(get(data, "files"));
        setProjectTitle(get(data, "title"));
        setOrganization(get(data, "related_organization"));
      });
  };

  const handleZoomIcon = (params) => {
    const { enhancement_file, hid } = params;
    !enhancement_file
      ? setImagePreviewModal({ open: true, id: hid })
      : setCompareModal({ open: true, id: hid, activeTab: "enhancement" });
  };

  const organizationName = get(organization, "name");
  const organizationLogo = get(organization, "logo");

  useEffect(() => getDetail(id), [id]);

  useEffect(() => {
    if (compareModal || imagePreviewModal) {
      const id = get(compareModal, "id") || get(imagePreviewModal, "id");
      const file = find(files, { hid: id });
      if (file) {
        setCompareModalFile(file);
        setImagePreviewModalFile(file);
      }
    }
  }, [compareModal, imagePreviewModal]);

  return (
    <React.Fragment>
      <Helmet title={projectTitle} />
      <Grid container alignItems={"center"}>
        <Grid item xs={6} sm={9}>
          <Typography variant="h3" gutterBottom display="inline">
            {projectTitle}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} textAlign="right">
          {organizationLogo ? (
            <Logo src={organizationLogo} alt={organizationName} />
          ) : (
            <Typography variant="h4" gutterBottom display="inline">
              {organizationName}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Divider my={3} />

      <Grid container spacing={6}>
        {files.length !== 0 &&
          files.map((f, i) => {
            const { public_url, hid, enhancement_file } = f;
            let enhancementPublicUrl = enhancement_file?.public_url;

            if (!includes(enhancementPublicUrl, "enhanced/finals")) {
              enhancementPublicUrl = enhancementPublicUrl?.replace(
                "enhanced",
                "enhanced/finals"
              );
            }

            return (
              <Grid item xs={12} sm={6} key={i}>
                <Card>
                  <CardMedia image={!enhancement_file ? public_url : ""}>
                    {enhancement_file && (
                      <CompareImage
                        hideLabel={true}
                        inComponent={true}
                        left={public_url}
                        right={enhancementPublicUrl}
                        status={FILE_STATUS.COMPLETED}
                      />
                    )}
                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="zoom-out"
                      onClick={() => handleZoomIcon({ enhancement_file, hid })}
                    >
                      <ZoomOutMapIcon />
                    </Fab>
                  </CardMedia>
                </Card>
                <CompareModal
                  files={files}
                  hideLabel={true}
                  hideFooter={true}
                  compareModal={compareModal}
                  setCompareModal={setCompareModal}
                  compareModalFile={compareModalFile}
                  defaultStatus={FILE_STATUS.COMPLETED}
                />
              </Grid>
            );
          })}
      </Grid>
      <ImagePreviewModal
        files={files}
        imagePreviewModal={imagePreviewModal}
        imagePreviewModalFile={imagePreviewModalFile}
        setImagePreviewModal={setImagePreviewModal}
      />
    </React.Fragment>
  );
}

export default ShareableLink;
