import React, { useState } from "react";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import {
  Table,
  Button,
  Select,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  InputLabel,
  MenuItem,
  FormControl,
  TableContainer,
  Paper as MuiPaper,
} from "@mui/material";
import Loader from "./Loader";
import toast from "react-hot-toast";
import dateFormatter from "../utils/dateFormatter";
import { useTranslation } from "react-i18next";
import { head, get } from "lodash";
import axios from "../utils/axios";
import { MyAccountContext, useContext } from "../contexts/MyAccountContext";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS, UPDATE_USER_ROLE } from "../queries/authorization";
import { ROLES, USER_ROLES_FOR_CUSTOMER } from "../constants";
import useAuthorization from "../hooks/useAuthorization";
const Paper = styled(MuiPaper)(spacing);

const headCells = [
  { id: "mail", alignment: "left", label: "Email" },
  { id: "first_name", alignment: "left", label: "First Name" },
  { id: "last_name", alignment: "left", label: "Last Name" },
  { id: "created_at", alignment: "left", label: "Created" },
  { id: "role", alignment: "right", label: "Role" },
];

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function UserListTable() {
  const { t } = useTranslation();
  const { hasRole } = useAuthorization();
  const [reSendId, setReSendId] = useState(null);
  const { data, loading } = useQuery(GET_USERS);
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE, {
    refetchQueries: ["getUsers"],
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const { setErrorResendInvite, setSuccessResendInvite } =
    useContext(MyAccountContext);

  const roleHandleChange = (e, id) => {
    updateUserRole({
      variables: { role_id: e.target.value, user_id: id },
    });
  };

  const resendInvite = async (id, email) => {
    setReSendId(id);
    try {
      await axios.post(`/organizations/invite`, { email }).then(() => {
        setReSendId(null);
        setSuccessResendInvite(true);
        setTimeout(() => setSuccessResendInvite(false), 4000);
      });
    } catch (error) {
      setErrorResendInvite(
        error.response.data.message || "Something went wrong"
      );
      setReSendId(null);
    }
  };

  const ResendButton = ({ id, email }) => {
    return (
      <Button
        style={{ columnGap: "6px" }}
        disabled={reSendId === id}
        variant={"outlined"}
        onClick={() => resendInvite(id, email)}
      >
        {t("resend_invite")}
        {reSendId === id && <Loader color="primary" size="20px" />}
      </Button>
    );
  };

  const RoleSelectBox = ({ id, roleId }) => {
    return (
      <FormControl fullWidth>
        <InputLabel id="role-select-label">Role</InputLabel>
        <Select
          labelId="role-elect-label"
          id="role-select"
          value={roleId}
          label="Role"
          onChange={(e) => roleHandleChange(e, id)}
        >
          <MenuItem value={4}>Admin</MenuItem>
          <MenuItem value={5}>Member</MenuItem>
        </Select>
      </FormControl>
    );
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Paper>
        <TableContainer>
          <Table size={"medium"}>
            <EnhancedTableHead />
            <TableBody>
              {data?.users?.map((user, index) => {
                const labelId = `enhanced-table-${index}`;
                const {
                  id,
                  first_name,
                  last_name,
                  email,
                  status,
                  created_at,
                  roles_users,
                } = user;
                const role_id = get(head(roles_users), "role_id");

                return (
                  <TableRow hover tabIndex={-1} key={`${id}-${index}`}>
                    <TableCell component="th" id={labelId} scope="row">
                      {email}
                    </TableCell>
                    <TableCell>{first_name}</TableCell>
                    <TableCell align="left">{last_name}</TableCell>
                    <TableCell align="left">
                      {dateFormatter(created_at)}
                    </TableCell>
                    <TableCell align="right" sx={{ width: "300px" }}>
                      {hasRole(ROLES.ORGANIZATION_OWNER) ? (
                        <>
                          {status === "3" ? (
                            <ResendButton id={id} email={email} />
                          ) : (
                            <RoleSelectBox id={id} roleId={role_id} />
                          )}
                        </>
                      ) : (
                        USER_ROLES_FOR_CUSTOMER[role_id] || "Unknown"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

export default UserListTable;
