import React, { useState } from "react";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { useSearchParams } from "react-router-dom";

const Intro = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [enabled, setEnabled] = useState(
    !!searchParams.get("onboarding") && searchParams.get("tab") == "staging"
  );

  const [steps] = useState([
    {
      element: ".button-0",
      intro: "Click to approve your photos or leave feedback.",
    },
  ]);

  const config = {
    showProgress: false,
    showBullets: false,
    showStepNumbers: false,
    doneLabel: "Got it!",
    tooltipClass: "intro-tooltip-container",
  };

  const handleClose = () => {
    setEnabled(false);
    if (enabled) {
      searchParams.delete("onboarding");
      setSearchParams(searchParams);
    }
  };

  return (
    <Steps
      enabled={enabled}
      steps={steps}
      initialStep={0}
      onExit={() => handleClose()}
      options={config}
    />
  );
};

export default Intro;
