// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const USER_ROLES_FOR_CUSTOMER = {
  4: "Admin",
  5: "Member",
};

export const ROLES = {
  ORGANIZATION_OWNER: "ORGANIZATION OWNER",
  ORGANIZATION_USER: "ORGANIZATION USER",
  ORGANIZATION_MANAGER: "ORGANIZATION MANAGER",
};

export const FILE_STATUS = {
  CREATED: 1,
  UPLOADING: 2,
  UPLOADED: 3,
  QUEUED: 4,
  PROCESSING: 5,
  WAITING_FOR_APPROVAL: 6,
  COMPLETED: 7,
  FAILED: 8,
  WAITING_FOR_STAGING: 9,
  WAITING_FOR_REVISION: 10,
};

export const FILE_METADATA_ACTIONS = {
  PC: "pc",
  ENHANCEMENT: "enhancement",
  AUTO_ENCODER: "auto_encoder",
  STAGING: "staging",
  STAGING_AUTO_SELECT: "staging_auto_select",
};

export const FILE_REVIEW_STATUS = {
  APPROVED: 1,
  REJECTED: 2,
};

export const CATEGORY_UNIT_TYPE = {
  UNIT: "Unit",
  OTHER: "Other",
};

export const STAGING_STYLES = {
  MODERN: "Modern",
  COASTAL: "Coastal",
  TRADITIONAL: "Traditional",
  SCANDINAVIAN: "Scandinavian",
};

export const STEPS = {
  UPLOAD: 0,
  INFO: 1,
  SELECT_SERVICES: 2,
  FINISH: 3,
};

export const PRICING = {
  ENHANCEMENT: 0.75,
  STAGING: 18,
  PREMIUM_STAGING: 24,
  PREMIUM_ENHANCEMENT: 4,
};
