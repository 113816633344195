/* eslint-disable */
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { get, map, trim, uniq, remove, compact, includes } from "lodash";

import {
  Grid,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
  FormControl,
  Checkbox as MuiCheckbox,
  Typography as MuiTypography,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";

import { STAGING_STYLES } from "../constants";

import Keyword from "./Keyword";

const Label = styled(FormLabel)`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const Image = styled.img`
  width: 100%;
  min-width: 100px;
  max-height: 165px;
  object-fit: cover;
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 2px;
  column-gap: 10px;
  justify-content: end;
  overflow: scroll;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  align-items: center;
  justify-content: center;
  margin: 0;
  height: inherit;
`;

const Checkbox = styled(MuiCheckbox)`
  padding-left: 0;
`;

const Typography = styled(MuiTypography)`
  word-break: break-all;
`;

const ListViewCard = (props) => {
  const { file, formik, stagingStyle, handleStagingCheckbox } = props;
  const { t } = useTranslation();
  const { values, setFieldValue } = formik;

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([
    ...(get(file, "metadata.keywords") || []),
  ]);

  const onRoomTypeChange = (e, file) => {
    const { value } = e.target;
    e.stopPropagation();

    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) f.metadata.room_type = value;
        return f;
      })
    );
  };

  const saveKeywords = (value) => {
    if (!values?.files.length) return;

    setValue(value);

    setFieldValue(
      "files",
      map(values.files, (f) => {
        if (f === file) {
          f.metadata.keywords = value;
        }
        return f;
      })
    );
  };

  const onKeywordsChange = (e, value) => {
    saveKeywords(compact(uniq([...value, trim(get(e, "target.value"))])));
  };

  const handleKeyDown = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      e.stopPropagation();

      if (
        trim(get(e, "target.value")).length &&
        !value.includes(trim(inputValue))
      ) {
        saveKeywords([...value, trim(get(e, "target.value"))]);
        setInputValue("");
      }
    }
  };

  const handleClickOutside = () => {
    if (inputValue && !value.includes(trim(inputValue))) {
      saveKeywords([...value, trim(inputValue)]);
      setInputValue("");
    }
  };

  useEffect(() => {
    const styles = map(STAGING_STYLES, (style) => style);
    const filtered = remove(
      value,
      (item) => !includes(styles, item) && item !== ""
    );

    file.checked && stagingStyle
      ? setValue([...filtered, stagingStyle])
      : setValue(filtered);
  }, [stagingStyle, inputValue, file.checked]);

  useEffect(() => {
    setFieldValue(
      "files",
      map(values?.files, (f) => {
        if (f === file) {
          f.metadata.keywords = value;
        }
        return f;
      })
    );
  }, [value]);

  return (
    <Div>
      <Grid item lg={3.5} xs={3.5} p={3}>
        <Image src={get(file, "public_url")} />
        <Typography
          sx={{
            fontSize: {
              lg: 13,
              xs: 10,
            },
          }}
        >
          {get(file, "original_name")}
        </Typography>
      </Grid>
      <Grid item lg={4} xs={3} p={3}>
        <Label component="legend">{t("project.keywords")}</Label>
        <Keyword
          value={value}
          inputValue={inputValue}
          setInputValue={setInputValue}
          onChange={onKeywordsChange}
          handleKeyDown={handleKeyDown}
          handleClickOutside={handleClickOutside}
        />
        <InputLabel style={{ paddingTop: "5px" }}>
          {t("project.press_enter_return_comma_key_to_add_multiple_keywords")}
        </InputLabel>
      </Grid>
      <Grid item lg={3} xs={3} p={3}>
        <FormControl mt={3} fullWidth>
          <Label component="legend">{t("project.room_type")}</Label>
          <Select
            value={get(file, "metadata.room_type")}
            onChange={(e) => onRoomTypeChange(e, file)}
          >
            <MenuItem value={"living_room"}>
              {t(`room_type.living_room`)}
            </MenuItem>
            <MenuItem value={"dining_room"}>
              {t(`room_type.dining_room`)}
            </MenuItem>
            <MenuItem value={"dining_room_and_living_room"}>
              {t(`room_type.dining_room_and_living_room`)}
            </MenuItem>
            <MenuItem value={"bedroom"}>{t(`room_type.bedroom`)}</MenuItem>
            <MenuItem value={"kitchen"}>{t(`room_type.kitchen`)}</MenuItem>
            <MenuItem value={"kitchen_and_dining_room"}>
              {t(`room_type.kitchen_and_dining_room`)}
            </MenuItem>
            <MenuItem value={"kitchen_and_living_room"}>
              {t(`room_type.kitchen_and_living_room`)}
            </MenuItem>
            <MenuItem value={"bathroom"}>{t(`room_type.bathroom`)}</MenuItem>
            <MenuItem value={"office_space"}>
              {t(`room_type.office_space`)}
            </MenuItem>
            <MenuItem value={"other"}>{t(`room_type.other`)}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        p={2}
        lg={1.5}
        xs={2.5}
        height="100%"
        textAlign="center"
        backgroundColor={!!file.checked ? "#00800033" : "#e0676387"}
      >
        <FormControlLabel
          control={
            <Checkbox
              value="staging"
              checked={!!file.checked}
              onChange={(e) => handleStagingCheckbox(e, file)}
            />
          }
          label="Virtual Staging"
        />
      </Grid>
    </Div>
  );
};

export default ListViewCard;
