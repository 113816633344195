/* eslint-disable */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { get, trim, uniq, compact, includes } from "lodash";

import {
  Chip,
  Grid,
  Select,
  Tooltip,
  Checkbox,
  MenuItem,
  IconButton,
  Fab as MuiFab,
  Box as MuiBox,
  Card as MuiCard,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";
import { ZoomOutMap as ZoomOutMapIcon } from "@mui/icons-material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import axios from "../utils/axios";
import dateFormatter from "../utils/dateFormatter";
import {
  fileIsWaitingStagingOrApprovalOrRevisionState,
  getFileStatusText,
} from "../utils/fileFilteringHelper";

import Keyword from "./Keyword";
import RenameModal from "./RenameModal";
import { FILE_STATUS } from "../constants";

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
  ${spacing};
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
`;

const ImageName = styled(Typography)`
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  text-align: left;
  color: #000;
`;

const Title = styled(Typography)`
  color: #8492a6;
  font-size: 12px;
  margin: 10px 0;
`;

const StatusTitle = styled(MuiTypography)`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  padding: 6px;
  width: 27px;
`;

const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
`;

const DownloadButtonBox = styled(MuiBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  background-color: #e06763;

  & button {
    color: #fff;
  }
`;

const ActionChip = styled(Chip)`
  position: absolute;
  right: 4px;
  bottom: 4px;
  height: 18px;
  font-size: 10px;
  background-color: ${(props) => props.theme.palette[props?.color]};

  & span {
    padding: 0 6px;
  }
`;

const Fab = styled(MuiFab)`
  transform: scale(0.6);
  position: absolute;
  left: 0;
  top: 0;
`;

const ImageListCard = ({
  id,
  image,
  status,
  actions,
  category,
  tabValue,
  roomType,
  keywords,
  imageName,
  createdAt,
  projectId,
  projectName,
  checkedFiles,
  downloadFile,
  lastTouchedAt,
  updateFileName,
  setCompareModal,
  handleCheckboxChange,
  setImagePreviewModal,
  isOriginal = false,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [newRoomType, setNewRoomType] = useState(roomType);
  const [keywordValues, setKeywordValues] = useState(keywords);
  const [originalImage, setOriginalImage] = useState(isOriginal);
  const [renameModal, setRenameModal] = useState({
    open: false,
    name: imageName,
    id,
  });

  useEffect(() => {
    if (actions?.length > 1) {
      // this means image is original file
      setOriginalImage(true);
    }
  }, []);

  const returnStatusColor = () => {
    switch (status) {
      case FILE_STATUS.COMPLETED:
        return { backgroundColor: "rgba(80,193,63,0.76)", color: "#fff" };
      case FILE_STATUS.PROCESSING:
        return { backgroundColor: "rgba(255,152,0,0.73)", color: "#fff" };
      case FILE_STATUS.WAITING_FOR_REVISION:
        return { backgroundColor: "#E06763", color: "#fff" };
      case FILE_STATUS.WAITING_FOR_STAGING:
        return { backgroundColor: "rgba(255,152,0,0.73)", color: "#fff" };
      case FILE_STATUS.WAITING_FOR_APPROVAL:
        return {
          backgroundColor: originalImage ? "#5BBAC7" : "rgba(255,152,0,0.73)",
          color: "#fff",
        };
      case FILE_STATUS.UPLOADED:
        return { backgroundColor: "#5BBAC7", color: "#fff" };
      default:
        return { backgroundColor: "#fff", color: "#fff", minWidth: "27px" };
    }
  };

  const returnActionChip = () => {
    if (actions.length === 1) {
      return actions.map((action, i) => {
        return (
          <ActionChip
            key={i}
            label={t(`actions_label.${action}`)}
            color={"primary"}
          />
        );
      });
    } else return <ActionChip label={"Original"} color={"primary"} />;
  };

  const onRoomTypeChange = async (e) => {
    const { value } = e.target;
    e.stopPropagation();
    setNewRoomType(value);

    if (!id) return;
    await axios.patch(`/files/${id}`, {
      metadata: {
        room_type: value,
      },
    });
  };

  const handleDisabled = () => {
    return !originalImage && status !== FILE_STATUS.COMPLETED
      ? tabValue === "enhancement"
        ? true
        : status !== FILE_STATUS.WAITING_FOR_APPROVAL
      : false;
  };

  const saveKeywords = async (value) => {
    setKeywordValues(value);

    if (!id) return;
    await axios.patch(`/files/${id}`, {
      metadata: {
        keywords: value,
      },
    });
  };

  const onKeywordsChange = (e, value) => {
    saveKeywords(compact(uniq([...value, trim(get(e, "target.value"))])));
  };

  const handleKeyDown = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      e.stopPropagation();

      if (
        trim(get(e, "target.value")).length &&
        !keywordValues.includes(trim(inputValue))
      ) {
        saveKeywords([...keywordValues, trim(get(e, "target.value"))]);
        setInputValue("");
      }
    }
  };

  const handleClickOutside = () => {
    if (inputValue && !keywordValues.includes(trim(inputValue))) {
      saveKeywords([...keywordValues, trim(inputValue)]);
      setInputValue("");
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Grid item alignSelf={"center"}>
          <Checkbox
            disabled={handleDisabled()}
            checked={!handleDisabled() && includes(checkedFiles, id)}
            onChange={() => handleCheckboxChange(id)}
          />
        </Grid>

        <Grid
          item
          sx={returnStatusColor()}
          display={"flex"}
          alignItems={"center"}
        >
          {!originalImage ? (
            <StatusTitle variant={"h6"}>
              {getFileStatusText(image, status)}
            </StatusTitle>
          ) : (
            <StatusTitle variant={"h6"}>Raw</StatusTitle>
          )}
        </Grid>

        <Grid
          item
          margin={3}
          sx={{ position: "relative", alignSelf: "center" }}
        >
          <img
            alt={imageName}
            src={image}
            style={{
              display: "block",
              width: "180px",
              height: "120px",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          {actions && returnActionChip()}
          <Tooltip title={"Click to compare"}>
            <Fab
              size="small"
              color="secondary"
              aria-label="zoom-out"
              onClick={() =>
                originalImage || setImagePreviewModal
                  ? setImagePreviewModal({ open: true, id })
                  : setCompareModal({ open: true, id, activeTab: tabValue })
              }
            >
              <ZoomOutMapIcon />
            </Fab>
          </Tooltip>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          sx={{ margin: "12px 0" }}
        >
          <Grid item sx={{ textAlign: "left", maxWidth: "120px" }}>
            <Box>
              <Title>FILE NAME</Title>
              <Tooltip title={"Edit file name"}>
                <IconButton
                  onClick={() =>
                    setRenameModal({ open: true, name: imageName, id })
                  }
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <ImageName variant="h6">{imageName}</ImageName>
          </Grid>
          {projectName && (
            <Grid item>
              <Title>PROJECT NAME</Title>
              {projectId ? (
                <ImageName component={NavLink} to={`${projectId}`} variant="h6">
                  {projectName}
                </ImageName>
              ) : (
                <ImageName variant="h6">{projectName}</ImageName>
              )}
            </Grid>
          )}
          {category && (
            <Grid item>
              <Title>CATEGORY</Title>
              <ImageName variant="h6">{category}</ImageName>
            </Grid>
          )}
          {createdAt && (
            <Grid item sx={{ textAlign: "left" }}>
              <Title>UPLOAD DATE</Title>
              <ImageName variant="h6">{dateFormatter(createdAt)}</ImageName>
            </Grid>
          )}

          {lastTouchedAt && (
            <Grid item sx={{ textAlign: "left" }}>
              <Title>LAST UPDATED</Title>
              <ImageName variant="h6">{dateFormatter(lastTouchedAt)}</ImageName>
            </Grid>
          )}

          <Grid item sx={{ textAlign: "left" }}>
            <Title>KEYWORDS</Title>
            <Keyword
              width={250}
              value={keywordValues}
              inputValue={inputValue}
              setInputValue={setInputValue}
              onChange={onKeywordsChange}
              handleKeyDown={handleKeyDown}
              handleClickOutside={handleClickOutside}
            />
          </Grid>

          <Grid
            item
            sx={{
              textAlign: "left",
              paddingBottom: "24px",
            }}
          >
            <Title>ROOM TYPE</Title>
            <Select
              disabled={fileIsWaitingStagingOrApprovalOrRevisionState(status)}
              sx={{ width: "195px" }}
              value={newRoomType}
              onChange={(e) => onRoomTypeChange(e)}
            >
              <MenuItem value={"living_room"}>
                {t(`room_type.living_room`)}
              </MenuItem>
              <MenuItem value={"dining_room"}>
                {t(`room_type.dining_room`)}
              </MenuItem>
              <MenuItem value={"dining_room_and_living_room"}>
                {t(`room_type.dining_room_and_living_room`)}
              </MenuItem>
              <MenuItem value={"bedroom"}>{t(`room_type.bedroom`)}</MenuItem>
              <MenuItem value={"kitchen"}>{t(`room_type.kitchen`)}</MenuItem>
              <MenuItem value={"kitchen_and_dining_room"}>
                {t(`room_type.kitchen_and_dining_room`)}
              </MenuItem>
              <MenuItem value={"kitchen_and_living_room"}>
                {t(`room_type.kitchen_and_living_room`)}
              </MenuItem>
              <MenuItem value={"bathroom"}>{t(`room_type.bathroom`)}</MenuItem>
              <MenuItem value={"office_space"}>
                {t(`room_type.office_space`)}
              </MenuItem>
              <MenuItem value={"other"}>{t(`room_type.other`)}</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item sx={{ marginLeft: "auto", paddingLeft: 12 }}>
          <DownloadButtonBox>
            <Tooltip title={"Download"}>
              <span>
                <IconButton
                  onClick={() => downloadFile(id)}
                  disabled={
                    !originalImage &&
                    !(
                      status === FILE_STATUS.COMPLETED ||
                      status === FILE_STATUS.WAITING_FOR_APPROVAL
                    )
                  }
                  variant="contained"
                  size={"small"}
                >
                  <DownloadIcon />
                </IconButton>
              </span>
            </Tooltip>
          </DownloadButtonBox>
        </Grid>
      </Card>
      <RenameModal
        renameModal={renameModal}
        setRenameModal={setRenameModal}
        updateName={updateFileName}
      />
    </React.Fragment>
  );
};
export default ImageListCard;
