/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Radio, Typography, FormControlLabel } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import LoadingButton from "./LoadingButton";
import { STAGING_STYLES } from "../constants";

const StagingCheckbox = (props) => {
  const { url, name, value, isDisabled, stagingStyle, handleStagingStyle } =
    props;

  const imgStyle = {
    width: "100%",
    height: "auto",
    opacity: stagingStyle === value ? "0.2" : "1",
  };

  const divStyle = {
    backgroundColor: stagingStyle === value ? "#0080005e" : "",
  };

  const radioStyle = {
    display: "none",
  };

  const iconStyle = {
    top: "50%",
    left: "50%",
    fill: "white",
    position: "absolute",
    transform: "translate(-50%, -50%)",
  };

  return (
    <Grid item xs={3} sx={{ opacity: isDisabled ? 0.2 : 1 }}>
      <FormControlLabel
        style={{
          margin: "unset",
          position: "relative",
          width: "100%",
          maxWidth: "400px",
        }}
        value={value}
        control={
          <div style={divStyle}>
            <Radio
              value={value}
              style={radioStyle}
              name="radio-buttons"
              disabled={isDisabled}
              checked={stagingStyle === value}
              onChange={(e) => handleStagingStyle(e)}
            />
            <img src={url} style={imgStyle} alt="" />
            {stagingStyle === value && (
              <CheckCircleOutline
                style={iconStyle}
                sx={{
                  fontSize: {
                    lg: 70,
                    md: 50,
                    sm: 15,
                    xs: 10,
                  },
                }}
              />
            )}
          </div>
        }
        label=""
      />
      <Typography variant="h6" align="center">
        {name}
      </Typography>
    </Grid>
  );
};

const StagingStyle = ({
  formik,
  stagingStyle,
  setStagingStyle,
  isNextBtnClicked,
  handleStagingStyle,
  virtualStagingContentRef,
}) => {
  const { values, isSubmitting, handleSubmit } = formik;
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const stagingOptions = [
    {
      url: "/static/img/stagingStyles/modern.jpeg",
      value: STAGING_STYLES.MODERN,
    },
    {
      url: "/static/img/stagingStyles/scandinavian.jpeg",
      value: STAGING_STYLES.SCANDINAVIAN,
    },
    {
      url: "/static/img/stagingStyles/traditional.jpeg",
      value: STAGING_STYLES.TRADITIONAL,
    },
    {
      url: "/static/img/stagingStyles/coastal.jpeg",
      value: STAGING_STYLES.COASTAL,
    },
  ];

  useEffect(() => {
    if (values.files.filter((f) => f.checkedStaging).length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setStagingStyle(null);
    }
  }, [values.files]);

  return (
    values?.files.length > 0 &&
    isNextBtnClicked && (
      <>
        <div id={"virtualStagingContentId"} ref={virtualStagingContentRef}>
          <Grid container>
            <Typography variant="h5" mb={3}>
              {t("project.virtual_staging_style")}
            </Typography>
            <Grid container item spacing={3}>
              {stagingOptions.map((item, key) => (
                <StagingCheckbox
                  key={key}
                  url={item.url}
                  name={item.value}
                  value={item.value}
                  isDisabled={isDisabled || isSubmitting}
                  stagingStyle={stagingStyle}
                  handleStagingStyle={handleStagingStyle}
                />
              ))}
            </Grid>
          </Grid>
        </div>
        <Grid item xs={12} display="flex" justifyContent="center">
          <LoadingButton
            disabled={
              values.files.filter((f) => f.checkedStaging).length > 0 &&
              !stagingStyle
            }
            style={{ width: "300px" }}
            my={4}
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            {t("submit")}
          </LoadingButton>
        </Grid>
      </>
    )
  );
};

export default StagingStyle;
