import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components/macro";

const Icon = styled(InfoOutlinedIcon)`
  display: flex;
  margin: 0 auto;
  font-size: 56px;
  color: ${(props) => props.theme.palette.secondary.main};
  margin-bottom: 12px;
`;

const ContentText = styled(DialogContentText)`
  font-size: 15px;
  text-align: center;
`;

const InfoModal = ({ open, handleClose, description }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Icon />
        <ContentText id="alert-dialog-description">{description}</ContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoModal;
