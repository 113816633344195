import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .introjs-helperLayer {
    box-shadow:rgb(185 96 96 / 80%) 0px 0px 1px 2px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;

  }
  .intro-tooltip-container {
    .introjs-tooltip-header {
      display: none;
    }
    .introjs-tooltipbuttons .introjs-button {
      border:none;
      text-shadow: none;
      font-weight: 500;
      padding: 6px 16px;
      border-radius: 4px;
      color: #FFF;
      background-color: #376fd0;
      zoom: 0;
    }
  }

  
`;

export default GlobalStyle;
