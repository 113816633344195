import React, { useState } from "react";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { MyAccountContext } from "../contexts/MyAccountContext";

import {
  Box,
  Tab,
  Grid,
  Alert,
  Button,
  Typography,
  CardContent,
  Card as MuiCard,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
} from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import UserList from "../components/UsersList";
import AddUserModal from "../components/AddUserModal";
import MyAccountForm from "../components/forms/MyAccount";
import OrganizationForm from "../components/forms/Organization";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function MyAccount() {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("1");
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [successResendInvite, setSuccessResendInvite] = useState(false);
  const [errorResendInvite, setErrorResendInvite] = useState("");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const data = {
    setErrorResendInvite,
    setSuccessResendInvite,
  };

  return (
    <MyAccountContext.Provider value={data}>
      <React.Fragment>
        <Helmet title={t("my_account")} />

        <Typography variant="h3" gutterBottom display="inline">
          {t("my_account")}
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Typography>{t("dashboard")}</Typography>
          <Typography>{t("my_account")}</Typography>
        </Breadcrumbs>

        <Divider my={6} />

        {successResendInvite && (
          <Alert severity="success" my={3} sx={{ marginBottom: "20px" }}>
            Invite sent successfully!
          </Alert>
        )}

        {errorResendInvite && (
          <Alert
            onClose={() => setErrorResendInvite("")}
            severity="warning"
            my={3}
            sx={{ marginBottom: "20px" }}
          >
            {errorResendInvite}
          </Alert>
        )}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <TabContext value={selectedTab}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TabList
                      indicatorColor="primary"
                      onChange={handleTabChange}
                      aria-label="Accounts Tabs"
                    >
                      <Tab label={t("auth.account")} value="1" />
                      <Tab label={t("auth.organization")} value="2" />
                      <Tab label={t("auth.users")} value="3" />
                    </TabList>
                    {selectedTab === "3" && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setAddUserModalOpen(true)}
                      >
                        {t("auth.add_user")}
                      </Button>
                    )}
                  </Box>
                  <TabPanel value={"1"}>
                    <MyAccountForm />
                  </TabPanel>
                  <TabPanel value={"2"}>
                    <OrganizationForm />
                  </TabPanel>
                  <TabPanel value={"3"}>
                    <UserList />
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddUserModal open={addUserModalOpen} setOpen={setAddUserModalOpen} />
      </React.Fragment>
    </MyAccountContext.Provider>
  );
}

export default MyAccount;
