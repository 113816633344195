import { PickerInline } from "filestack-react";
import React from "react";
import Axios from "axios";

const LibraryUpload = (props) => {
  const { organization } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column-reverse",
        width: "100%",
        height: "fit-content",
        zIndex: "89",
        position: "relative",
      }}
    >
      <PickerInline
        apikey="AfDABxM7nTFW7Bzs9hkt9z"
        key="filestack-picker_library"
        clientOptions={{
          security: {
            signature: localStorage.getItem("fileStackSignature"),
            policy: localStorage.getItem("fileStackPolicy"),
          },
        }}
        pickerOptions={{
          exposeOriginalFile: true,
          maxFiles: 1000,
          viewType: "grid",
          uploadInBackground: false,
          concurrency: 10,
          disableStorageKey: true,
          fromSources: [
            "local_file_system",
            "googledrive",
            "googlephotos",
            "dropbox",
            "box",
            "onedrive",
            "facebook",
          ],
          accept: [".png", ".JPG", ".jpeg"],
          storeTo: {
            location: "s3",
            path: `library/${organization.id}/`,
            region: "us-east-2",
            container: process.env.REACT_APP_BUCKET,
            access: "public",
          },
          onFileUploadFinished: (uploadedFile) => {
            const photoObjectBody = {
              image_path: uploadedFile.key,
              hasura_access_token: localStorage.getItem("hasuraAccessToken"),
            };

            Axios.post(
              `${process.env.REACT_APP_DEMAND_GATEWAY_URL}/photos/create`,
              photoObjectBody
            )
              .then(({ data }) => {
                console.log(data);
              })
              .catch((err) => {
                return console.error(err);
              });
          },
        }}
        onUploadDone={() => {
          console.log("all files are uploaded");
        }}
      />
    </div>
  );
};

export default LibraryUpload;
