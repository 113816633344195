import React  from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("privacy_policy")} />

      <Typography variant="h3" gutterBottom display="inline">
        {t("privacy_policy")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>{t("documentation")}</Typography>
        <Typography>{t("privacy_policy")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography>
                <br />
                Thank you for choosing to be part of our community at Oda
                Portal, Inc. ("Company", "we", "us", or "our"). We are committed
                to protecting your personal information and your right to
                privacy. If you have any questions or concerns about our policy,
                or our practices with regards to your personal information,
                please contact us at info@odaportal.com When you visit our
                website https://studio.odaportal.com, and use our services, you
                trust us with your personal information. We take your privacy
                very seriously. In this privacy notice, we describe our privacy
                policy. We seek to explain to you in the clearest way possible
                what information we collect, how we use it and what rights you
                have in relation to it. We hope you take some time to read
                through it carefully, as it is important. If there are any terms
                in this privacy policy that you do not agree with, please
                discontinue use of our site (as defined below) and our services.
                This privacy policy applies to all information collected through
                our website (such as https://studio.odaportal.com), mobile
                application ("Apps"), and/or any related services, sales,
                marketing or events (we refer to them collectively in this
                privacy policy as the "site").
                <br />
                <br />
                <br />
                Please read this privacy policy carefully as it will help you
                make informed decisions about sharing your personal information
                with us.
                <br />
                <br />
                <br />
                <b>1. WHAT INFORMATION DO WE COLLECT?</b>
                <br />
                <br />
                <br />
                Personal information you disclose to us.
                <br />
                <br />
                <br />
                We collect personal information that you provide to us such as
                name, contact information, passwords and security data, and
                payment information. We collect personal information that you
                voluntarily provide to us when registering at the Sites or Apps,
                expressing an interest in obtaining information about us or our
                products and services, when participating in activities on the
                Sites or Apps or otherwise contacting us. The personal
                information that we collect depends on the context of your
                interactions with us and the Sites or Apps, the choices you make
                and the products and features you use. The personal information
                we collect can include the following:
                <br />
                <br />- Name and Contact Data. We collect your first and last
                name, email address, postal address, phone number, and other
                similar contact data.
                <br />
                <br />- Credentials. We collect passwords, password hints, and
                similar security information used for authentication and account
                access.
                <br />
                <br />- Payment Data. We relay the data necessary to process
                your payment if you make purchases, such as your payment
                instrument number (such as a credit card number), and the
                security code associated with your payment instrument, to our
                third-party payment processor (as defined below). We do not
                store or have access to your payment information. All payment
                data is stored by our payment processor and you should review
                its privacy policies and contact the payment processor directly
                to respond to your questions.
                <br />
                <br />
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
                <br />
                <br />
                <br />
                Information automatically collected
                <br />
                <br />
                <br />
                Some information — such as IP address and/or browser and device
                characteristics — is collected automatically when you visit our
                site.
                <br />
                <br />
                <br />
                We automatically collect certain information when you visit, use
                or navigate the Sites or Apps. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Sites or
                Apps and other technical information. This information is
                primarily needed to maintain the security and operation of our
                Sites or Apps, and for our internal analytics and reporting
                purposes.
                <br />
                <br />
                <br />
                Like many businesses, we also collect information through
                cookies and similar technologies. “Cookies” are identifiers we
                transfer to your browser or device that allow us to recognize
                your browser or device and tell us how and when pages and
                features on our site are visited and by how many people. You may
                be able to change the preferences on your browser or device to
                prevent or limit your device’s acceptance of cookies, but this
                may prevent you from taking advantage of some of our features.
                <br />
                <br />
                <br />
                Our Advertisers may also transmit cookies to your browser or
                device, when you click on ads that appear on the site. Also, if
                you click on a link to a third-party website or service, a third
                party may also transmit cookies to you. Again, this Policy does
                not cover the use of cookies by any third parties, and we aren’t
                responsible for their privacy policies and practices. Please be
                aware that cookies placed by third parties may continue to track
                your activities online even after you have left our site, and
                those third parties may not honor “Do Not Track” requests you
                have set using your browser or device.
                <br />
                <br />
                <br />
                We may receive a confirmation when you open an email from us.
                This confirmation helps us make our communications with you more
                interesting and improve our services.
                <br />
                <br />
                <br />
                Information collected from other sources
                <br />
                <br />
                <br />
                We may collect limited data from public databases, marketing
                partners, and other outside sources. We may obtain information
                about you from other sources, such as public databases, joint
                marketing partners, as well as from other third parties.
                Examples of the information we receive from other sources
                include: social media profile information; marketing leads and
                search results and links, including paid listings (such as
                sponsored links).
                <br />
                <br />
                <br />
                <br />
                <b>2. HOW DO WE USE YOUR INFORMATION?</b>
                <br />
                <br />
                <br />
                We process your information for purposes based on legitimate
                business interests, the fulfillment of our contract with you,
                compliance with our legal obligations, and/or your consent.
                <br />
                <br />
                We use personal information collected via our site for a variety
                of business purposes described below. We process your personal
                information for these purposes in reliance on our legitimate
                business interests, in order to enter into or perform a contract
                with you, with your consent, and/or for compliance with our
                legal obligations. We indicate the specific processing grounds
                we rely on next to each purpose listed below.
                <br />
                <br />
                <br />
                We use the information we collect or receive:
                <br />
                To facilitate account creation and logon process. If you choose
                to link your account with us to a third- party account (such as
                your Google or Facebook account), we use the information you
                allowed us to collect from those third parties to facilitate
                account creation and logon process.
                <br />
                To send you marketing and promotional communications. We and/or
                our third-party marketing partners may use the personal
                information you send to us for our marketing purposes, if this
                is in accordance with your marketing preferences. You can
                opt-out of our marketing emails at any time (see the " WHAT ARE
                YOUR PRIVACY RIGHTS " below).
                <br />
                Fulfill and manage your orders. We may use your information to
                fulfill and manage your orders, payments, returns, and exchanges
                made through the Sites or Apps.
                <br />
                To post testimonials. We post testimonials on our Sites or Apps
                that may contain personal information. Prior to posting a
                testimonial, we will obtain your consent to use your name and
                testimonial. If you wish to update, or delete your testimonial,
                please contact us at help@odaportal.com and be sure to include
                your name, testimonial location, and contact information.
                <br />
                Deliver targeted advertising to you. We may use your information
                to develop and display content and advertising (and work with
                third parties who do so) tailored to your interests and/or
                location and to measure its effectiveness.
                <br />
                Request Feedback. We may use your information to request
                feedback and to contact you about your use of our Sites or Apps.
                <br />
                To protect our Sites. We may use your information as part of our
                efforts to keep our Sites or Apps safe and secure (for example,
                for fraud monitoring and prevention).
                <br />
                To enforce our terms, conditions and policies.
                <br />
                To respond to legal requests and prevent harm. If we receive a
                subpoena or other legal request, we may need to inspect the data
                we hold to determine how to respond.
                <br />
                <br />
                <br />
                <b>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</b>
                <br />
                <br />
                <br />
                We only share information with your consent, to comply with
                laws, to protect your rights, or to fulfill business
                obligations.
                <br />
                <br />
                <br />
                We may process or share data based on the following legal basis:
                <br />
                Consent: We may process your data if you have given us specific
                consent to use your personal information in a specific purpose.
                <br />
                Legitimate Interests: We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
                <br />
                Performance of a Contract: Where we have entered into a contract
                with you, we may process your personal information to fulfill
                the terms of our contract.
                <br />
                Legal Obligations: We may disclose your information where we are
                legally required to do so in order to comply with applicable
                law, governmental requests, a judicial proceeding, court order,
                or legal process, such as in response to a court order or a
                subpoena (including in response to public authorities to meet
                national security or law enforcement requirements).
                <br />
                Vital Interests: We may disclose your information where we
                believe it is necessary to investigate, prevent, or take action
                regarding potential violations of our policies, suspected fraud,
                situations involving potential threats to the safety of any
                person and illegal activities, or as evidence in litigation in
                which we are involved.
                <br />
                More specifically, we may need to process your data or share
                your personal information in the following situations:
                <br />
                Vendors, Consultants and Other Third-Party Service Providers. We
                may share your data with third party vendors, service providers,
                contractors or agents who perform services for us or on our
                behalf and require access to such information to do that work.
                Examples include: payment processing, data analysis, email
                delivery, hosting services, customer service and marketing
                efforts. We may allow selected third parties to use tracking
                technology on the site, which will enable them to collect data
                about how you interact with the site over time. This information
                may be used to, among other things, analyze and track data,
                determine the popularity of certain content and better
                understand online activity. Unless described in this Policy, we
                do not share, sell, rent or trade any of your information with
                third parties for their promotional purposes.
                <br />
                <br />
                We only share and disclose your information with the following
                third parties. We have categorized each party so that you may be
                easily understand the purpose of our data collection and
                processing practices. If we have processed your data based on
                your consent and you wish to revoke your consent, please contact
                us.
                <br />
                <br />
                Advertising, Direct Marketing, and Lead Generation - Google
                AdSense
                <br />
                Allow Users to Connect to their Third-Party Accounts - and
                Facebook account
                <br />
                Retargeting Platforms - Facebook Custom Audience and Facebook
                Remarketing
                <br />
                Invoice and Billing - Stripe
                <br />
                <br />
                <br />
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
                <br />
                Business Partners. We may share your information with our
                business partners to offer you certain products, services or
                promotions.
                <br />
                <br />
                <br />
                <b>4. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
                <br />
                <br />
                <br />
                We keep your information for as long as necessary to fulfill the
                purposes outlined in this privacy policy unless otherwise
                required by law.
                <br />
                <br />
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy policy,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements). No
                purpose in this policy will require us keeping your personal
                information for longer than 1 year past the termination of the
                user's account.
                <br />
                <br />
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize it, or,
                if this is not possible (for example, because your personal
                information has been stored in backup archives), then we will
                securely store your personal information and isolate it from any
                further processing until deletion is possible.
                <br />
                <br />
                <br />
                <br />
                <b>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
                <br />
                <br />
                <br />
                We aim to protect your personal information through a system of
                organizational and technical security measures.
                <br />
                <br />
                We have implemented appropriate technical and organizational
                security measures designed to protect the security of any
                personal information we process. However, please also remember
                that we cannot guarantee that the internet itself is 100%
                secure. Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our Sites or Apps is at your own risk. You should only access
                the services within a secure environment.
                <br />
                <br />
                <br />
                <br />
                <b>6. DO WE COLLECT INFORMATION FROM MINORS?</b>
                <br />
                <br />
                <br />
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the sites, you represent that
                you are at least 18. If we learn that personal information from
                users less than 18 years of age has been collected, we will
                deactivate the account and take reasonable measures to promptly
                delete such data from our records. If you become aware of any
                data we have collected from children under age 18, please
                contact us at info@odaportal.com.
                <br />
                <br />
                <br />
                <br />
                <b>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
                <br />
                <br />
                <br />
                We aim to protect your personal information through a system of
                organizational and technical security measures.We have
                implemented appropriate technical and organizational security
                measures designed to protect the security of any personal
                information we process. However, please also remember that we
                cannot guarantee that the internet itself is 100% secure.
                Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our Sites or Apps is at your own risk. You should only access
                the services within a secure environment.
                <br />
                <br />
                <br />
                <b>8. WHAT ARE YOUR PRIVACY RIGHTS?</b>
                <br />
                <br />
                <br />
                You may review, change, or terminate your account at any time.
                <br />
                <br />
                <br />
                Account Information
                <br />
                <br />
                <br />
                If you would at any time like to review or change the
                information in your account or terminate your account, you can
                contact us using the contact information provided.
                <br />
                <br />
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, some information may be retained in our
                files to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our Terms of Use and/or comply with
                legal requirements.
                <br />
                <br />
                Opting-out of email marketing: You can unsubscribe from our
                marketing email list at any time by clicking on the unsubscribe
                link in the emails that we send or by contacting us using the
                details provided below. You will then be removed from the
                marketing email list — however, we will still need to send you
                service-related emails that are necessary for the administration
                and use of your account. To otherwise opt- out, you may contact
                us using the contact information provided.
                <br />
                <br />
                <br />
                <br />
                <b>9. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
                <br />
                <br />
                <br />
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. No uniform technology standard for recognizing and
                implementing DNT signals has been finalized. As such, we do not
                currently respond to DNT browser signals or any other mechanism
                that automatically communicates your choice not to be tracked
                online. If a standard for online tracking is adopted that we
                must follow in the future, we will inform you about that
                practice in a revised version of this Privacy Policy.
                <br />
                <br />
                <br />
                <br />
                <b>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b>
                <br />
                <br />
                <br />
                Yes, if you are a resident of California, you are granted
                specific rights regarding access to your personal information.
                <br />
                <br />
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
                <br />
                <br />
                If you are under 18 years of age, reside in California, and have
                a registered account with the Sites or Apps, you have the right
                to request removal of unwanted data that you publicly post on
                the Sites or Apps. To request removal of such data, please
                contact us using the contact information provided below, and
                include the email address associated with your account and a
                statement that you reside in California. We will make sure the
                data is not publicly displayed on the Sites or Apps, but please
                be aware that the data may not be completely or comprehensively
                removed from our systems.
                <br />
                <br />
                <br />
                <br />
                <b>12. DO WE MAKE UPDATES TO THIS POLICY?</b>
                <br />
                <br />
                <br />
                Yes, we will update this policy as necessary to stay compliant
                with relevant laws.
                <br />
                <br />
                We may update this privacy policy from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy policy, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy policy frequently to be informed of
                how we are protecting your information.
                <br />
                <br />
                <br />
                <br />
                <b>13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</b>
                <br />
                <br />
                <br />
                If you have questions or comments about this policy, you may
                email us at info@odaportal.com
                <br />
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
