import React from "react";
import styled from "styled-components/macro";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

function SignIn() {
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const location = useLocation();

  const initialValues = {
    email: "",
    password: "",
    submit: false,
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("validation.invalid_email"))
      .max(255)
      .required(t("validation.enter_your_email")),
    password: Yup.string()
      .max(255)
      .required(t("validation.enter_your_password")),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password);
        } catch (error) {
          const message = error.response.data.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {location?.state && (
            <Alert severity="success" my={3}>
              {location?.state?.message}
            </Alert>
          )}
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label={t("auth.email")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label={t("auth.password")}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />*/}

          <Button
            mt={3}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t("auth.signin")}
          </Button>
          <Button
            component={Link}
            to="/reset-password"
            fullWidth
            color="primary"
          >
            {t("auth.forgot_password")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
