import React, { useState } from "react";
import { includes } from "lodash";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  Grid,
  MenuItem,
  TextField,
  CardContent,
  FormControl,
  Typography,
  Button,
  Autocomplete,
} from "@mui/material";

import { CATEGORY_UNIT_TYPE, STEPS } from "../../constants";
import LoadingButton from "../LoadingButton";
import { useQuery } from "@apollo/client";
import { GET_PROPERTIES_QUERY } from "../../queries/properties";
import AddPropertyModal from "../AddPropertyModal";

const AddPropertyText = styled(Typography)`
  span {
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  }
`;
const Strong = styled.strong`
  color: #e06763;
`;

const NextButton = styled(LoadingButton)`
  width: 100%;
  margin-top: 25px;
  background-color: #e06763;

  &:hover {
    background-color: #bd5552;
  }
`;

function InfoStep(props) {
  const {
    id,
    projectDetail,
    formik,
    hideUnitInput,
    disableNextButton,
    setActiveStep,
    setIsNextBtnClicked,
  } = props;
  const [addPropertyModalOpen, setAddPropertyModalOpen] = useState(false);
  const { data: properties } = useQuery(GET_PROPERTIES_QUERY);
  const { values, errors, handleBlur, handleChange, touched, setFieldValue } =
    formik;

  const { t } = useTranslation();
  const { UNIT, OTHER } = CATEGORY_UNIT_TYPE;

  const handleErrorMessage = (errorText) => {
    return errorText && includes([UNIT, OTHER], values.category)
      ? t("validation.enter_unit_type", { name: values.category })
      : errorText;
  };

  return (
    <>
      <Box my={5} display="flex" alignItems={"center"} justifyContent={"end"}>
        <Button
          variant="outlined"
          onClick={() => {
            setActiveStep(STEPS.UPLOAD);
          }}
        >
          {t("back")}
        </Button>
      </Box>
      <Card mb={6}>
        <CardContent>
          <Grid
            item
            xs={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
          >
            <Grid display="grid" gap={5} mb={7}>
              <FormControl>
                <Autocomplete
                  value={{
                    name:
                      projectDetail?.attributes?.title || values.propertyName,
                  }}
                  disabled={!!id}
                  name={"propertyId"}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    setFieldValue("propertyId", newValue?.id);
                    setFieldValue("propertyName", newValue?.name);
                  }}
                  getOptionLabel={(option) => option?.name || ""}
                  options={properties?.real_estate_properties || []}
                  renderInput={(params) => (
                    <TextField {...params} label="Property Name" />
                  )}
                  noOptionsText={
                    <>
                      <AddPropertyText>
                        No results!{" "}
                        <span
                          onClick={() => {
                            setAddPropertyModalOpen(true);
                          }}
                        >
                          Add Property
                        </span>
                      </AddPropertyText>
                    </>
                  }
                />
              </FormControl>
              <FormControl>
                <TextField
                  select
                  disabled={!!id}
                  id={"category"}
                  name={"category"}
                  label={t("project.select_category")}
                  value={
                    projectDetail?.attributes?.metadata?.category ||
                    values.category
                  }
                  error={touched.category && Boolean(errors.category)}
                  helperText={touched.category && errors.category}
                  onChange={handleChange}
                >
                  <MenuItem value={"Unit"}>Unit</MenuItem>
                  <MenuItem value={"Lifestyle"}>Lifestyle</MenuItem>
                  <MenuItem value={"Exterior"}>Exterior</MenuItem>
                  <MenuItem value={"Amenities"}>Amenities</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                sx={{ display: hideUnitInput ? "none" : "flex" }}
              >
                <TextField
                  disabled={!!id}
                  label={values.category === UNIT ? UNIT : OTHER}
                  name="unitName"
                  value={
                    values.unitName ||
                    projectDetail?.attributes?.metadata?.category_value
                  }
                  error={touched.unitName && Boolean(errors.unitName)}
                  helperText={
                    touched.unitName && handleErrorMessage(errors.unitName)
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Box>
              <Typography gutterBottom>
                We will ask about <Strong>Virtual Staging</Strong> preferences
                on the next step.
              </Typography>
              <NextButton
                variant="contained"
                loading={disableNextButton && values.files.length > 0}
                disabled={
                  !formik.isValid ||
                  values.files.length === 0 ||
                  disableNextButton
                }
                onClick={() => {
                  setActiveStep(STEPS.SELECT_SERVICES);
                  setIsNextBtnClicked(true);
                }}
              >
                {t("next")}
              </NextButton>
            </Box>
          </Grid>
        </CardContent>
      </Card>
      <AddPropertyModal
        open={addPropertyModalOpen}
        setOpen={setAddPropertyModalOpen}
      />
    </>
  );
}

export default InfoStep;
