import { useFlags } from "flagsmith/react";
import { useEffect, useState } from "react";
import { Briefcase, CreditCard, FolderPlus, User } from "react-feather";

import {
  Collections as CollectionsIcon,
  HolidayVillageOutlined as HolidayVillageOutlinedIcon,
} from "@mui/icons-material";

import useAuthorization from "./useAuthorization";

function useDashboardItems() {
  const { hasPermission } = useAuthorization();
  const flags = useFlags(["show_library_menu_item"]);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;

    const pages = [
      {
        href: "/dashboard/new-project",
        icon: FolderPlus,
        title: "New Project",
      },
      {
        href: "/dashboard/projects",
        icon: Briefcase,
        title: "Projects",
        badge: true,
      },
      {
        href: "/dashboard/my-account",
        icon: User,
        title: "My Account",
      },
      {
        href: "/dashboard/payment",
        icon: CreditCard,
        title: "Payment",
      },
    ];

    //if (hasPermission("REAL_ESTATE_PROPERTY_LIST_ACTION")) {
    pages.push({
      href: "/dashboard/properties",
      icon: HolidayVillageOutlinedIcon,
      title: "Properties",
    });
    // }

    if (flags.show_library_menu_item.enabled) {
      pages.push({
        href: "/dashboard/library",
        icon: CollectionsIcon,
        title: "Library",
      });
    }

    setItems(pages);
    setLoading(false);
  }, [loading, hasPermission]);

  return { loading, items };
}

export default useDashboardItems;
