import React from "react";
import { includes } from "lodash";
import { FILE_STATUS } from "../constants";

import ReactCompareImage from "../vendor/react-compare-image";
import { getFileStatusText } from "../utils/fileFilteringHelper";

const CompareImage = ({
  left,
  right,
  status = FILE_STATUS.COMPLETED,
  hideLabel,
  leftLabel,
  rightLabel,
  inComponent,
  disabledLazyLoading,
}) => {
  const { COMPLETED, WAITING_FOR_REVISION } = FILE_STATUS;

  const getLeftImageLabelStyle = () => {
    if (status === COMPLETED && includes(left, "staging")) {
      return {
        background: "rgb(33 170 39 / 57%)",
      };
    } else if (status === WAITING_FOR_REVISION && includes(left, "staging")) {
      return {
        background: "rgb(216 11 11 / 57%)",
      };
    }
  };

  return (
    <div className={"compare-image-parent"} style={{ margin: "0 auto" }}>
      <ReactCompareImage
        hideLabel={hideLabel}
        disabledLazyLoading={disabledLazyLoading}
        leftImageLabelStyle={getLeftImageLabelStyle()}
        leftImageLabel={leftLabel || getFileStatusText(left, status)}
        rightImageLabel={rightLabel || "Original"}
        style={{ boxShadow: "none" }}
        sliderLineWidth={2}
        sliderLineColor={"#f9f5ec"}
        sliderPositionPercentage={0.5}
        hover={false}
        handle={<React.Fragment />}
        leftImage={left}
        rightImage={right}
        inComponent={inComponent}
        leftImageStyle={
          getFileStatusText(left, status) === "In Progress" && {
            filter: "blur(3px)",
          }
        }
      />
    </div>
  );
};

export default CompareImage;
