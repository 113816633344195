import React from "react";
import { Card as MuiCard, CardMedia, Grid } from "@mui/material";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { get } from "lodash";
import CompareImage from "./CompareImage";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  imgWrapper: {
    "& div": {
      borderRadius: 5,
      cursor: "pointer",
    },
  },
  cardMedia: {
    height: "510px",
    overflow: "hidden",
    position: "relative",
    "& > div:first-child": {
      minHeight: "510px",
      maxHeight: "100%",
    },
  },
}));

export const IframeView = ({ files, selectImageHandler, selectedFile }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={6} mt={3}>
      <Grid
        item
        xs={6}
        alignSelf={"center"}
        style={{ height: "80vh", overflow: "scroll" }}
      >
        <div className={classes.root}>
          <ImageList rowHeight={300} gap={30}>
            {files.map((f) => {
              const { original_file, hid, file_name } = f;
              return (
                <ImageListItem key={hid} className={classes.imgWrapper}>
                  <img
                    onClick={() => selectImageHandler(f)}
                    src={get(original_file, "public_url")}
                    alt={file_name}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </div>
      </Grid>
      <Grid item xs={6} alignSelf={"center"}>
        <Card>
          <CardMedia className={classes.cardMedia}>
            <CompareImage
              hideLabel={true}
              inComponent={true}
              right={selectedFile?.original_file?.public_url}
              left={selectedFile?.public_url}
            />
          </CardMedia>
        </Card>
      </Grid>
    </Grid>
  );
};
