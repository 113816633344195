import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import axios from "../utils/axios";
import { useFlags } from "flagsmith/react";
import { useNavigate } from "react-router-dom";
import { IframeView } from "../components/IframeView";

function Iframe() {
  let { id } = useParams();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const flags = useFlags(["iframe"]);
  let navigate = useNavigate();

  useEffect(() => {
    if (flags.iframe.value === false) {
      navigate("/dashboard/new-project");
    }
  }, [flags.iframe.value]);

  const getFiles = (id) => {
    axios
      .get(
        `/sharing-links/${id}?include=related_folder,related_organization,files`
      )
      .then(({ data }) => {
        const { files } = data;
        setFiles(files);
        setSelectedFile(files[0]);
      });
  };

  const selectImageHandler = (f) => {
    setSelectedFile(f);
  };

  useEffect(() => {
    getFiles(id);
  }, [id]);

  return (
    <React.Fragment>
      <Grid container alignItems={"center"} mb={3}>
        <Typography variant="h3" gutterBottom display="inline">
          Oda Look 👀
        </Typography>
      </Grid>

      {files.length > 0 && (
        <IframeView
          selectedFile={selectedFile}
          selectImageHandler={selectImageHandler}
          files={files}
        />
      )}
    </React.Fragment>
  );
}

export default Iframe;
