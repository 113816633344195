import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Box, Paper, Typography } from "@mui/material";
import SignUpForm from "../../components/auth/SignUp";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Logo = styled.img`
  width: 105px;
  padding-bottom: 30px;
`;

const Link = styled(NavLink)`
  padding: 0 ${(props) => props.theme.spacing(1)};
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
`;

const Wrapper = styled(Paper)`
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUp() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("auth.signup")} />

      <Box sx={{ maxWidth: "500px" }}>
        <a href="https://studio.odaportal.com/" target="_blank" rel="noreferrer">
          <Logo src={"/oda-logo.png"} alt={"oda studio"} />
        </a>
        <Wrapper>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            {t("auth.welcome_to_oda_studio")}
          </Typography>
          <Typography component="h2" variant="body1" align="center" mb={5}>
            {t("auth.have_an_account")}
            <Link to="/sign-in">{t("auth.signin")}</Link>
          </Typography>
          <SignUpForm />
        </Wrapper>
      </Box>
    </React.Fragment>
  );
}

export default SignUp;
