import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import styled from "styled-components/macro";
import BaseCompareImage from "../CompareImage";
import { Modal as MuiModal } from "@mui/material";

export const CircleButton = styled(Button)`
  width: 50px;
  height: 50px;
  min-width: auto;
  border-radius: 50%;
  transform: scale(0.75);
`;

export const CloseButton = styled(CircleButton)`
  top: 0;
  right: 0;
  position: absolute;
`;
export const Modal = styled(MuiModal)`
  display: flex;
`;

export const ModalWrapper = styled(Box)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: max-content;
`;

export const ModalViewport = styled(Box)`
  display: grid;
  grid-gap: 30px;
  max-height: 95vh;
  grid-template-columns: 60px 1fr 60px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: unset;
    grid-gap: 5px;
    max-width: 95vh;
  }
`;

export const ViewportContent = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: max-content;
    flex-direction: column;
  }
`;

export const ViewportNavItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewportReviewBar = styled(Box)`
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.palette.background.paper};
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export const NoCommentBox = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const CommentBox = styled(Box)`
  overflow-y: scroll;
  max-height: 53vh;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-items: center;
`;

export const ReviewBarGrid = styled(Grid)`
  align-items: center;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.palette.background.paper};
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down("sm")} {
    justify-content: center;
  }
`;

export const CompareImage = styled(BaseCompareImage)`
  min-height: 500px;
  min-width: 500px;
`;
