import Axios from "axios";
import { race } from "async";
import { get, last, split } from "lodash";
import { checkRoomTypes } from "./fileFilteringHelper";
import ImageResizer from "./ImageResizer";

const uploadFilesToImageApi = async (acceptedFiles, cb) => {
  race(
    acceptedFiles.map((file) => async () => {
      const timeStampHash = (+new Date() + Math.random() * 100).toString(32);
      const uploadOptions = {
        headers: {
          "Content-Type": file.type,
        },
      };
      const ext = last(split(file.type, "/"));
      const uploadUrl = `https://oda-api.s3-us-east-2.amazonaws.com/tmp/${timeStampHash}.${ext}`;
      const filteringUrl = "https://caption.odaportal.com/v2";
      const filteringBody = {
        image_url: uploadUrl,
      };
      const filteringOptions = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CAPTION_TOKEN}`,
        },
      };

      const resizedImage = await ImageResizer.createResizedImage(
        file,
        300,
        300,
        "JPEG",
        50
      );

      Axios.put(uploadUrl, resizedImage, uploadOptions)
        .then(() => {
          Axios.post(filteringUrl, filteringBody, filteringOptions)
            .then(({ data }) => {
              const roomType = get(data, "label");
              file.auto_detections = get(data, "detected_objects");
              if (!file.room_type) file.room_type = checkRoomTypes(roomType);
              file.detected_room_type = roomType;
              return cb(acceptedFiles);
            })
            .catch((err) => {
              return cb(err);
            });
        })
        .catch((err) => {
          cb(err);
        });
    })
  );
};

export default uploadFilesToImageApi;
