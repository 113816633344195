import { map, get, find, filter, includes } from "lodash";
import { FILE_METADATA_ACTIONS, FILE_STATUS } from "../constants";

const { STAGING, ENHANCEMENT } = FILE_METADATA_ACTIONS;
const {
  FAILED,
  COMPLETED,
  WAITING_FOR_APPROVAL,
  WAITING_FOR_REVISION,
  WAITING_FOR_STAGING,
} = FILE_STATUS;

export const fileIsCompleted = (file) => get(file, "status") === COMPLETED;

export const fileIsCompletedOrApprovalOrRevisionState = (file) =>
  includes(
    [COMPLETED, WAITING_FOR_APPROVAL, WAITING_FOR_REVISION],
    get(file, "status")
  );

export const fileIsCompletedOrApprovalState = (file) =>
  includes(
    [COMPLETED, WAITING_FOR_APPROVAL, WAITING_FOR_STAGING],
    get(file, "status")
  );

export const fileIsWaitingStagingOrApprovalOrRevisionState = (status) =>
  includes(
    [WAITING_FOR_APPROVAL, WAITING_FOR_STAGING, WAITING_FOR_REVISION],
    status
  );

export const fileHasStagingAction = (file) =>
  includes(get(file, "metadata.actions"), STAGING);

export const fileHasEnhancementAction = (file) =>
  includes(get(file, "metadata.actions"), ENHANCEMENT);

export const getFileStatusText = (leftImage, status) => {
  const statusLabelMapping = {
    [COMPLETED]: "Processed",
    [WAITING_FOR_APPROVAL]: "In Progress",
    [FAILED]: "Failed",
    [WAITING_FOR_REVISION]: "In Revision",
  };

  if (includes(leftImage, "staging")) {
    statusLabelMapping[COMPLETED] = "Approved";
    statusLabelMapping[WAITING_FOR_APPROVAL] = "Waiting for review";
  }

  return statusLabelMapping?.[status] || "In Progress";
};

export const checkRoomTypes = (room_type) => {
  const room_types = [
    "living_room",
    "bedroom",
    "bathroom",
    "kitchen",
    "office_space",
    "dining_room",
    "balcony",
    "kitchen_and_dining_room",
    "kitchen_and_living_room",
    "dining_room_and_living_room",
  ];
  if (room_types.includes(room_type)) {
    return room_type;
  } else {
    return "other";
  }
};

/**
 * If there's a modified file with completed status, returns
 * Otherwise returns the last image
 *
 * @param {List[File]} modifiedFiles
 * @returns
 */
const getCorrectEnhancementFile = (modifiedFiles) => {
  const enhancementFiles = filter(modifiedFiles, (file) => {
    return fileHasEnhancementAction(file) && !fileHasStagingAction(file);
  });

  // try to get the completed image
  const completedEnhancementFile = find(enhancementFiles, (file) => {
    return get(file, "status") === FILE_STATUS.COMPLETED;
  });

  // if there's a completed image, return it
  if (completedEnhancementFile) {
    return completedEnhancementFile;
  }

  // if there's no completed image, return the last image
  // Assuming that the last image is the one with the highest id
  return enhancementFiles[enhancementFiles.length - 1];
};

export const getFileInformation = (isActiveTab, f) => {
  const { hid, metadata, file_name, public_url, created_at, modified_files } =
    f;

  let lastTouchedAt, keywords, room_type;
  let modified_file_status, modified_url, modified_file_hid;
  let image_url, statusTitle;

  let isDownloadDisabled = false;

  if (isActiveTab === "original") {
    image_url = public_url;
    keywords = get(metadata, "keywords");
    room_type = get(metadata, "room_type");
    lastTouchedAt = get(f, "metadata.last_touched_at") || get(f, "updated_at");

    // for original images, Status Title is always "Raw"
    statusTitle = "Raw";
  } else if (isActiveTab === "enhancement") {
    const enhancement_file = getCorrectEnhancementFile(modified_files);

    keywords = get(enhancement_file, "metadata.keywords");
    room_type = get(enhancement_file, "metadata.room_type");
    modified_file_hid = get(enhancement_file, "hid");
    modified_file_status = get(enhancement_file, "status");

    modified_url = get(enhancement_file, "public_url");
    image_url =
      modified_file_status === FILE_STATUS.COMPLETED
        ? modified_url
        : public_url;

    statusTitle = getFileStatusText(modified_url, modified_file_status);

    lastTouchedAt =
      get(enhancement_file, "metadata.last_touched_at") ||
      get(enhancement_file, "updated_at");

    isDownloadDisabled = modified_file_status !== FILE_STATUS.COMPLETED;
  } else if (isActiveTab === "staging") {
    const enhancement_files = filter(modified_files, (file) => {
      if (!fileHasEnhancementAction(file)) {
        return false;
      }
      return !!find(get(file, "modified_files"), fileHasStagingAction);
    });

    // NOTE: THIS IS NOT A MAPPING OPERATION
    // THIS IS JUST A FOR LOOP
    map(enhancement_files, (enhancement_file) => {
      const staging_file = find(
        get(enhancement_file, "modified_files"),
        fileHasStagingAction
      );
      modified_file_status = get(staging_file, "status");
      keywords = get(staging_file, "metadata.keywords");
      room_type = get(staging_file, "metadata.room_type");
      modified_file_hid = get(staging_file, "hid");
      modified_url = fileIsCompletedOrApprovalOrRevisionState(staging_file)
        ? get(staging_file, "public_url")
        : public_url;

      lastTouchedAt =
        get(staging_file, "metadata.last_touched_at") ||
        get(staging_file, "updated_at");

      statusTitle = getFileStatusText(modified_url, modified_file_status);

      image_url = modified_url;
    });

    isDownloadDisabled = modified_file_status !== FILE_STATUS.COMPLETED;
  }

  return {
    hid,
    created_at,
    modified_file_status,
    statusTitle,
    file_name,
    image_url,
    public_url,
    keywords,
    room_type,
    lastTouchedAt,
    modified_file_hid,
    modified_url,
    isDownloadDisabled,
  };
};
