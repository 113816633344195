import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/stable";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { initSentry } from "./utils/sentry";
import { apolloClient } from "./utils/react-apollo";
import { ThemeProvider } from "./contexts/ThemeContext";
import { reportWebVitals } from "./utils/reportWebVitals";

initSentry();

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
