import React from "react";
import styled from "styled-components/macro";
import { Checkbox } from "@mui/material";

const CheckboxIcon = styled("span")`
  border-radius: 3px;
  width: 16px;
  height: 16px;
  background-color: white;
".Mui-focusVisible &": {
  outline: 2px auto ${(props) => props.theme.palette.primary.main};
  outline-offset: 2px;
},
"input:hover ~ &": {
  background-color: #ebf1f5;
},
`;

const CheckedIcon = styled(CheckboxIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z" fill="%23fff"/%3E%3C/svg%3E')`,
    content: "no-close-quote",
  },
}));

function WhiteCheckbox({ checked, onChange, ...rest }) {
  return (
    <Checkbox
      {...rest}
      checkedIcon={<CheckedIcon />}
      icon={<CheckboxIcon />}
      checked={checked}
      onChange={onChange}
    />
  );
}

export default WhiteCheckbox;
