import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress as MuiLinearProgress,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { makeStyles } from "@mui/styles";

const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Paper = styled(MuiPaper)`
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;
const TypographyWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
const useStyles = makeStyles({
  scrollPaper: {
    marginRight: "30px",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});

const UploadingBarModal = ({
  open,
  hasUploadingBar,
  uploadingBarPercent,
  totalFileSize,
  uploadedFileSize,
}) => {
  const classes = useStyles();
  const fileSizeUploaded = () => (uploadedFileSize / (1024 * 1024)).toFixed(2);
  const fileSizeCalculator = () => (totalFileSize / (1024 * 1024)).toFixed(2);

  return (
    <Dialog
      classes={{ scrollPaper: classes.scrollPaper }}
      fullWidth
      open={open}
    >
      <DialogContent>
        {hasUploadingBar && (
          <Paper>
            <TypographyWrapper>
              <Typography>
                {fileSizeUploaded()} of {fileSizeCalculator()} MB
              </Typography>
              <Typography textAlign={"right"}>
                {uploadingBarPercent > 0 ? "Uploading... " : " "}
                {uploadingBarPercent}%
              </Typography>
            </TypographyWrapper>
            <LinearProgress
              my={2}
              variant="determinate"
              value={uploadingBarPercent}
              color="secondary"
            />
          </Paper>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UploadingBarModal;
