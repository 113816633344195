import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import styled from "styled-components/macro";

const Icon = styled(HighlightOffIcon)`
  display: flex;
  margin: 0 auto;
  font-size: 56px;
  color: ${(props) => props.theme.palette.secondary.main};
  margin-bottom: 12px;
`;

const ContentText = styled(DialogContentText)`
  font-size: 15px;
`;

const ConfirmDeleteModal = ({
  tabValue,
  open,
  handleClose,
  deleteFiles,
  deleteFile,
  deleteFileId,
}) => {
  const returnDescription = () => {
    switch (tabValue) {
      case "enhancement":
        return "Are you sure you want to delete the selected photos?";
      case "staging":
        return "Are you sure you want to delete the selected photos?";
      default:
        return "Deleting an original photo removes all the processed versions of the image. Do you want to continue?";
    }
  };

  const handleDeleteFiles = () => {
    deleteFileId ? deleteFile(deleteFileId) : deleteFiles();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Icon />
        <ContentText id="alert-dialog-description" textAlign={"center"}>
          {returnDescription()}
        </ContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteFiles} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
