import React from "react";
import {
  Alert,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../utils/axios";

export default function AddUserModal({ open, setOpen }) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t("validation.invalid_email"))
        .max(255)
        .required(t("validation.enter_your_email")),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const { email } = values;
        await axios.post(`/organizations/invite`, { email }).then(() => {
          setStatus({ sent: true });
        });
      } catch (error) {
        const message = error.response.data.message || "Something went wrong";
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    isValid,
    handleChange,
    values,
    resetForm,
    handleSubmit,
    setStatus,
    setErrors,
    status,
  } = formik;

  const handleCloseAndResetForm = () => {
    setOpen(false);
    resetForm();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={() => handleCloseAndResetForm(false)}
    >
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        {status && status.sent && (
          <Alert
            severity="success"
            my={3}
            onClose={() => setStatus({ sent: false })}
          >
            Invite sent!
          </Alert>
        )}

        {errors.submit && (
          <Alert my={3} severity="warning" onClose={() => setErrors({})}>
            {errors.submit}
          </Alert>
        )}

        <TextField
          name={"email"}
          value={values.email}
          onChange={handleChange}
          margin="dense"
          label="E-mail Address"
          fullWidth
          variant="standard"
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseAndResetForm(false)}>
          {t("close")}
        </Button>
        <Button
          disabled={!isValid}
          variant={"contained"}
          onClick={handleSubmit}
        >
          {t("send_invite")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
