import {
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from "react-router-dom";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    environment: process.env.REACT_APP_ENV || "unknown",
    tracesSampleRate: 0.2,
  });
};
