/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import Axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  map,
  get,
  find,
  filter,
  compact,
  forEach,
  identity,
  includes,
} from "lodash";

import {
  Tab,
  Grid,
  Menu,
  Link,
  Alert,
  Button,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box as MuiBox,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
} from "@mui/material";
import { spacing } from "@mui/system";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";

import {
  fileIsCompleted,
  fileHasStagingAction,
  fileHasEnhancementAction,
  fileIsCompletedOrApprovalOrRevisionState,
  fileIsCompletedOrApprovalState,
  getFileInformation,
} from "../utils/fileFilteringHelper";
import axios from "../utils/axios";
import { FILE_STATUS } from "../constants";

import useAuth from "../hooks/useAuth";
import Loader from "../components/Loader";
import InfoModal from "../components/InfoModal";
import ImageCard from "../components/ImageCard";
import ShareLinkModal from "../components/shareLinkModal";
import ImagePreviewModal from "../components/ImagePreviewModal";
import OriginalImageCard from "../components/OriginalImageCard";
import CompareModal from "../components/compare-modal/CompareModal";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import ProjectDetailListViewTable from "../components/ProjectDetailListViewTable";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Box = styled(MuiBox)`
  ${spacing};
  text-align: right;
  min-height: 45px;
`;

const ViewButtonBox = styled(MuiBox)`
  position: absolute;
  top: 5px;
  right: 0;
  @media (max-width: 555px) {
    top: -106px;
  }
`;

const TabValueEnum = {
  ORIGINAL: "original",
  ENHANCEMENT: "enhancement",
  STAGING: "staging",
  VIDEO: "video",
};

// For Cortland
const ORGANIZATION_HID = "rVaMWwJ5gO4Gmg5v";

function ProjectDetail() {
  const { t } = useTranslation();
  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { organization } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [checkedFiles, setCheckedFiles] = useState([]);
  const [checkedModifiedFiles, setCheckedModifiedFiles] = useState([]);

  const [files, setFiles] = useState([]);
  const [projectDetail, setProjectDetail] = useState({});

  const [actionsMenu, setActionsMenu] = useState(null);

  const defaultActiveTab = searchParams.get("tab") || TabValueEnum.ENHANCEMENT;
  const [tabValue, setTabValue] = useState(defaultActiveTab);
  const [activeView, setActiveView] = useState("card");

  const [deleteFailedModal, setDeleteFailedModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
  const [shareLinkModal, setShareLinkModal] = useState(false);
  const [compareModal, setCompareModal] = useState({
    open: false,
    id: null,
    activeTab: defaultActiveTab,
  });
  const [imagePreviewModal, setImagePreviewModal] = useState({
    open: false,
    id: null,
  });
  const [compareModalFile, setCompareModalFile] = useState(null);
  const [imagePreviewModalFile, setImagePreviewModalFile] = useState(null);

  const [selectAllCheckboxDisabled, setSelectAllCheckboxDisabled] =
    useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [showVideoTab, setShowVideoTab] = useState(false);
  const [videos, setVideos] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");

  const getProjectDetail = (id) => {
    axios.get(`/folders/${id}`).then(({ data }) => {
      setProjectDetail(data.data);
    });
  };

  const getFiles = (id, withLoading = true) => {
    withLoading && setLoading(true);
    const urlParams = new URLSearchParams();
    urlParams.append("folder_id", id);
    urlParams.append("sort", `${orderBy}.${order.toUpperCase()}`);

    if (tabValue === TabValueEnum.ENHANCEMENT) {
      urlParams.append("include", "modified_files");
      urlParams.append("metadata.actions", TabValueEnum.ENHANCEMENT);
    } else if (tabValue === TabValueEnum.STAGING) {
      urlParams.append(
        "include",
        `modified_files,modified_files.modified_files`
      );
      urlParams.append("metadata.actions", TabValueEnum.STAGING);
    }

    axios.get(`/files?${urlParams.toString()}`).then(({ data }) => {
      setFiles(data);
      withLoading && setLoading(false);

      if (tabValue !== TabValueEnum.STAGING) {
        const notCompleted = data?.filter((f) => {
          const enhancementStatus = map(get(f, "modified_files"), (file) => {
            if (fileHasEnhancementAction(file)) return file.status;
          });
          return compact(enhancementStatus).find(
            (status) => status !== FILE_STATUS.COMPLETED
          );
        });

        setSelectAllCheckboxDisabled(data?.length === notCompleted?.length);
      } else {
        const filesStatus = [];
        let notCompletedCount = 0;

        data.forEach((file) => {
          get(file, "modified_files").forEach((f) => {
            if (!fileHasEnhancementAction(f)) return false;
            const stagingFile = find(
              get(f, "modified_files"),
              fileHasStagingAction
            );

            if (!fileIsCompletedOrApprovalOrRevisionState(stagingFile)) {
              notCompletedCount++;
            }

            filesStatus.push(get(stagingFile, "status"));
          });
        });

        setSelectAllCheckboxDisabled(filesStatus.length === notCompletedCount);
      }
    });
  };

  const getVideos = (id) => {
    setLoading(true);
    axios.get(`/files?folder_id=${id}&file_type=video`).then(({ data }) => {
      setVideos(data);
      setLoading(false);
    });
  };

  const handleDeleteModal = () => {
    if (compact(checkedFiles).length > 0) {
      if (tabValue === TabValueEnum.STAGING) {
        let isAllCompleted = true;
        const checkedFileMapper = (file) => {
          return find(checkedFiles, (f) => {
            return f === file.hid;
          });
        };
        const fileUrlList = filter(files, checkedFileMapper);
        map(fileUrlList, (file) => {
          const enhancementFileFilter = (file) => {
            if (!fileHasEnhancementAction(file)) {
              return false;
            }
            return !!find(get(file, "modified_files"), fileHasStagingAction);
          };
          const enhancement_file = find(
            get(file, "modified_files"),
            enhancementFileFilter
          );
          const staging_file = find(
            get(enhancement_file, "modified_files"),
            fileHasStagingAction
          );
          if (!fileIsCompleted(staging_file)) {
            isAllCompleted = false;
          }
        });
        isAllCompleted
          ? setDeleteModal({ open: true, id: null })
          : setDeleteFailedModal(true);
      } else if (tabValue === TabValueEnum.ORIGINAL) {
        axios
          .post(`/folders/${id}/bulk-delete`, {
            ids: checkedFiles,
            dry_run: true,
          })
          .then(() => {
            setDeleteModal({ open: true, id: null });
          })
          .catch((err) => {
            if (err.response.data.status_code === 400) {
              setDeleteFailedModal(true);
            }
          });
      } else setDeleteModal({ open: true, id: null });
    }
  };

  const deleteFiles = () => {
    let ids;
    if (tabValue === TabValueEnum.ORIGINAL) {
      ids = checkedFiles;
    } else if (
      tabValue === TabValueEnum.ENHANCEMENT ||
      tabValue === TabValueEnum.STAGING
    ) {
      ids = compact(checkedModifiedFiles);
    }
    if (ids.length === 0) return;
    axios
      .post(`/folders/${id}/bulk-delete`, { ids })
      .then(() => {
        setDeleteModal({ open: false, id: null });
        getFiles(id, false);
      })
      .catch((err) => {
        if (err.response.data.status_code === 400) {
          setDeleteModal({ open: false, id: null });
          setDeleteFailedModal(true);
        }
      });
  };

  const deleteFile = (fileId) => {
    axios
      .post(`/folders/${id}/bulk-delete`, { ids: [fileId] })
      .then(() => {
        setDeleteModal({ open: false, id: null });
        getFiles(id, false);
      })
      .catch((err) => {
        if (err.response.data.status_code === 400) {
          setDeleteModal({ open: false, id: null });
          setDeleteFailedModal(true);
        }
      });
  };

  const handleOriginalFileDeleteModal = (fileId) => {
    axios
      .post(`/folders/${id}/bulk-delete`, {
        ids: [fileId],
        dry_run: true,
      })
      .then(() => {
        setDeleteModal({ open: true, id: null });
      })
      .catch((err) => {
        if (err.response.data.status_code === 400) {
          setDeleteFailedModal(true);
        }
      });
  };

  const downloadAllFiles = async () => {
    setIsDownloading(true);
    setActionsMenu(null);

    const checkedFileMapper = (file) => {
      return find(checkedFiles, (f) => {
        return f === file.hid;
      });
    };

    const fileUrlList = filter(files, checkedFileMapper);
    await downloadFilesHandler(fileUrlList);
  };

  const downloadFile = async (id) => {
    const fileMapper = (file) => {
      return id === file.hid;
    };
    const fileUrlList = filter(files, fileMapper);
    await downloadFilesHandler(fileUrlList);
  };

  const downloadFilesHandler = async (fileUrlList) => {
    if (!fileUrlList.length) return;

    const currentDate = moment().format("DD-MM-YYYY-HH-mm-ss");
    const fileName = `${projectDetail.attributes?.slug}-${tabValue}-${currentDate}.zip`;

    const imageUrlMapper = (imageItem) => {
      if (tabValue === TabValueEnum.ORIGINAL) {
        return imageItem;
      } else if (tabValue === TabValueEnum.STAGING) {
        const modified_files = get(imageItem, "modified_files");
        const enhancement_file = find(modified_files, (file) => {
          if (!fileHasEnhancementAction(file)) {
            return false;
          }

          return !!find(get(file, "modified_files"), fileHasStagingAction);
        });

        const staging_file = find(
          get(enhancement_file, "modified_files"),
          fileHasStagingAction
        );
        if (!fileIsCompletedOrApprovalState(staging_file)) return;
        return staging_file;
      } else {
        const modified_files = get(imageItem, "modified_files");
        const enhancement_file = find(modified_files, (file) => {
          if (
            includes(get(file, "metadata.actions"), TabValueEnum.ENHANCEMENT)
          ) {
            return file;
          }
        });
        if (
          !enhancement_file ||
          get(enhancement_file, "status") !== FILE_STATUS.COMPLETED
        )
          return;
        return enhancement_file;
      }
    };
    const processedImages = filter(map(fileUrlList, imageUrlMapper), identity);
    if (!processedImages.length) return;

    const imageContentMapper = (foundFile) => {
      const url = get(foundFile, "public_url");
      const fileName = get(foundFile, "file_name");

      return new Promise((resolve, reject) =>
        Axios.get(url, {
          responseType: "arraybuffer",
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
            Accept: "application/jpeg",
            "Content-Type": "application/json",
          },
        })
          .then((response) => resolve({ fileName, content: response.data }))
          .catch(reject)
      );
    };

    const imageContents = await Promise.all(
      map(processedImages, imageContentMapper)
    );

    const jsZip = new JSZip();

    forEach(imageContents, (result) =>
      jsZip.file(
        result.fileName?.replace("_enhancement_staging", "_staging"),
        new Blob([result.content])
      )
    );

    const zipFile = await jsZip.generateAsync({ type: "blob" });
    setIsDownloading(false);
    return saveAs(zipFile, fileName);
  };

  const handleCheckboxChange = (hid) => {
    const file = find(files, { hid });

    let enhancedFile, stagingFile;
    if (tabValue === TabValueEnum.ENHANCEMENT) {
      const modified_files = get(file, "modified_files");
      enhancedFile = find(modified_files, fileHasEnhancementAction);
    } else if (tabValue === TabValueEnum.STAGING) {
      const enhancementFileFilter = (file) => {
        if (!fileHasEnhancementAction(file)) {
          return false;
        }
        return !!find(get(file, "modified_files"), fileHasStagingAction);
      };
      enhancedFile = find(get(file, "modified_files"), enhancementFileFilter);
      stagingFile = find(
        get(enhancedFile, "modified_files"),
        fileHasStagingAction
      );
    }

    const modifiedFile =
      tabValue === TabValueEnum.ENHANCEMENT ? enhancedFile : stagingFile;

    const isChecked = includes(checkedFiles, hid);
    if (isChecked) {
      setCheckedFiles(filter(checkedFiles, (f) => f !== hid));
      tabValue !== TabValueEnum.ORIGINAL &&
        setCheckedModifiedFiles(
          filter(checkedModifiedFiles, (f) => f !== modifiedFile.hid)
        );
    } else {
      setCheckedFiles(checkedFiles.concat(hid));
      tabValue !== TabValueEnum.ORIGINAL &&
        setCheckedModifiedFiles(checkedModifiedFiles.concat(modifiedFile.hid));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    if (!isChecked) {
      setCheckedFiles([]);
      setCheckedModifiedFiles([]);
      return;
    }

    if (tabValue === TabValueEnum.ORIGINAL) {
      setCheckedFiles(map(files, "hid"));
      setCheckedModifiedFiles(map(files, "hid"));
    } else if (tabValue === TabValueEnum.STAGING) {
      const mappedFiles = map(files, (file) => {
        const enhancement_file = find(get(file, "modified_files"), (f) => {
          return !fileHasEnhancementAction(f)
            ? false
            : !!find(get(f, "modified_files"), fileHasStagingAction);
        });

        const staging_file = find(
          get(enhancement_file, "modified_files"),
          fileHasStagingAction
        );

        if (!fileIsCompletedOrApprovalState(staging_file)) return false;
        return {
          originalFileHid: file.hid,
          stagingFileHid: staging_file.hid,
        };
      });
      setCheckedFiles(map(mappedFiles, "originalFileHid"));
      setCheckedModifiedFiles(map(mappedFiles, "stagingFileHid"));
    } else {
      const mappedFiles = map(files, (file) => {
        const enhancement_file = find(get(file, "modified_files"), (file) => {
          if (fileHasEnhancementAction(file)) return file;
        });

        if (!enhancement_file || !fileIsCompleted(enhancement_file)) {
          return null;
        }

        return {
          originalFileHid: file.hid,
          enhancementFileHid: enhancement_file.hid,
        };
      });

      setCheckedFiles(map(mappedFiles, "originalFileHid"));
      setCheckedModifiedFiles(map(mappedFiles, "enhancementFileHid"));
    }
  };

  const updateFileName = (name, fileId) => {
    axios
      .patch(`/files/${fileId}`, {
        file_name: name,
      })
      .then(() => {
        getFiles(id, false);
      });
  };

  const onRoomTypeChange = async (e, fileId) => {
    e.stopPropagation();
    const { value } = e.target;
    if (!id) return;
    await axios
      .patch(`/files/${fileId}`, {
        metadata: {
          room_type: value,
        },
      })
      .then(() => {
        getFiles(id, false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
  };

  const handleSendToVirtualStaging = () => {
    const selectedFiles = filter(files, (file) => {
      return find(checkedFiles, (f) => f === file.hid);
    });

    const enhancementFiles = selectedFiles.map((f) => {
      return find(get(f, "modified_files"), (file) => {
        file.original_name = f.file_name;
        file.metadata.staging_style = "";
        return fileHasEnhancementAction(file);
      });
    });

    navigate("staging", {
      state: {
        projectTitle: projectDetail.attributes?.title,
        enhancementFiles,
      },
    });
  };

  const handleSendToEnhancementAndVirtualStaging = () => {
    const originalFiles = filter(files, (file) => {
      return find(checkedFiles, (f) => f === file.hid);
    });
    navigate("enhancement-staging", {
      state: {
        projectTitle: projectDetail.attributes?.title,
        originalFiles,
      },
    });
  };

  const handleAddNewPhotos = () => {
    navigate(`/dashboard/projects/${id}/add-new-photos`, {
      state: { folderId: id },
    });
  };

  useEffect(() => getProjectDetail(id), [id]);

  useEffect(() => {
    setFiles([]);
    setCheckedFiles([]);
    setCheckedModifiedFiles([]);
    setVideos([]);
    tabValue === TabValueEnum.VIDEO && getVideos(id);
  }, [tabValue, id]);

  useEffect(() => {
    getFiles(id);
  }, [order, orderBy, tabValue]);

  useEffect(() => {
    if (compareModal.open) {
      const { id } = compareModal;
      const file = find(files, { hid: id });
      if (file) {
        setCompareModalFile(file);
      }
    }
  }, [compareModal, files]);

  useEffect(() => {
    if (imagePreviewModal.open) {
      const { id } = imagePreviewModal;
      const file = find(files, { hid: id });
      if (file) {
        setImagePreviewModalFile(file);
      }
    }
  }, [imagePreviewModal]);

  useEffect(() => {
    setSuccessMessage(get(location, "state.message"));
  }, []);

  useEffect(() => {
    organization.id === ORGANIZATION_HID && setShowVideoTab(true);
  }, [organization]);

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setTabValue(TabValueEnum.ENHANCEMENT);
      searchParams.set("tab", TabValueEnum.ENHANCEMENT);
      setSearchParams(searchParams);
    }
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const returnOriginalTabValue = () => {
    return (
      <React.Fragment>
        {activeView === "card" ? (
          <Grid container spacing={6}>
            {files?.length !== 0 &&
              !loading &&
              files?.map((f, i) => {
                const { file_name, public_url, hid } = f;
                return (
                  <Grid key={i} item xs={12} sm={4} lg={4}>
                    <OriginalImageCard
                      id={hid}
                      image={public_url}
                      imageName={file_name}
                      checkedFiles={checkedFiles}
                      handleOriginalFileDeleteModal={
                        handleOriginalFileDeleteModal
                      }
                      setImagePreviewModal={setImagePreviewModal}
                      downloadFile={downloadFile}
                      updateFileName={updateFileName}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  </Grid>
                );
              })}
            {files?.length === 0 && loading && (
              <Grid item xs={12}>
                <Loader loading={loading} />
              </Grid>
            )}
            {files?.length === 0 && !loading && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  textAlign={"center"}
                >
                  {t("project_detail.no_files_found")}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <ProjectDetailListViewTable
            files={files}
            loading={loading}
            order={order}
            orderBy={orderBy}
            isActiveTab={tabValue}
            handleRequestSort={handleRequestSort}
            checkedFiles={checkedFiles}
            setImagePreviewModal={setImagePreviewModal}
            downloadFile={downloadFile}
            handleCheckboxChange={handleCheckboxChange}
            updateFileName={updateFileName}
            onRoomTypeChange={onRoomTypeChange}
            handleSelectAll={handleSelectAll}
          />
        )}

        <ImagePreviewModal
          files={files}
          imagePreviewModal={imagePreviewModal}
          imagePreviewModalFile={imagePreviewModalFile}
          setImagePreviewModal={setImagePreviewModal}
        />
      </React.Fragment>
    );
  };

  const returnEnhancementTabValue = () => {
    return (
      <React.Fragment>
        {activeView === "card" ? (
          <Grid container spacing={6}>
            {files?.length !== 0 &&
              !loading &&
              files?.map((f, i) => {
                const {
                  file_name,
                  public_url,
                  hid,
                  modified_file_status,
                  modified_url,
                } = getFileInformation("enhancement", f);

                return (
                  <Grid item xs={12} sm={4} lg={4} key={i}>
                    <ImageCard
                      index={i}
                      id={hid}
                      tabValue={tabValue}
                      imageName={file_name}
                      leftImage={
                        modified_file_status === FILE_STATUS.COMPLETED
                          ? modified_url
                          : public_url
                      }
                      rightImage={public_url}
                      checkedFiles={checkedFiles}
                      status={modified_file_status}
                      setDeleteModal={setDeleteModal}
                      setCompareModal={setCompareModal}
                      downloadFile={downloadFile}
                      updateFileName={updateFileName}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  </Grid>
                );
              })}
            {files?.length === 0 && loading && (
              <Grid item xs={12}>
                <Loader loading={loading} />
              </Grid>
            )}
            {files?.length === 0 && !loading && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  textAlign={"center"}
                >
                  {t("project_detail.no_files_found")}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <ProjectDetailListViewTable
            files={files}
            loading={loading}
            order={order}
            orderBy={orderBy}
            isActiveTab={tabValue}
            handleRequestSort={handleRequestSort}
            checkedFiles={checkedFiles}
            setCompareModal={setCompareModal}
            downloadFile={downloadFile}
            handleCheckboxChange={handleCheckboxChange}
            updateFileName={updateFileName}
            onRoomTypeChange={onRoomTypeChange}
            handleSelectAll={handleSelectAll}
          />
        )}
        <CompareModal
          files={files}
          compareModal={compareModal}
          compareModalFile={compareModalFile}
          setCompareModal={setCompareModal}
          hasComments={true}
        />
      </React.Fragment>
    );
  };

  const returnStagingTabValue = () => {
    return (
      <React.Fragment>
        {activeView === "card" ? (
          <Grid container spacing={6}>
            {files?.length !== 0 &&
              !loading &&
              files?.map((f, i) => {
                const { hid, file_name, public_url, modified_files } = f;

                const enhancement_files = filter(modified_files, (file) => {
                  if (!fileHasEnhancementAction(file)) {
                    return false;
                  }

                  return !!find(
                    get(file, "modified_files"),
                    fileHasStagingAction
                  );
                });
                return map(enhancement_files, (enhancement_file) => {
                  const staging_file = find(
                    get(enhancement_file, "modified_files"),
                    fileHasStagingAction
                  );

                  let modified_url = fileIsCompletedOrApprovalOrRevisionState(
                    staging_file
                  )
                    ? get(staging_file, "public_url")
                    : public_url;

                  const lastTouchedAt =
                    get(staging_file, "metadata.last_touched_at") ||
                    get(staging_file, "updated_at");

                  return (
                    <Grid item xs={12} sm={4} lg={4} key={staging_file.hid}>
                      <ImageCard
                        index={i}
                        id={hid}
                        tabValue={tabValue}
                        imageName={file_name}
                        rightImage={public_url}
                        leftImage={modified_url}
                        checkedFiles={checkedFiles}
                        setDeleteModal={setDeleteModal}
                        setCompareModal={setCompareModal}
                        status={get(staging_file, "status")}
                        downloadFile={downloadFile}
                        updateFileName={updateFileName}
                        handleCheckboxChange={handleCheckboxChange}
                      />
                    </Grid>
                  );
                });
              })}
            {files?.length === 0 && loading && (
              <Grid item xs={12}>
                <Loader loading={loading} />
              </Grid>
            )}
            {files?.length === 0 && !loading && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  textAlign={"center"}
                >
                  {projectDetail.attributes?.has_staging_files
                    ? t("project_detail.photos_being_processed")
                    : t("project_detail.no_files_found")}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <ProjectDetailListViewTable
            files={files}
            loading={loading}
            order={order}
            orderBy={orderBy}
            isActiveTab={tabValue}
            projectDetail={projectDetail}
            checkedFiles={checkedFiles}
            setCompareModal={setCompareModal}
            downloadFile={downloadFile}
            handleRequestSort={handleRequestSort}
            handleCheckboxChange={handleCheckboxChange}
            updateFileName={updateFileName}
            onRoomTypeChange={onRoomTypeChange}
            handleSelectAll={handleSelectAll}
          />
        )}
        <CompareModal
          hasComments={true}
          files={files}
          compareModal={compareModal}
          compareModalFile={compareModalFile}
          setCompareModal={setCompareModal}
          getFiles={getFiles}
        />
      </React.Fragment>
    );
  };

  const returnVideoTabValue = () => {
    return (
      <React.Fragment>
        <Grid container spacing={6}>
          {videos?.length !== 0 &&
            !loading &&
            videos?.map((f, i) => {
              const { file_name, public_url, hid } = f;
              return (
                <Grid item key={i} xs={12} sm={4} lg={4} name={"card"}>
                  <OriginalImageCard
                    imageName={file_name}
                    id={hid}
                    video={public_url}
                    handleCheckboxChange={handleCheckboxChange}
                    checkedFiles={checkedFiles}
                    setImagePreviewModal={setImagePreviewModal}
                    updateFileName={updateFileName}
                    downloadFile={downloadFile}
                  />
                </Grid>
              );
            })}
          {videos?.length === 0 && loading && (
            <Grid item xs={12}>
              <Loader loading={loading} />
            </Grid>
          )}
          {videos?.length === 0 && !loading && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                textAlign={"center"}
              >
                {t("project_detail.no_files_found")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Helmet title={t("project_detail.title")} />
      <Typography variant="h3" gutterBottom display="inline">
        {projectDetail.attributes?.title}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography> {t("dashboard")}</Typography>
        <Link component={NavLink} to="/dashboard/projects">
          {t("projects")}
        </Link>
        <Typography>{projectDetail.attributes?.title}</Typography>
        <Typography>
          {projectDetail.attributes?.metadata?.category}
          {projectDetail.attributes?.metadata?.category_value
            ? ` - ${projectDetail.attributes?.metadata?.category_value}`
            : ""}
        </Typography>
      </Breadcrumbs>
      <Box sx={{ width: "100%", typography: "body1" }}>
        {successMessage && (
          <Box my={3}>
            <Alert my={3} severity="success">
              {successMessage}
            </Alert>
          </Box>
        )}
        {tabValue !== TabValueEnum.VIDEO ? (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    files?.length !== 0 && files?.length === checkedFiles.length
                  }
                  disabled={selectAllCheckboxDisabled}
                  onChange={(e) => handleSelectAll(e)}
                  name="selectAll"
                />
              }
              label="Select All"
            />
            <Button
              disabled={isDownloading}
              aria-owns={actionsMenu ? "actions-menu" : undefined}
              aria-haspopup="true"
              onClick={(event) => setActionsMenu(event.currentTarget)}
              variant="contained"
              color="secondary"
            >
              Actions&nbsp;
              {isDownloading ? (
                <Loader color="inherit" size="20px" />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={actionsMenu}
              open={Boolean(actionsMenu)}
              onClose={() => setActionsMenu(null)}
            >
              <MenuItem
                disabled={!checkedFiles.length}
                onClick={() => downloadAllFiles()}
              >
                {t("project_detail.download_selected")}
              </MenuItem>
              <MenuItem
                disabled={!checkedFiles.length}
                onClick={() => setShareLinkModal(true)}
              >
                {t("project_detail.share_selected")}
              </MenuItem>
              {tabValue === TabValueEnum.ENHANCEMENT && (
                <MenuItem
                  disabled={!checkedFiles.length}
                  onClick={() => handleSendToVirtualStaging()}
                >
                  {t("project_detail.send_to_virtual_staging")}
                </MenuItem>
              )}
              {tabValue === TabValueEnum.ORIGINAL && (
                <MenuItem
                  disabled={!checkedFiles.length}
                  onClick={() => handleSendToEnhancementAndVirtualStaging()}
                >
                  {t("project_detail.send_to_enhancement_and_staging")}
                </MenuItem>
              )}
              <MenuItem
                disabled={!checkedFiles.length}
                onClick={() => handleDeleteModal()}
              >
                Delete Selected
              </MenuItem>
              <MenuItem onClick={() => handleAddNewPhotos()}>
                {t("project_detail.add_photos")}
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <Box />
        )}

        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "relative",
            }}
            mb={2}
          >
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Originals" value={TabValueEnum.ORIGINAL} />
              <Tab label="Enhancement" value={TabValueEnum.ENHANCEMENT} />
              <Tab label="Virtual Staging" value={TabValueEnum.STAGING} />
              {showVideoTab && (
                <Tab label="Videos" value={TabValueEnum.VIDEO} />
              )}
            </TabList>
            {tabValue !== TabValueEnum.VIDEO && (
              <ViewButtonBox>
                <Button
                  onClick={() =>
                    activeView === "list"
                      ? setActiveView("card")
                      : setActiveView("list")
                  }
                >
                  {activeView === "list" ? <ViewListIcon /> : <ViewComfyIcon />}
                </Button>
              </ViewButtonBox>
            )}
          </Box>
          <TabPanel value={TabValueEnum.ORIGINAL}>
            {returnOriginalTabValue()}
          </TabPanel>
          <TabPanel value={TabValueEnum.ENHANCEMENT}>
            {returnEnhancementTabValue()}
          </TabPanel>
          <TabPanel value={TabValueEnum.STAGING}>
            {returnStagingTabValue()}
          </TabPanel>
          <TabPanel value={TabValueEnum.VIDEO}>
            {returnVideoTabValue()}
          </TabPanel>
        </TabContext>
      </Box>

      <ShareLinkModal
        projectTitle={projectDetail.attributes?.title}
        open={shareLinkModal}
        setShareLinkModal={setShareLinkModal}
        checkedFiles={
          tabValue === TabValueEnum.ORIGINAL
            ? checkedFiles
            : checkedModifiedFiles
        }
      />

      <ConfirmDeleteModal
        tabValue={tabValue}
        open={deleteModal.open}
        handleClose={() => setDeleteModal({ open: false, id: null })}
        deleteFiles={deleteFiles}
        deleteFile={deleteFile}
        deleteFileId={deleteModal.id}
      />

      <InfoModal
        open={deleteFailedModal}
        handleClose={() => setDeleteFailedModal(false)}
        description={
          tabValue === TabValueEnum.STAGING
            ? t("project_detail.staging_delete_failed")
            : t("project_detail.delete_failed")
        }
      />
    </React.Fragment>
  );
}

export default ProjectDetail;
