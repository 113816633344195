/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import JSZip from "jszip";
import moment from "moment";
import saveAs from "file-saver";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import {
  get,
  map,
  find,
  size,
  last,
  head,
  split,
  filter,
  forEach,
  includes,
} from "lodash";
import styled from "styled-components/macro";

import {
  Box,
  Chip,
  Grid,
  Menu,
  Table,
  Button,
  Tooltip,
  MenuItem,
  Checkbox,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  Autocomplete,
  TableContainer,
  TableSortLabel,
  FormHelperText,
  InputAdornment,
  FormControlLabel,
  Paper as MuiPaper,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  Add as AddIcon,
  Edit as EditIcon,
  AddCircleOutline,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

import axios from "../utils/axios";
import dateFormatter from "../utils/dateFormatter";
import { fileIsCompletedOrApprovalState } from "../utils/fileFilteringHelper";

import Loader from "../components/Loader";
import RenameModal from "../components/RenameModal";
import ImageListCard from "../components/ImageListCard";
import ImagePreviewModal from "../components/ImagePreviewModal";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const StatusChip = styled(Chip)`
  width: max-content;
  right: 0;
  left: 10px;
  background-color: ${(props) =>
    props.theme.palette[get(props, "color")].light};
`;

const headCells = [
  {
    id: "slug",
    alignment: "left",
    label: "Project Name",
  },
  {
    id: "category",
    alignment: "left",
    label: "Category",
  },
  {
    id: "status",
    alignment: "right",
    label: "Status",
  },
  {
    id: "created_at",
    alignment: "right",
    label: "Date Uploaded",
  },
  {
    id: "file_count",
    alignment: "right",
    label: "Photos Count",
  },
];

const ProjectTableHead = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => () => onRequestSort(property);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={"normal"}
          >
            <TableSortLabel
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id, headCell.label)}
              active={orderBy === headCell.id}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align={"right"} padding={"normal"}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

function ProjectTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [order, setOrder] = useState("desc");
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("created_at");
  const [renameModal, setRenameModal] = useState({
    open: false,
    title: "",
    id: null,
  });

  useEffect(() => {
    getProjects();
  }, [order, orderBy]);

  const getProjects = () => {
    setLoading(true);
    const urlParams = new URLSearchParams();
    urlParams.append("sort", `${orderBy}.${order.toUpperCase()}`);
    axios.get(`/folders?${urlParams}`).then(({ data }) => {
      setProjects(data.data);
      setLoading(false);
    });
  };

  const updateFolderName = (title, fileId) => {
    axios.patch(`/folders/${fileId}`, { title }).then(() => {
      getProjects();
    });
  };

  const handleRename = (event, title, id) => {
    event.stopPropagation();
    setRenameModal({
      open: true,
      title,
      id,
    });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows = 10 - Math.min(10, projects.length);

  const handleAddNewPhotos = (e, id) => {
    e.stopPropagation();
    navigate(`${id}/add-new-photos`, { state: { folderId: id } });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Paper>
        <TableContainer>
          <Table size={"medium"}>
            <ProjectTableHead
              rowCount={projects.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {projects.map((row, index) => {
                const { attributes, id } = row;
                const { title, file_count, status, metadata, created_at } =
                  attributes;
                const { category, category_value } = metadata || {};

                return (
                  <TableRow
                    style={{ cursor: "pointer" }}
                    hover
                    tabIndex={-1}
                    key={`${id}-${index}`}
                    onClick={() => {
                      navigate(`${id}`);
                    }}
                  >
                    <TableCell>{title}</TableCell>
                    <TableCell>
                      {category ? `${category}` : "-"}{" "}
                      {category_value ? `${category_value}` : ""}
                    </TableCell>
                    <TableCell align="right">
                      <StatusChip
                        size="small"
                        mr={1}
                        mb={1}
                        label={status === 6 ? "Completed" : "Processing"}
                        color={status === 6 ? "success" : "warning"}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {dateFormatter(created_at)}
                    </TableCell>
                    <TableCell align="right">{file_count}</TableCell>
                    <TableCell padding="none" align="right">
                      <Box mr={2}>
                        <Tooltip title={t("project.add_new_photos")}>
                          <IconButton
                            aria-label="add"
                            size="large"
                            onClick={(e) => handleAddNewPhotos(e, id)}
                          >
                            <AddCircleOutline />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("project.rename")}>
                          <IconButton
                            aria-label="delete"
                            size="large"
                            onClick={(e) => handleRename(e, title, id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <RenameModal
        renameModal={renameModal}
        setRenameModal={setRenameModal}
        updateName={updateFolderName}
      />
    </>
  );
}

function Projects() {
  const { t } = useTranslation();
  const firstRender = useRef(true);
  const [loading, setLoading] = useState(false);
  const [checkedFiles, setCheckedFiles] = useState([]);
  const [actionsMenu, setActionsMenu] = useState(null);
  const [searchParams, setSearchParams] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [imagePreviewModalFile, setImagePreviewModalFile] = useState(null);
  const [imagePreviewModal, setImagePreviewModal] = useState({
    open: false,
    id: null,
  });

  const PaperText = styled(Paper)(() => ({
    height: 60,
    textAlign: "center",
    lineHeight: "60px",
  }));

  useEffect(() => {
    if (imagePreviewModal) {
      const { id } = imagePreviewModal;
      const file = find(searchResults, { hid: id });
      if (file) {
        setImagePreviewModalFile(file);
      }
    }
  }, [imagePreviewModal]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      getSearchResults();
    }
  }, [searchParams]);

  useEffect(() => {
    setCheckedFiles([]);
  }, [searchResults]);

  const getSearchResults = (withLoading = true) => {
    withLoading && setLoading(true);
    const urlParams = new URLSearchParams();
    if (searchParams.length === 0) {
      withLoading && setLoading(false);
      setSearchResults([]);
      return;
    }
    urlParams.append("include", "original_file,folder");
    searchParams.map((param) => {
      if (
        param.toLowerCase() === "virtual" ||
        param.toLowerCase() === "virtual staging"
      ) {
        urlParams.append(`q[]`, "staging");
      } else urlParams.append(`q[]`, param);
    });
    axios.get(`/files?${urlParams}`).then(({ data }) => {
      setSearchResults(data);
      withLoading && setLoading(false);
    });
  };

  const updateFileName = (name, fileId) => {
    axios
      .patch(`/files/${fileId}`, {
        file_name: name,
      })
      .then(() => {
        getSearchResults(false);
      });
  };

  const downloadFile = async (id) => {
    const fileMapper = (file) => {
      return id === file.hid;
    };
    const fileUrlList = filter(searchResults, fileMapper);
    await downloadFilesHandler(fileUrlList);
  };

  const downloadAllFiles = async () => {
    const checkedFileMapper = (file) => {
      return find(checkedFiles, (f) => {
        return f === file.hid;
      });
    };

    const fileUrlList = filter(searchResults, checkedFileMapper);
    await downloadFilesHandler(fileUrlList);
  };

  const downloadFilesHandler = async (fileUrlList) => {
    if (!fileUrlList.length) return;
    const currentDate = moment().format("DD-MM-YYYY-HH-mm-ss");
    const fileName = `${currentDate}.zip`;

    const imageContentMapper = (foundFile) => {
      const isOriginalImage = size(get(foundFile, "metadata.actions")) !== 1;
      const isEnhancedImage = includes(
        get(foundFile, "metadata.actions"),
        "enhancement"
      );

      const url = get(foundFile, "public_url");
      const fileNameWithExt = get(foundFile, "file_name");
      const project_name = get(foundFile, "folder.title");

      const ext = last(split(fileNameWithExt, "."));
      const fileName = head(split(fileNameWithExt, "."));

      let newFileName;
      if (isOriginalImage) {
        newFileName = `${fileName}_${project_name}.${ext}`;
      } else if (isEnhancedImage) {
        newFileName = `${fileName}_enhanced_${project_name}.${ext}`;
      } else newFileName = `${fileName}_staged_${project_name}.${ext}`;

      return new Promise((resolve, reject) =>
        Axios.get(url, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/jpeg",
            "Content-Type": "application/json",
          },
        })
          .then((response) =>
            resolve({ fileName: newFileName, content: response.data })
          )
          .catch(reject)
      );
    };

    const imageContents = await Promise.all(
      map(fileUrlList, imageContentMapper)
    );
    const jsZip = new JSZip();

    forEach(imageContents, (result) =>
      jsZip.file(result.fileName, new Blob([result.content]))
    );

    const zipFile = await jsZip.generateAsync({ type: "blob" });

    return saveAs(zipFile, fileName);
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const statusFilter = (file) => {
        const isOriginalImage = size(get(file, "metadata.actions")) !== 1;
        if (!fileIsCompletedOrApprovalState(file) && !isOriginalImage) {
          return false;
        }
        return get(file, "hid");
      };
      setCheckedFiles(map(searchResults, statusFilter));
    } else {
      setCheckedFiles([]);
    }
  };

  const handleCheckboxChange = (hid) => {
    const isChecked = includes(checkedFiles, hid);
    if (isChecked) {
      setCheckedFiles(filter(checkedFiles, (f) => f !== hid));
    } else {
      setCheckedFiles(checkedFiles.concat(hid));
    }
  };

  return (
    <React.Fragment>
      <Helmet title={t("projects")} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("projects")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("dashboard")}</Typography>
            <Typography> {t("projects")}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={"/dashboard/new-project"}
            >
              <AddIcon fontSize={"small"} />
              &nbsp;{t("new_project.title")}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} mb={6}>
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            onChange={(event, newValue) => {
              setSearchParams(newValue);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => {
              params.InputProps.startAdornment = (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              );
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={
                    searchParams?.length
                      ? ""
                      : "Bridge Apartment, fireplace, oven, balcony"
                  }
                />
              );
            }}
          />
          <FormHelperText>
            Press “enter/return” to start your search.
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        {searchResults?.length === 0 && loading && (
          <Grid item xs={12}>
            <Loader loading={loading} />
          </Grid>
        )}
        {searchResults?.length !== 0 && !loading && (
          <Grid sx={{ marginLeft: "auto" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    searchResults?.length !== 0 &&
                    searchResults?.length === checkedFiles.length
                  }
                  onChange={(e) => handleSelectAll(e)}
                  name="selectAll"
                />
              }
              label="Select All"
            />
            <Button
              aria-owns={actionsMenu ? "actions-menu" : undefined}
              aria-haspopup="true"
              onClick={(event) => setActionsMenu(event.currentTarget)}
              variant="contained"
              color="secondary"
            >
              Actions&nbsp;
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={actionsMenu}
              open={Boolean(actionsMenu)}
              onClose={() => setActionsMenu(null)}
            >
              <MenuItem
                disabled={!checkedFiles.length}
                onClick={() => downloadAllFiles()}
              >
                {t("project_detail.download_selected")}
              </MenuItem>
            </Menu>
          </Grid>
        )}
        {searchResults?.length !== 0 &&
          !loading &&
          searchResults.map((f, i) => {
            const { room_type, category, keywords, actions } = metadata;
            return (
              <Grid item xs={12} key={i}>
                <ImageListCard
                  id={hid}
                  actions={actions}
                  keywords={keywords}
                  imageName={file_name}
                  image={public_url}
                  status={status}
                  roomType={room_type}
                  category={category}
                  projectName={folder?.title}
                  projectId={folder?.hid}
                  checkedFiles={checkedFiles}
                  setImagePreviewModal={setImagePreviewModal}
                  updateFileName={updateFileName}
                  downloadFile={downloadFile}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </Grid>
            );
          })}
        {searchResults?.length === 0 && !loading && (
          <Grid item xs={12}>
            {searchParams.length && !searchResults.length ? (
              <Box>
                <PaperText>No results found</PaperText>
              </Box>
            ) : (
              <ProjectTable />
            )}
          </Grid>
        )}
      </Grid>
      <ImagePreviewModal
        files={searchResults}
        imagePreviewModal={imagePreviewModal}
        imagePreviewModalFile={imagePreviewModalFile}
        setImagePreviewModal={setImagePreviewModal}
      />
    </React.Fragment>
  );
}

export default Projects;
