import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

// noinspection JSCheckFunctionSignatures
export const typesenseAdapter = new TypesenseInstantsearchAdapter({
  server: {
    apiKey: "yEaxCQKsVTjq2YNtoNpT90v3dMLkY9Rn",
    nodes: [
      {
        host: "0793yd81a5efg2vqp-1.a1.typesense.net",
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 1,
    numRetries: 8,
  },
  additionalSearchParameters: {
    queryBy:
      "title,property_name,property_city,property_state_code,category,category_value,room_type,keywords",
    queryByWeights: "1,2,3,4,5,6,7,8",
    numTypos: 3,
    typoTokensThreshold: 1,
  },
});

export const searchClient = typesenseAdapter.searchClient;
