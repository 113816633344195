import React, { useState } from "react";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
} from "@mui/material";
import AddPropertyModal from "../components/AddPropertyModal";
import PropertiesList from "../components/PropertiesList";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Properties() {
  const { t } = useTranslation();
  const [addPropertyModalOpen, setAddPropertyModalOpen] = useState(false);

  return (
    <React.Fragment>
      <Helmet title={t("properties")} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("properties")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("dashboard")}</Typography>
            <Typography>{t("properties")}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddPropertyModalOpen(true)}
          >
            {t("auth.add_property")}
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PropertiesList />
        </Grid>
      </Grid>
      <AddPropertyModal
        open={addPropertyModalOpen}
        setOpen={setAddPropertyModalOpen}
      />
    </React.Fragment>
  );
}

export default Properties;
