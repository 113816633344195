import React from "react";
import styled from "styled-components/macro";

import { TextField, Autocomplete as MuiAutocomplete } from "@mui/material";

const Autocomplete = styled(MuiAutocomplete)`
  max-height: 90px;
  overflow-y: scroll;

  & .MuiAutocomplete-tag {
    height: 25px;
    color: #e06763;
    border-radius: 25px;
    background-color: #f3d1d1;
  }
`;

function Keyword({
  value,
  width,
  disabled,
  inputValue,
  setInputValue,
  onChange,
  handleKeyDown,
  handleClickOutside,
}) {
  return (
    <Autocomplete
      style={{ width: `${width}px` || "", maxWidth: `${width}px` || "" }}
      multiple
      freeSolo
      disabled={disabled}
      name="keywords"
      options={[]}
      value={value}
      inputValue={inputValue}
      onBlur={() => handleClickOutside()}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      onChange={(e, value) => onChange(e, value)}
      getOptionLabel={(option) => option}
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;
        return <TextField name="keywords" {...params} />;
      }}
    />
  );
}

export default Keyword;
