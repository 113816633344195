import React from "react";
import { Navigate, Route } from "react-router-dom";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import ShareableLinkLayout from "./layouts/ShareableLink";
import BlankLayout from "./layouts/Blank";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Page components
import Payment from "./pages/Payment";
import Projects from "./pages/Projects";
import MyAccount from "./pages/MyAccount";
import NewProject from "./pages/NewProject";
import ProjectDetail from "./pages/ProjectDetail";
import ShareableLink from "./pages/ShareableLink";
import EnhancementToStaging from "./pages/EnhancementToStaging";
import AddPaymentMethod from "./pages/AddPaymentMethod";
import Properties from "./pages/Properties";
import Library from "./pages/Library";
import PrivacyPolicy from "./pages/documentation/PrivacyPolicy";
import TermsOfService from "./pages/documentation/TermsOfService";
import OriginalToEnhancementAndStaging from "./pages/OriginalToEnhancementAndStaging";
import Iframe from "./pages/Iframe";

const AppRoutes = (
  <>
    <Route path={"/"} element={<Navigate to={"/dashboard/new-project"} />} />
    <Route
      path={"/dashboard"}
      element={<Navigate to={"/dashboard/new-project"} />}
    />
    <Route
      path="/dashboard"
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      <Route path="new-project" element={<NewProject />} />
      <Route path="projects" element={<Projects />} />
      <Route path="projects/:id" element={<ProjectDetail />} />
      <Route path="projects/:id/add-new-photos" element={<NewProject />} />
      <Route path="projects/:id/staging" element={<EnhancementToStaging />} />
      <Route
        path="projects/:id/enhancement-staging"
        element={<OriginalToEnhancementAndStaging />}
      />

      <Route path="my-account" element={<MyAccount />} />
      <Route path="payment" element={<Payment />} />
      <Route path="payment/add-payment-method" element={<AddPaymentMethod />} />
      <Route path="properties" element={<Properties />} />
      <Route path="library" element={<Library />} />
    </Route>
    <Route path={"documentation"} element={<DashboardLayout />}>
      <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
      <Route path={"terms-of-service"} element={<TermsOfService />} />
    </Route>
    <Route
      path={"/"}
      element={
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      }
    >
      <Route path={"sign-in"} element={<SignIn />} />
      <Route path={"sign-up"} element={<SignUp />} />
      <Route path={"reset-password/:token"} element={<ResetPassword />} />
      <Route path={"reset-password"} element={<ResetPassword />} />
    </Route>

    <Route path={"/"} element={<ShareableLinkLayout />}>
      <Route path={"sl/:id"} element={<ShareableLink />} />
      <Route path={"iframe/:id"} element={<Iframe />} />
    </Route>

    <Route path={"*"} element={<BlankLayout />}>
      <Route path={"*"} element={<Page404 />} />
      <Route path={"500"} element={<Page500 />} />
    </Route>
  </>
);

export default AppRoutes;
