const en = {
  translation: {
    oda_studio: "Oda Studio",
    dashboard: "Dashboard",
    projects: "Projects",
    my_account: "My Account",
    properties: "Properties",
    privacy_policy: "Privacy Policy",
    terms_of_service: "Terms of Service",
    documentation: "Documentation",
    save_changes: "Save Changes",
    cancel: "Cancel",
    close: "Close",
    ok: "OK",
    save: "Save",
    reject: "Reject",
    approve: "Approve",
    send: "Send",
    next: "Next",
    back: "Back",
    submit: "Submit",
    edit: "Edit",
    virtual_staging: "Virtual Staging",
    send_invite: "Send Invite",
    resend_invite: "Resend Invite",
    auth: {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      account: "Account",
      organization: "Organization",
      properties: "Properties",
      users: "Users",
      organization_name: "Organization Name",
      password: "Password",
      confirm_password: "Confirm Password",
      save_changes: "Save Changes",
      add_user: "Add User",
      add_property: "Add Property",
      welcome_to_oda_studio: "Welcome to Oda Studio",
      dont_have_an_account: "Don't have an account?",
      have_an_account: "Have an account?",
      signin: "Sign In",
      signup: "Sign Up",
      forgot_password: "Forgot Password",
      reset_password: "Reset Password",
      enter_your_email_to_reset_password:
        "Enter your email to reset your password",
      enter_your_new_password: "Enter your new password",
      your_password_changed: "Your password has been successfully changed.",
      we_sent_you_email_to_reset_password:
        "We have sent you email in order to reset your password",
    },
    payment: {
      payment_title: "Payment",
      payment_method: "Payment Method",
      add_payment_method: "Add Payment Method",
      credit_or_debit: "Credit or Debit Card",
      card_expiry: "Card Expiration",
      cvc: "CVC",
      save: "Save",
      holder_name: "Holder Name",
    },
    users_status: {
      Active: "Admin",
      Waiting: "Pending member",
    },
    actions_label: {
      enhancement: "Enhancement",
      staging: "Virtual Staging",
      staging_auto_select: "Staging Auto Select",
    },
    staging_styles: {
      Modern: "Modern",
      Scandinavian: "Scandinavian",
      Urban: "Urban",
      Coastal: "Coastal",
    },
    room_type: {
      kitchen: "Kitchen",
      dining_room: "Dining room",
      living_room: "Living room",
      kitchen_and_dining_room: "Kitchen & Dining room",
      kitchen_and_living_room: "Kitchen & Living room",
      dining_room_and_living_room: "Dining room & Living room",
      bedroom: "Bedroom",
      bathroom: "Bathroom",
      office_space: "Office space",
      balcony: "Balcony",
      other: "Other",
    },
    upload: "Upload",
    name_project: "Name project",
    select_services: "Select services",
    finish: "Finish",
    project: {
      drag_n_drop_some_files:
        "Drag 'n' drop some files here, or click to select files",
      select_image_to_add_service: "Select an image to add a service",
      select_services: "Select Services:",
      enhancement: "Enhancement",
      staging: "Virtual Staging",
      staging_auto_select: "Virtual Staging - auto select*",
      select_image: "Select Image",
      preview: "Preview",
      share: "Share",
      download: "Download",
      delete: "Delete",
      rename: "Rename",
      add_new_photos: "Add New Photos",
      successfully_added: "Successfully added.",
      success_your_photos_are_sent_to_virtual_staging:
        "Your photos are sent to virtual staging successfully!",
      compare: "Compare",
      remove: "Remove",
      create_new_project: "Create A New Project",
      images_uploaded_successfully: "Images uploaded successfully",
      to_create_more_projects_please_add_your_credit_card:
        "To create more projects, please add your credit card.",
      credit_card_successfully_added: "Credit card successfully added.",
      add_credit_card: "Add Credit Card",
      my_project: "My Project",
      the_maximum_file_limit: `Images must be .png or .jpg format. Max 100 photos per submission.`,
      items_selected: "items selected",
      item_selected: "item selected",
      some_selected_files:
        "Some selected files already have services assigned.",
      selecting_new_services_automatically_override:
        "Selecting new services will automatically override the current selections.",
      single_select_any_file_to_view_all_preferences:
        "Single select any file to view all preferences.",
      staging_style: "Staging Style *",
      room_type: "Room Type",
      invalidate_cache: "Invalidate Cache",
      in_progress: "In Progress",
      select_for_virtual_staging:
        "Review room type and select photos you would like to submit for staging below.",
      virtual_staging_style: "Virtual Staging Style",
      building_project_name: "Building / Project Name",
      category: "Category",
      select_category: "Category",
      keywords: "Keywords",
      services: "Services",
      basic: "Basic",
      premium: "Premium",
      press_enter_return_comma_key_to_add_multiple_keywords:
        "Press “enter/return” or “comma” key to add multiple keywords.",
      success_your_photos_are_sent_to_flow:
        "Your photos are sent to flow successfully",
    },
    new_project: {
      title: "New Project",
      please_select_staging_style_below: "Please select staging style below.",
    },
    project_detail: {
      title: "Project Detail",
      card_view: "Card View",
      list_view: "List View",
      download_selected: "Download selected",
      share_selected: "Share selected",
      send_to_virtual_staging: "Send to virtual staging",
      send_to_enhancement_and_staging: "Send to enhancement/staging",
      staging_delete_failed:
        "Some selected photos are pending your review. Please complete your review before deleting these photos.",
      delete_failed:
        "Some versions of selected photos are currently being processed. Please wait until the processing is completed to delete your photos.",
      no_files_found: "No files found",
      photos_being_processed: "Photos being processed",
      add_photos: "Add Photos",
    },
    validation: {
      enter_your_email: "Please enter your email address",
      enter_your_password: "Please enter your password",
      enter_your_first_name: "Please enter your first name",
      enter_your_last_name: "Please enter your last name",
      invalid_email: "Invalid email address",
      password_need_same: "Both password need to be the same",
      password_must_least_7_characters:
        "Password must be at least 7 characters",
      card_number_is_invalid: "Credit or debit card number is invalid",
      expiry_date_is_invalid: "Expiry date is invalid",
      cvc_must_be_at_least_3_characters: "CVC must be at least 3 characters",
      cvc_must_be_at_most_4_characters: "CVC must be at most 4 characters",
      cvc_can_only_be_a_number: "CVC can only be a number",
      enter_holder_name: "Please enter your holder name",
      enter_card_number: "Please enter your credit or debit card number",
      enter_card_expiry: "Please enter your card expiration",
      enter_cvc: "Please enter your card cvc",
      enter_project_name: "Please enter your project name",
      enter_unit_type: "Please enter {{- name}}",
      select_staging_style: "Please select a staging style",
      select_category_style: "Please select a category",
      room_type_helper_text:
        "Optional: Oda will auto assign room type if you leave this blank.",
      enter_comment: "Please add your comments before clicking Reject.",
      enter_comment_before_send:
        "Please add your comments before clicking send.",
      enter_property_name: "Please enter property name",
      enter_your_address: "Please enter address",
      enter_your_city: "Please enter city",
      enter_your_zip_code: "Please enter zip code",
      enter_your_state_code: "Please enter your state code",
    },
    footer: {
      about_us: "About Us",
      contact_us: "Contact Us",
      privacy: "Privacy",
      terms_of_service: "Terms of Service",
    },
  },
};

export default en;
