import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Alert,
} from "@mui/material";
import Loader from "./Loader";
import { GET_PROPERTIES_QUERY } from "../queries/properties";
import { useQuery } from "@apollo/client";
import AddPropertyModal from "./AddPropertyModal";
import useAuthorization from "../hooks/useAuthorization";
import { ROLES } from "../constants";

function PropertiesList() {
  const { t } = useTranslation();
  const { hasRole } = useAuthorization();
  const { loading, data, error } = useQuery(GET_PROPERTIES_QUERY);
  const [editPropertyModal, setEditPropertyModal] = useState({
    open: false,
    values: {},
  });
  const headCells = [
    { id: "name", alignment: "left", label: "Name", isSorting: false },
    { id: "address", alignment: "left", label: "Address", isSorting: false },
    {
      id: "created_by",
      alignment: "left",
      label: "Created By",
      isSorting: false,
    },
  ];

  if (loading) return <Loader />;
  return (
    <React.Fragment>
      <Paper>
        {error && <Alert severity="warning">{error.message}</Alert>}
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={"medium"}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignment}
                    padding={"normal"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
                {hasRole(ROLES.ORGANIZATION_OWNER) && (
                  <TableCell align={"right"} padding={"normal"}>
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                data?.real_estate_properties.map((p, index) => {
                  const labelId = `enhanced-table-${index}`;
                  const {
                    address,
                    city,
                    state_code,
                    zip_code,
                    name,
                    id,
                    created_by,
                    real_estate_properties_users,
                  } = p;
                  return (
                    <TableRow hover tabIndex={-1} key={`${id}-${index}`}>
                      <TableCell component="th" id={labelId} scope="row">
                        {name}
                      </TableCell>
                      <TableCell>
                        {address}, {city}, {state_code}, {zip_code}
                      </TableCell>
                      <TableCell>
                        {created_by?.first_name} {created_by?.last_name}
                      </TableCell>
                      {hasRole(ROLES.ORGANIZATION_OWNER) && (
                        <TableCell align="right">
                          <Button
                            variant={"outlined"}
                            onClick={() =>
                              setEditPropertyModal({
                                open: true,
                                values: {
                                  id,
                                  name,
                                  address,
                                  city,
                                  state_code,
                                  zip_code,
                                  real_estate_properties_users,
                                },
                              })
                            }
                          >
                            {t("edit")}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <AddPropertyModal
        values={editPropertyModal.values}
        open={editPropertyModal.open}
        setOpen={() => setEditPropertyModal({ open: false, values: {} })}
      />
    </React.Fragment>
  );
}

export default PropertiesList;
