import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import valid from "card-validator";
import {
  Grid,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from "@mui/material";

import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import ExpiryMaskInput from "../ExpiryMaskInput";
import LoadingButton from "../LoadingButton";
import useAuth from "../../hooks/useAuth";

const TextField = styled(MuiTextField)(spacing);
const Button = styled(LoadingButton)(spacing);
const Alert = styled(MuiAlert)(spacing);

function AddPaymentMethod() {
  const { user, refreshAuthState } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const initialValues = {
    holder_name: "",
    number: "",
    expiry: "",
    cvc: "",
    submit: false,
  };

  const validationSchema = Yup.object({
    holder_name: Yup.string().required(t("validation.enter_holder_name")),
    number: Yup.string()
      .test(
        "number",
        t("validation.card_number_is_invalid"),
        (value) => valid.number(value).isValid
      )
      .required(t("validation.enter_card_number")),
    expiry: Yup.string()
      .test(
        "expiry",
        t("validation.expiry_date_is_invalid"),
        (value) => valid.expirationDate(value).isValid
      )
      .required(t("validation.enter_card_expiry")),
    cvc: Yup.string()
      .min(3, t("validation.cvc_must_be_at_least_3_characters"))
      .max(4, t("validation.cvc_must_be_at_most_4_characters"))
      .matches(/^[0-9]+$/, t("validation.cvc_can_only_be_a_number"))
      .required(t("validation.enter_cvc")),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          const { month, year } = valid.expirationDate(values.expiry);
          const exp_year = `20${year}`;
          await axios
            .post(`/users/${user.id}/add-card`, {
              holder_name: values.holder_name,
              number: values.number,
              exp_month: parseInt(month),
              exp_year: parseInt(exp_year),
              cvc: values.cvc,
            })
            .then(async () => {
              await refreshAuthState();

              location?.state?.isClickedAddCreditCard
                ? navigate("/dashboard/new-project", {
                    state: { isCardAdded: true },
                  })
                : navigate("/dashboard/payment");
            });
        } catch (error) {
          const message = error.response.data.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert my={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            id="holder-name"
            name="holder_name"
            value={values.holder_name}
            label={t("payment.holder_name")}
            variant="outlined"
            error={Boolean(touched.holder_name && errors.holder_name)}
            helperText={touched.holder_name && errors.holder_name}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            my={2}
          />
          <TextField
            id="card-number"
            name="number"
            value={values.number}
            label={t("payment.credit_or_debit")}
            variant="outlined"
            error={Boolean(touched.number && errors.number)}
            helperText={touched.number && errors.number}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            my={2}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="expiry"
                name="expiry"
                label={t("payment.card_expiry")}
                variant="outlined"
                value={values.expiry}
                error={Boolean(touched.expiry && errors.expiry)}
                helperText={touched.expiry && errors.expiry}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                my={2}
                InputProps={{
                  inputComponent: ExpiryMaskInput,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="cvc"
                id="cvc"
                label={t("payment.cvc")}
                value={values.cvc}
                error={Boolean(touched.cvc && errors.cvc)}
                helperText={touched.cvc && errors.cvc}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            loading={isSubmitting}
            color="primary"
            mt={3}
            fullWidth
          >
            {t("payment.save")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default AddPaymentMethod;
