import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Grid,
  Alert as MuiAlert,
  Button as MuiButton,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignUp() {
  const { t } = useTranslation();
  const { signUp } = useAuth();
  const [searchParams] = useSearchParams();
  const [invitationToken, setInvitationToken] = useState(null);
  const [invitationDetail, setInvitationDetail] = useState(null);
  const [isInvalidToken, setIsInvalidToken] = useState(null);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      organization: "",
      password: "",
      confirmPassword: "",
      submit: false,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .max(255)
        .required(t("validation.enter_your_first_name")),
      last_name: Yup.string()
        .max(255)
        .required(t("validation.enter_your_last_name")),
      email: Yup.string()
        .email(t("validation.invalid_email"))
        .max(255)
        .required(t("validation.enter_your_email")),
      password: Yup.string()
        .min(7, t("validation.password_must_least_7_characters"))
        .required(t("validation.enter_your_password")),
      confirmPassword: Yup.string()
        .min(7, t("validation.password_must_least_7_characters"))
        .required(t("validation.enter_your_password"))
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("validation.password_need_same")
          ),
        }),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        await signUp(
          values.email,
          values.password,
          values.first_name,
          values.last_name,
          values.organization,
          invitationToken
        );
      } catch (error) {
        const message = error.response.data.message || "Something went wrong";
        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setInvitationToken(searchParams.get("invitation_token"));
    if (invitationToken) {
      axios
        .get(`organizations/invite/${invitationToken}`)
        .then(({ data }) => {
          setFieldValue("organization", data.organization.name);
          setFieldValue("email", data.email);
          setInvitationDetail(data);
        })
        .catch((err) => {
          if (err.response.data.status_code === 404) {
            setIsInvalidToken(true);
          }
        });
    }
  }, [invitationToken]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      {errors.submit && (
        <Alert mt={2} mb={4} severity="warning">
          {errors.submit}
        </Alert>
      )}
      {isInvalidToken && (
        <Alert mt={2} mb={4} severity="warning">
          Your invite has expired, please ask your team to resend an invite.
        </Alert>
      )}

      {invitationDetail?.email_already_use && (
        <Alert mt={2} mb={4} severity="warning">
          This email is already in use. Please reach us{" "}
          <a href={"mailto:help@odaportal.com"}>via email</a> or by clicking the
          chat icon in the bottom right corner of this page, and someone from
          our team will assist asap!
        </Alert>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="first_name"
            label={t("auth.first_name")}
            value={values.first_name}
            error={Boolean(touched.first_name && errors.first_name)}
            helperText={touched.first_name && errors.first_name}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="last_name"
            label={t("auth.last_name")}
            value={values.last_name}
            error={Boolean(touched.last_name && errors.last_name)}
            helperText={touched.last_name && errors.last_name}
            onBlur={handleBlur}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <TextField
        disabled={!!invitationDetail?.email}
        type="email"
        name="email"
        label="Email address"
        value={values.email}
        error={Boolean(touched.email && errors.email)}
        fullWidth
        helperText={touched.email && errors.email}
        onBlur={handleBlur}
        onChange={handleChange}
        my={2}
      />

      <TextField
        disabled={!!invitationDetail?.organization}
        type="text"
        name="organization"
        label={t("auth.organization")}
        value={values.organization}
        error={Boolean(touched.organization && errors.organization)}
        fullWidth
        helperText={touched.organization && errors.organization}
        onBlur={handleBlur}
        onChange={handleChange}
        my={2}
      />

      <TextField
        type="password"
        name="password"
        label={t("auth.password")}
        value={values.password}
        error={Boolean(touched.password && errors.password)}
        fullWidth
        helperText={touched.password && errors.password}
        onBlur={handleBlur}
        onChange={handleChange}
        my={2}
      />
      <TextField
        type="password"
        name="confirmPassword"
        label={t("auth.confirm_password")}
        value={values.confirmPassword}
        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
        fullWidth
        helperText={touched.confirmPassword && errors.confirmPassword}
        onBlur={handleBlur}
        onChange={handleChange}
        my={2}
      />
      <Button
        mt={3}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={
          isSubmitting || invitationDetail?.email_already_use || isInvalidToken
        }
      >
        {t("auth.signup")}
      </Button>
    </form>
  );
}

export default SignUp;
